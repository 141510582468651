import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { LoadingIndicatorComponent } from './buildingBlocks/LoadingIndicatorComponent';
import { NavMenu } from './NavMenu';
import { Redirect } from "react-router-dom";

function isInIframe() {
    try {
        return window.self !== window.top;
    }
    catch (e) {
        return true;
    }
}

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
    }

    render() {
        if (isInIframe()) {
            return (<>
                {this.props.children}
            </>);
        }
        return (
            <div>
                {this.props.loadedClientConfig && (
                    <NavMenu />
                )}                
                {!this.props.loadedClientConfig && (
                    <LoadingIndicatorComponent />
                )}
                <br />
                <Container>                    
                    {this.props.children}
                </Container>

                <br />

                {/* Maybe add a footer here */}
            </div>
        );
    }
}