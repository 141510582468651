export const translate = (path) =>
{
    const lang = sessionStorage.getItem("lang");
    let obj = {};
    switch (lang) {
        case "EN":
            obj = EN;
            break;
        case "CS":
            obj = CS;
            break;
        case "HU":
            obj = HU;
            break;
        case "SK":
            obj = SK;
            break;
        default:
            obj = RO;
    }
    for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
        obj = obj[path[i]];
    };
    return obj;
}

const RO = {
    generic: {
        vote: 'Voteaza',
        change_vote: 'Schimba vot',
        votes: 'voturi',

        users: 'Utilizatori',
        user: 'Utilizator',
        functions: 'Functii',
        departs: 'Departamente',
        locations: 'Locatii',

        active_users: 'Utilizatori activi',
        disabled_users: 'Utilizatori inactivi',
        posts: 'Postari',

        delete: 'Sterge',
        mark_as_important: 'Marcheaza ca important',
        mark_as_private_post: 'Marcheaza ca privat',
        unmark_as_important: 'Demarcheaza ca important',
        mark_as_public_post: 'Marcheaza ca public',
        posts_page: 'Pagina postarii',

        comment_added: 'Comentariul dvs. a fost adaugat.',
        view_all_comments: 'Vezi toate comentariile',
        hide_comments: 'Ascunde comentariile',

        change_text_content: 'Modifica continut text',
        add_image: 'Adauga imagine',
        your_comment: 'Comentariul tau ...',
        comment_action: 'Comenteaza',
        post_action: 'Posteaza',
        empty_post_err: 'Postarea nu are text sau atasament!',
        add_question: 'Adauga intrebarea',
        add_new_message: 'Adauga un mesaj nou',
        question: 'Sondaj',
        attach: 'Ataseaza',
        answer_option: 'Adauga o optiune de raspuns',
        multiple_answers: 'Permite mai multe raspunsuri',
        anonymus_answers: 'Raspunsuri anonime',
        hide_answers: 'Ascunde raspunsuri',
        view_gallery: 'Vezi galeria',
        request_reload: 'Apasati mai jos pentru a incarca aplicatia',
        member: 'membru',
        g_members: 'Membri',
        members: 'membri',
        reset: 'Reseteaza',
        search: 'Cauta',
        close: 'Inchide',
        search_advanced_users: 'Cautare avansata utilizatori',
        search_advanced_posts: 'Cautare avansata postari',
        search_results: 'Rezultate cautare',
        more_notifications: 'Mai multe notificari',
        more: "Mai mult »",
        less: "Mai putin «",
        add: 'Adauga',
        save: 'Salveaza',
        cancel: 'Anuleaza',
        edit: 'Editeaza',
        confirm: 'Confirma',
        confirm_q: 'Confirmati?',
        navbar: {
            chat: 'Chat',
            groups: 'Grupuri',
            my_profile: 'Profilul meu',
            account_settings: 'Setari cont',
            help: 'Ajutor',
            logout: 'Delogare'
        },
        user_search_dialogs: {
            title: 'Cautare avansata utilizatori',
            after_name: 'Dupa nume:',
            after_forename: 'Dupa prenume:',
            after_email: 'Dupa email:',
            after_functie: 'Dupa functie:',
            after_departament: 'Dupa departament:',
            after_locatie: 'Dupa locatie:'
        },
        users_posts_dialog: {
            title: 'Cautare avansata postari',
            pick_group: 'Alege grup:',
            after_content: 'Dupa continut:'
        },
        pick_image: 'Alege poza',
        upload_image: 'Incarca fotografie',
        confirm_question: 'Sunteti sigur ca doriti sa continuati?',
        all_members: 'Toti membri',
        posts_mode: 'Mod postare',
        some_members: 'Anumiti membri',
        configured_for_all: 'Acest grup este configurat pentru toti membri.',
        select: 'Alege',
        select_users: 'Alege utilizatori',
        comments_mode: 'Mod comentarii',        
        pick_file: 'Alege fisier',
        upload_file: 'Incarcare fisier',
        name: 'Nume',
        description: 'Descriere',
        public: 'Public',
        private: 'Privat'
    },
    home: {        
        groups: {
            title: 'Grupuri',
            public: 'Publice',
            private: 'Private',
            details: 'Detalii grup'
        },
        archive: {
            title: 'Fisierele mele',
            open_archive: 'Deschide arhiva'
        },
    },
    chat: {
        new_conversation: 'Conversatie noua',
        no_convos: 'Nu exista convesatii',
        private: 'Privata',
        group: 'Grup',
        send: 'Trimite',
        add_file: 'Adauga fisier',
        unread: 'Necitit',
        new_message: 'Mesaj nou'
    },
    archive: {
        uploaded_at: 'Incarcat la data de:',
        uploaded_by: 'Incarcat de:',
        downloads: 'descarcari',
        download: 'descarcare',
        download_action: 'Descarca',
        add_folder: 'Adaugati un folder',
        read_write: 'Citire / Scriere',
        read: 'Citire',
        admin_notif: 'Administratorii de platforma au automat permisiune de citire / scriere',
        folder_name: 'Nume folder:',
        rights: 'Drepturi:'
    },
    profile: {
        private_message: 'Mesaj privat',
        user_disabled: 'Utilizator dezactivat',
        my_posts: 'Postarile mele',
        users_post: 'Postarile userului'
    },
    notifications: {
        title: 'Notificari',
        unred_notifications: 'notificari necitite'
    },
    groups: {
        my_groups: 'Grupurile mele',
        admin: 'Admin',
        other_groups: 'Alte grupuri',
        create_new_group: 'Creeaza un grup nou',
        public_groups: 'Grupuri publice',
        private_groups: 'Grupuri private',
        administrators: 'Administratori',
        only_admins: 'Doar administratori',
        access_group: 'Acceseaza grup',
        register_group: 'Inscrie-te in grup',
        created_on: 'Creat in data de'
    },
    admin: {
        reset_password: 'Reseteaza parola',
        platform_activity: 'Activitate platforma',
        current_month: 'Luna curenta:',
        pick_date: 'Alege data:',
        likes: 'Like-uri:',
        comments: 'Comentarii:',
        forename: 'Prenume',
        username: 'Username',
        change_password: 'Schimba parola',
        check_pass_change: 'Sunteti sigur?',
        check_pass_check: 'Introduceti parola veche, parola noua (litere si cifre, minim 6 caractere) si confirmati parola noua',
        current_pass: 'Parola curenta',
        new_pass: 'Parola noua',
        confirm_new_pass: 'Confirma parola noua',
        birth_date: 'Data nasterii',
        phone: 'Telefon',
        email: 'Email',
        functie: 'Functie',
        dept: 'Departament',
        loc: 'Locatie',
        nr_mat: 'Numar matricol',
        create_date: 'Data creare',
        disable_date: 'Data dezactivare',
        details: 'Detalii',
        password: 'Parola',
        original_pass: 'Parola originala',
        f_pass: 'Data primei autentificari',
        default_group: 'Grup prestabilit',
        lang: 'Limba utilizator',
        disable: 'Dezactiveaza',
        enable: 'Activeaza',
        name_admin: 'Numeste administrator',
        revoke_admin: 'Revoca administrator'
    }
};

const EN = {
    generic: {
        vote: 'Vote',
        change_vote: 'Change vote',
        votes: 'votes',

        users: 'Users',
        user: 'User',
        functions: 'Functions',
        departs: 'Departments',
        locations: 'Locations',

        active_users: 'Active users',
        disabled_users: 'Disabled users',
        posts: 'Posts',

        delete: 'Delete',
        mark_as_important: 'Mark as important',
        mark_as_private_post: 'Mark as private',
        unmark_as_important: 'Unmark as important',
        mark_as_public_post: 'Mark as public',
        posts_page: 'Post’s page',

        comment_added: 'Your comment was added.',
        view_all_comments: 'View all comments',
        hide_comments: 'Hide comments',

        change_text_content: 'Change text content',
        add_image: 'Add image',
        your_comment: 'Your comment ...',
        comment_action: 'Comment',
        post_action: 'Post',
        empty_post_err: 'Your post doesn\'t have any text or attachement',
        add_question: 'Add question',
        add_new_message: 'Add new message',
        question: 'Survey',
        attach: 'Attach',
        answer_option: 'Add an answer option',
        multiple_answers: 'Allow multiple answers',
        anonymus_answers: 'Anonymous answers',
        hide_answers: 'Hide answers',
        view_gallery: 'View gallery',
        request_reload: 'Press below to reload application',
        member: 'member',
        g_members: 'Members',
        members: 'members',
        reset: 'Reset',
        search: 'Search',
        close: 'Close',
        search_advanced_users: 'Advanced search users',
        search_advanced_posts: 'Advanced search posts',
        search_results: 'Search results',
        more_notifications: 'More notifications',
        more: "More »",
        less: "Less «",
        add: 'Add',
        save: 'Save',
        cancel: 'Cancel',
        edit: 'Edit',
        confirm: 'Confirm',
        confirm_q: 'Do you confirm?',
        navbar: {
            chat: 'Chat',
            groups: 'Groups',
            my_profile: 'My profile',
            account_settings: 'Account settings',
            help: 'Help',
            logout: 'Logout'
        },
        user_search_dialogs: {
            title: 'Advanced search users',
            after_name: 'By name:',
            after_forename: 'By forename',
            after_email: 'By email:',
            after_functie: 'By function:',
            after_departament: 'By department:',
            after_locatie: 'By location:'
        },
        users_posts_dialog: {
            title: 'Advanced search posts',
            pick_group: 'Select group:',
            after_content: 'By content:'
        },
        pick_image: 'Pick image',
        upload_image: 'Upload image',
        confirm_question: 'Are you sure you want to continue?',
        all_members: 'All members',
        posts_mode: 'Post mode',
        some_members: 'Some members',
        configured_for_all: 'This group is configured for all members.',
        select: 'Select',
        select_users: 'Select users',
        comments_mode: 'Comments mode',
        pick_file: 'Select file',
        upload_file: 'Upload file',
        name: 'Name',
        description: 'Description',
        public: 'Public',
        private: 'Private'
    },
    home: {
        groups: {
            title: 'Groups',
            public: 'Public',
            private: 'Private',
            details: 'Group details'
        },
        archive: {
            title: 'My files',
            open_archive: 'Open archive'
        },
    },
    chat: {
        new_conversation: 'New conversation',
        no_convos: 'No conversations',
        private: 'Private',
        group: 'Group',
        send: 'Send',
        add_file: 'Add file',
        unread: 'Unread',
        new_message: 'New message'
    },
    archive: {
        uploaded_at: 'Uploaded at:',
        uploaded_by: 'Uploaded by:',
        downloads: 'downloads',
        download: 'download',
        download_action: 'Download',
        add_folder: 'Add folder',
        read_write: 'Read / Write',
        read: 'Read',
        admin_notif: 'Platform admins have read/write permission by default',
        folder_name: 'Folder name:',
        rights: 'Rights:'
    },
    profile: {
        private_message: 'Private message',
        user_disabled: 'Disabled user',
        my_posts: 'My posts',
        users_post: 'User’s post'
    },
    notifications: {
        title: 'Notifications',
        unred_notifications: 'unread notifications'
    },
    groups: {
        my_groups: 'My groups',
        admin: 'Admin',
        other_groups: 'Other groups',
        create_new_group: 'Create new group',
        public_groups: 'Public groups',
        private_groups: 'Private groups',
        administrators: 'Administrators',
        only_admins: 'Only administrators',
        access_group: 'Access group',
        register_group: 'Register in group',
        created_on: 'Created on'
    },
    admin: {
        reset_password: 'Reset password',
        platform_activity: 'Platform activity',
        current_month: 'Current month:',
        pick_date: 'Select date:',
        likes: 'Likes:',
        comments: 'Comments',
        forename: 'Forename',
        username: 'Username',
        change_password: 'Change password',
        check_pass_change: 'Are you sure?',
        check_pass_check: 'Fill in old password, new password (letters and numbers, minimum 6 characters ) and confirm new password',
        current_pass: 'Current password',
        new_pass: 'New password',
        confirm_new_pass: 'Confirm new password',
        birth_date: 'Date of birth',
        phone: 'Phone',
        email: 'Email',
        functie: 'Function',
        dept: 'Department',
        loc: 'Location',
        nr_mat: 'Work ID',
        create_date: 'Create date',
        disable_date: 'Disable date',
        details: 'Details',
        password: 'Password',
        original_pass: 'Original password',
        f_pass: 'First authentication date',
        default_group: 'Default group',
        lang: 'User language',
        disable: 'Disable',
        enable: 'Enable',
        name_admin: 'Name administrator',
        revoke_admin: 'Revoke administrator'
    }
};

const CS = {
    generic: {
        users: 'Uživatelé',
        user: 'Uživatel',
        functions: 'Funkce',
        departs: 'Oddělení',
        locations: 'Umístění',

        active_users: 'Aktivní uživatelé',
        disabled_users: 'Neaktivní uživatelé',
        posts: 'Příspěvky',

        delete: 'odstranit',
        mark_as_important: 'Označit jako důležité',
        mark_as_private_post: 'Označit jako soukromé',
        unmark_as_important: 'Zrušit označení jako důležité',
        mark_as_public_post: 'Označit jako veřejné',
        posts_page: 'Stránka příspěvku',

        comment_added: 'Váš komentář byl přidán.',
        view_all_comments: 'Zobrazit všechny komentáře',
        hide_comments: 'Skrýt komentáře',

        change_text_content: 'Změnit obsah textu',
        add_image: 'Přidat obrázek',
        your_comment: 'Váš komentář ...',
        comment_action: 'Komentář',
        post_action: 'Příspěvek',
        empty_post_err: 'Příspěvek neobsahuje žádný text ani přílohu!',
        add_question: 'Přidat otázku',
        add_new_message: 'Přidat novou zprávu',
        question: 'Dotazník',
        attach: 'Příloha',
        answer_option: 'Přidat možnost odpovědi',
        multiple_answers: 'Možnost více odpovědí',
        anonymus_answers: 'Anonymní odpovědi',
        hide_answers: 'Skrýt odpovědi',
        view_gallery: 'Zobrazit galerii',
        request_reload: 'Stisknutím níže znovu načtete aplikaci',
        member: 'člen',
        g_members: 'Členové',
        members: 'členové',
        reset: 'Reset',
        search: 'Hledat',
        close: 'Zavřít',
        search_advanced_users: 'Pokročilé vyhledávání uživatelů',
        search_advanced_posts: 'Pokročilé vyhledávání příspěvků',
        search_results: 'Výsledky vyhledávání',
        more_notifications: 'Další upozornění',
        add: 'Přidat',
        save: 'Uložit',
        cancel: 'Zrušit',
        edit: 'Upravit',
        confirm: 'Potvrdit',
        confirm_q: 'Potvrzujete?',
        navbar: {
            chat: 'Chat',
            groups: 'Skupiny',
            my_profile: 'Můj profil',
            account_settings: 'Nastavení účtu',
            help: 'Pomoc',
            logout: 'Odhlásit'
        },
        user_search_dialogs: {
            title: 'Pokročilé vyhledávání uživatelů',
            after_name: 'Podle jména:',
            after_forename: 'Podle křestního jména',
            after_email: 'Podle e-mailu:',
            after_functie: 'Podle funkce:',
            after_departament: 'Podle oddělení:',
            after_locatie: 'Podle umístění:'
        },
        users_posts_dialog: {
            title: 'Pokročilé vyhledávání příspěvků',
            pick_group: 'Vybrat skupinu:',
            after_content: 'Podle obsahu:'
        },
        pick_image: 'Vybrat obrázek',
        upload_image: 'Nahrát obrázek',
        confirm_question: 'Opravdu chcete pokračovat?',
        all_members: 'Všichni členi',
        posts_mode: 'Režim příspěvku',
        some_members: 'Někteří členové',
        configured_for_all: 'Tato skupina je nakonfigurována pro všechny členy.',
        select: 'Vybrat',
        select_users: 'Vybrat uživatele',
        comments_mode: 'Režim komentářů',
        pick_file: 'Vybrat soubor',
        upload_file: 'Nahrát soubor',
        name: 'Jméno',
        description: 'Popis',
        public: 'Veřejné',
        private: 'Soukromé'
    },
    home: {
        groups: {
            title: 'Skupiny',
            public: 'Veřejné',
            private: 'Soukromé',
            details: 'Podrobnosti o skupině'
        },
        archive: {
            title: 'Moje soubory',
            open_archive: 'Otevřený archiv'
        },
    },
    chat: {
        new_conversation: 'Nová konverzace',
        no_convos: 'Žádné konverzace',
        private: 'Soukromé',
        group: 'Skupina',
        send: 'Odeslat',
        add_file: 'Přidat soubor',
        unread: 'Nepřečteno',
        new_message: 'Nová zpráva'
    },
    archive: {
        uploaded_at: 'Nahráno na:',
        uploaded_by: 'Nahráno:',
        downloads: 'stahování',
        download: 'stáhnout',
        download_action: 'stáhnout',
        add_folder: 'Přidat složku',
        read_write: 'Číst / Psát',
        read: 'Číst',
        admin_notif: 'Správci platformy mají ve výchozím nastavení oprávnění ke čtení/zápisu',
        folder_name: 'Název složky:',
        rights: 'Práva:'
    },
    profile: {
        private_message: 'Soukromá zpráva',
        user_disabled: 'Deaktivovaný uživatel',
        my_posts: 'Moje příspěvky',
        users_post: 'Příspěvek uživatele'
    },
    notifications: {
        title: 'Oznámení',
        unred_notifications: 'nepřečtená upozornění'
    },
    groups: {
        my_groups: 'Moje skupiny',
        admin: 'Admin',
        other_groups: 'Ostatní skupiny',
        create_new_group: 'Vytvořit novou skupinu',
        public_groups: 'Veřejné skupiny',
        private_groups: 'Soukromé skupiny',
        administrators: 'Administrátoři',
        only_admins: 'Pouze administrátoři',
        access_group: 'Přístupová skupina',
        register_group: 'Zaregistrovat se ve skupině',
        created_on: 'Vytvořeno dne'
    },
    admin: {
        reset_password: 'Obnovit heslo',
        platform_activity: 'Aktivity na platformě',
        current_month: 'Aktuální měsíc:',
        pick_date: 'Vyberte datum:',
        likes: 'Líbí se mi:',
        comments: 'Komenráře',
        forename: 'Křestní jméno',
        username: 'Uživatelské jméno',
        change_password: 'Změnit heslo',
        check_pass_change: 'Jste si jistý?',
        check_pass_check: ' Vyplňte staré heslo, nové heslo (písmena a číslice, minimálně 6 znaků) a potvrďte nové heslo',
        current_pass: 'Aktuální heslo',
        new_pass: 'Nové heslo',
        confirm_new_pass: 'Potvrďte nové heslo',
        birth_date: 'Datum narození',
        phone: 'Telefoní číslo',
        email: 'Email',
        functie: 'Funkce',
        dept: 'Oddělení',
        loc: 'Místo',
        nr_mat: 'Pracovní ID',
        create_date: 'Datum vytvoření',
        disable_date: 'Datum deaktivace',
        details: 'Podrobnosti',
        password: 'Heslo',
        original_pass: 'Původní heslo',
        f_pass: 'Datum prvního ověření',
        default_group: 'Výchozí skupina',
        lang: 'Uživatelský jazyk',
        disable: 'Zakázat',
        enable: 'Povolit',
        name_admin: 'Jméno administrátora',
        revoke_admin: 'Zrušit administratora'
    }

};

const SK = {
    generic: {
        users: 'Užívatelia',
        user: 'Používateľ',
        functions: 'Funkcia',
        departs: 'Oddelenie',
        locations: 'Umistnenie',

        active_users: 'Aktívni používatelia ',
        disabled_users: 'Neaktívni používatelia',
        posts: 'Príspevky',

        delete: 'Odstrániť',
        mark_as_important: 'Označiť ako doležité',
        mark_as_private_post: 'Označiť ako súkromné',
        unmark_as_important: 'Zrušiť označenie ako doležité',
        mark_as_public_post: 'Označiť ako verejné',
        posts_page: 'Stránka príspevku',

        comment_added: 'Váš komentár bol pridaný.',
        view_all_comments: 'Zobraziť všetky komentáre',
        hide_comments: 'Skryť komentáre',

        change_text_content: 'Zmeniť obsah textu',
        add_image: 'Pridať obrázok',
        your_comment: ' Váš komentár ...',
        comment_action: 'Komentár',
        post_action: 'Komentár',
        empty_post_err: 'Príspevok neobsahuje žiadny text ani prílohu!',
        add_question: 'Pridať otázku',
        add_new_message: 'Pridať novú správu',
        question: 'Dotazník',
        attach: 'Príloha',
        answer_option: 'Pridať možnosť odpovede',
        multiple_answers: 'Možnosť viacerých odpovedí',
        anonymus_answers: 'Anonymné odpovede',
        hide_answers: 'Skryť odpovede',
        view_gallery: 'Zobraziť galériu',
        request_reload: 'Stlačením nižšie znovu načítate aplikáciu',
        member: 'člen',
        g_members: 'Členovia',
        members: 'členovia',
        reset: 'Reset',
        search: 'Hľadať',
        close: 'Zavrieť',
        search_advanced_users: 'Pokročilé vyhľadávanie používateľov',
        search_advanced_posts: 'Pokročilé vyhľadávanie príspevkov',
        search_results: 'Výsledky vyhľadávania',
        more_notifications: 'Ďalšie upozornenie',
        add: 'Pridať',
        save: 'Uložiť',
        cancel: 'Zrušiť',
        edit: 'Upraviť ',
        confirm: 'Potvrdiť',
        confirm_q: 'Potvrdzujete?',
        navbar: {
            chat: 'Chat',
            groups: 'Skupiny',
            my_profile: 'Môj profil',
            account_settings: 'Nastavenie účtu',
            help: 'Pomoc',
            logout: 'Odhlásiť'
        },
        user_search_dialogs: {
            title: 'Pokročilé vyhľadávanie užívateľov',
            after_name: 'Podľa mena:',
            after_forename: 'Podľa krstného mena',
            after_email: 'Podľa e-mailu:',
            after_functie: 'Podľa funkcie:',
            after_departament: 'Podľa oddelenia:',
            after_locatie: 'Podľa umiestnenia:'
        },
        users_posts_dialog: {
            title: 'Pokročilé vyhľadávanie príspevkov',
            pick_group: 'Vybrať skupinu:',
            after_content: 'Podľa obsahu:'
        },
        pick_image: 'Vybrať obrázok',
        upload_image: 'Nahrať obrázok',
        confirm_question: 'Naozaj chcete pokračovať?',
        all_members: 'Všetci členovia',
        posts_mode: 'Režim príspevku',
        some_members: 'Niektorí členovia',
        configured_for_all: 'Táto skupina je nakonfigurovaná pre všetkých členov.',
        select: 'Vybrať',
        select_users: 'Vybrať používateľa',
        comments_mode: 'Režim komentárov',
        pick_file: 'Vybrať súbor',
        upload_file: 'Nahrať súbor',
        name: 'Meno',
        description: 'Popis',
        public: 'Verejné',
        private: 'Súkromné'
    },
    home: {
        groups: {
            title: 'Skupiny',
            public: 'Verejné',
            private: 'Súkromné',
            details: 'Podrobnosti o skupine'
        },
        archive: {
            title: 'Moje súbory',
            open_archive: 'Otvoreny archív'
        },
    },
    chat: {
        new_conversation: 'Nová konverzácia',
        no_convos: 'Žiadne konverzácie',
        private: 'Súkromné',
        group: 'Skupina',
        send: 'Odoslať',
        add_file: 'Pridať súbor',
        unread: 'Neprečítané',
        new_message: 'Nová správa'
    },
    archive: {
        uploaded_at: 'Nahrané na:',
        uploaded_by: 'Nahral:',
        downloads: 'sťahovanie',
        download: 'stiahnuť',
        download_action: 'Stiahnuť',
        add_folder: 'Pridať zložku',
        read_write: 'Čítať / Písať',
        read: 'Čítať',
        admin_notif: 'Správcovia platformy majú v predvolenom nastavení oprávnenie na čítanie/zápis',
        folder_name: 'Názov zložky:',
        rights: 'Práva:'
    },
    profile: {
        private_message: 'Súkromná správa',
        user_disabled: 'Deaktivovaný užívateľ',
        my_posts: 'Moje príspevky',
        users_post: 'Príspevok užívateľa'
    },
    notifications: {
        title: 'Oznámenie',
        unred_notifications: 'neprečítané upozornenia'
    },
    groups: {
        my_groups: 'Moje skupiny',
        admin: 'Admin',
        other_groups: 'Ostatné skupiny',
        create_new_group: 'Vytvoriť novú skupinu',
        public_groups: 'Verejné skupiny',
        private_groups: 'Súkromné skupiny',
        administrators: 'Administrátori',
        only_admins: 'Len administrátori',
        access_group: 'Prístupová skupina',
        register_group: 'Zaregistrovať sa v skupine',
        created_on: 'Vytvorené dňa'
    },
    admin: {
        reset_password: 'Obnoviť heslo', // TODO
        platform_activity: 'Aktivity na platforme',
        current_month: 'Aktuálny mesiac:',
        pick_date: 'Vyberte dátum:',
        likes: 'Páči sa mi:',
        comments: 'Komenráre',
        forename: 'Krstné meno',
        username: 'Užívateľské meno',
        change_password: 'Zmeniť heslo',
        check_pass_change: 'Ste si istý?',
        check_pass_check: 'Vyplňte staré heslo, nové heslo(písmená a číslice, minimálne 6 znakov) a potvrďte nové heslo',
        current_pass: 'Aktuálne heslo',
        new_pass: 'Nové heslo',
        confirm_new_pass: 'Potvrďte nové heslo',
        birth_date: 'Dátum narodenia',
        phone: 'Telefónne číslo',
        email: 'Email',
        functie: 'Funkcia',
        dept: 'Oddelenie',
        loc: 'Miesto',
        nr_mat: 'Pracovné ID',
        create_date: 'Dátum vytvorenia',
        disable_date: 'Dátum deaktivácie',
        details: 'Podrobnosti',
        password: 'Heslo',
        original_pass: 'Povodné heslo',
        f_pass: 'Dátum prvého overenia',
        default_group: 'Predvolená skupina',
        lang: 'Používateľský jazyk',
        disable: 'Zakátať',
        enable: 'Povoliť',
        name_admin: 'Meno administrátora',
        revoke_admin: 'Zrušiť administratora'
    }
};


const HU = {
    generic: {
        vote: 'Szavaz',
        change_vote: 'A szavazás módosítása',
        votes: 'Szavazat',

        users: 'Felhasználók',
        user: 'Felhasználó',
        functions: 'Beosztások',
        departs: 'Részlegek',
        locations: 'Lokációk',

        active_users: 'Aktív felhasználók',
        disabled_users: 'Letiltott felhasználók',
        posts: 'Bejegyzések',

        delete: 'Törlés',
        mark_as_important: 'Megjelölés fontosként',
        mark_as_private_post: 'Megjelölés privátként',
        unmark_as_important: 'Fontosként jelölés törlése',
        mark_as_public_post: 'Megjelölés nyilvánosként',
        posts_page: 'Bejegyzések oldala',

        comment_added: 'Megjegyzés hozzáadva.',
        view_all_comments: 'Összes megjegyzés megtekintése',
        hide_comments: 'Megjegyzések elrejtése',

        change_text_content: 'Szövegtartalom módosítása',
        add_image: 'Kép hozzáadása',
        your_comment: 'A kommented ...',
        comment_action: 'Komment',
        post_action: 'Bejegyzés',
        empty_post_err: 'A bejegyzés nem tartalmaz szöveget vagy csatolmányt!',
        add_question: 'Kérdés feltevése',
        add_new_message: 'Új üzenet hozzáadása',
        question: 'Felmérés',
        attach: 'Csatolás',
        answer_option: 'Válasz opció hozzáadása',
        multiple_answers: 'Több válasz engedélyezése',
        anonymus_answers: 'Névtelen válaszok',
        hide_answers: 'Válaszok elrejtése',
        view_gallery: 'Galéria megtekintése',
        request_reload: 'Az újratöltéshez nyomja meg az alábbi gombot',
        member: 'tag',
        g_members: 'Tagok',
        members: 'tagok',
        reset: 'Újraindítás',
        search: 'Keresés',
        close: 'Bezárás',
        search_advanced_users: 'Felhasználók speciális keresése',
        search_advanced_posts: 'Bejegyzések speciális keresése',
        search_results: 'Keresési eredmények',
        more_notifications: 'További értesítések',
        more: "Több »",
        less: "Kevésbé «",
        add: 'Hozzáadás',
        save: 'Mentés',
        cancel: 'Törlés',
        edit: 'Szerkesztés',
        confirm: 'Megerősít',
        confirm_q: 'Megerősíti?',
        navbar: {
            chat: 'Beszélgetés',
            groups: 'Csoportok',
            my_profile: 'Profilom',
            account_settings: 'Fiókbeállítások',
            help: 'Segítség',
            logout: 'Kijelentkezés'
        },
        user_search_dialogs: {
            title: 'Felhasználók speciális keresése',
            after_name: 'Név alapján:',
            after_forename: 'Keresztnév alapján:',
            after_email: 'E-mail alapján:',
            after_functie: 'Beosztás szerint:',
            after_departament: 'Osztály szerint:',
            after_locatie: 'Lokáció szerint:'
        },
        users_posts_dialog: {
            title: 'Speciális keresés a bejegyzésekben',
            pick_group: 'Csoport kiválasztása:',
            after_content: 'Tartalom alapján:'
        },
        pick_image: 'Kép kiválasztása',
        upload_image: 'Kép feltöltése',
        confirm_question: 'Biztos benne, hogy folytatni szeretné?',
        all_members: 'Minden tag',
        posts_mode: 'Bejegyzés mód',
        some_members: 'Néhány tag',
        configured_for_all: 'Ez a csoport minden tag számára be van állítva.',
        select: 'Kiválasztás',
        select_users: 'Felhasználók kiválasztása',
        comments_mode: 'Hozzászólás mód',
        pick_file: 'Fájl kiválasztása',
        upload_file: 'Fájl feltöltése',
        name: 'Név',
        description: 'Leírás',
        public: 'Nyilvános',
        private: 'Privát'
    },
    home: {
        groups: {
            title: 'Csoportok',
            public: 'Nyilvános',
            private: 'Privát',
            details: 'Csoport adatai'
        },
        archive: {
            title: 'Saját fájlok',
            open_archive: 'Archívum megnyitása'
        },
    },
    chat: {
        new_conversation: 'Új beszélgetés',
        no_convos: 'Nincsenek beszélgetések',
        private: 'Privát',
        group: 'Csoport',
        send: 'Küldés',
        add_file: 'Fájl hozzáadása',
        unread: 'Olvasatlan',
        new_message: 'Új üzenet'
    },
    archive: {
        uploaded_at: 'Feltöltve itt:',
        uploaded_by: 'Feltöltötte:',
        downloads: 'letöltések',
        download: 'letöltés',
        download_action: 'Letöltés',
        add_folder: 'Mappa hozzáadása',
        read_write: 'Olvasás / Írás',
        read: 'Olvasá',
        admin_notif: 'A platform adminisztrátorai alapértelmezés szerint olvasási/ írási jogosultsággal rendelkeznek',
        folder_name: 'Mappa neve:',
        rights: 'Jogosultságok:'
    },
    profile: {
        private_message: 'Privát üzenet',
        user_disabled: 'Letiltott felhasználó',
        my_posts: 'Bejegyzéseim',
        users_post: 'Felhasználói bejegyzés'
    },
    notifications: {
        title: 'Értesítések',
        unred_notifications: 'olvasatlan értesítések'
    },
    groups: {
        my_groups: 'Csoportjaim',
        admin: 'Adminisztrátor',
        other_groups: 'Más csoportok',
        create_new_group: 'Új csoport létrehozása',
        public_groups: 'Nyilvános csoportok',
        private_groups: 'Privát csoportok',
        administrators: 'Adminisztrátorok',
        only_admins: 'Kizárólag adminisztrátorok',
        access_group: 'Csoport elérése',
        register_group: 'Regisztrálás a csoportba',
        created_on: 'Készült'
    },
    admin: {
        reset_password: 'Jelszó visszaállítása',
        platform_activity: 'Oldaltevékenység',
        current_month: 'Jelenlegi hónap:',
        pick_date: 'Dátum kiválasztása:',
        likes: 'Kedvelések:',
        comments: 'Hozzászólások',
        forename: 'Keresztnév',
        username: 'Felhasználónév',
        change_password: 'Jelszó módosítása',
        check_pass_change: 'Biztos benne?',
        check_pass_check: 'Adja meg a régi jelszót, az új jelszót(betűk és számok, legalább 6 karakter) és erősítse meg az új jelszót',
        current_pass: 'Jelenlegi jelszó',
        new_pass: 'Új jelszó',
        confirm_new_pass: 'Új jelszó megerősítése',
        birth_date: 'Születési dátum',
        phone: 'Telefonszám',
        email: 'E-mail',
        functie: 'Beosztás',
        dept: 'Osztály',
        loc: 'Lokáció',
        nr_mat: 'Munkavállalói kód',
        create_date: 'Létrehozás dátuma',
        disable_date: 'Letiltás dátuma',
        details: 'Részletek',
        password: 'Jelszó',
        original_pass: 'Eredeti jelszó',
        f_pass: 'Első hitelesítés dátuma',
        default_group: 'Alapértelmezett csoport',
        lang: 'Felhasználói nyelv',
        disable: 'Letiltás',
        enable: 'Engedélyezés',
        name_admin: 'Adminisztrátor neve',
        revoke_admin: 'Adminisztrátor visszavonása'
    }
};
