import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { translate } from '../../translations/translator_utils';

export const SquareImageUploadField = (props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
    const [completedCrop, setCompletedCrop] = useState(null);

    const [defaultImagePreview, setDefaultImagePreview] = useState("/images/default_group_image.png");

    useEffect(() => {
        setDefaultImagePreview(props.defaultImagePreview);
    }, [props]);

    const imgBoxClick = () => $('#imgupload').trigger('click');

    function imageInputFileChanged(e) {        
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            setModalVisible(true);
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
    }, [completedCrop]);

    const saveFile = () => {
        const canvas = previewCanvasRef.current;
        canvas.toBlob(
            (blob) => {               
                var file = new File([blob], "name.png");                
                props.onSave(file);
                setDefaultImagePreview(URL.createObjectURL(blob));
                setModalVisible(false);
            },            
        );
    }

    return (
        <>
            <input type="file" id="imgupload" accept="image/*" onChange={imageInputFileChanged} style={{ display: 'none' }} />
            <div className="imgwrap" style={{ cursor: 'pointer' }}>
                <div className="service smooth" onClick={imgBoxClick}>
                    <img id="imguploadpreviewcircle" className="img-responsive" src={defaultImagePreview} style={{ width: "100%" }} />
                    <p style={{ width: '100%', textAlign: 'center' }} className="imgdescription">{translate('generic.pick_image')}</p>
                </div>
            </div>


            <Modal isOpen={modalVisible} toggle={() => { setModalVisible(false); }}>
                <ModalHeader toggle={() => { setModalVisible(false); }}>{translate('generic.upload_image')}</ModalHeader>
                <ModalBody>
                    <ReactCrop
                        src={upImg}
                        onImageLoaded={onLoad}
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                    />
                    <div>
                        <canvas
                            ref={previewCanvasRef}                            
                            style={{
                                display: 'none',
                                width: Math.round(completedCrop?.width ?? 0),
                                height: Math.round(completedCrop?.height ?? 0)
                            }}
                        />
                    </div>                    
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => { saveFile(); }}>{translate('generic.save')}</Button>
                    <Button color="secondary" onClick={() => { setModalVisible(false); }}>{translate('generic.cancel')}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}