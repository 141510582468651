import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { translate } from '../../../translations/translator_utils';
import { get_ajax } from '../../../utils/DataProvider';

Date.prototype.toDateInputValue = (function () {
    var local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
});

export const PlatformActivity = () => {
    const [selectedActivityDate, setSelectedActivityDate] = useState(new Date().toDateInputValue());

    const [activeUsersData, setActiveUsersData] = useState({});
    const [postsData, setPostsData] = useState({});
    const [likesData, setLikesData] = useState({});
    const [commentsData, setCommentsData] = useState({});

    const [detailedData, setDetailedData] = useState({});

    useEffect(() => {
        get_ajax('Social/UserManager/PlatformActivityCharts', (response) => {
            setActiveUsersData({
                labels: response.active.labels,
                datasets: [
                    {
                        label: '',
                        data: response.active.data,
                        fill: false,
                        backgroundColor: 'rgb(255, 99, 132)',
                        borderColor: 'rgba(255, 99, 132, 0.2)',
                    },
                ],
            });
            setLikesData({
                labels: response.likes.labels,
                datasets: [
                    {
                        label: '',
                        data: response.likes.data,
                        fill: false,
                        backgroundColor: 'rgb(255, 99, 132)',
                        borderColor: 'rgba(255, 99, 132, 0.2)',
                    },
                ],
            });
            setPostsData({
                labels: response.posts.labels,
                datasets: [
                    {
                        label: '',
                        data: response.posts.data,
                        fill: false,
                        backgroundColor: 'rgb(255, 99, 132)',
                        borderColor: 'rgba(255, 99, 132, 0.2)',
                    },
                ],
            });
            setCommentsData({
                labels: response.comments.labels,
                datasets: [
                    {
                        label: '',
                        data: response.comments.data,
                        fill: false,
                        backgroundColor: 'rgb(255, 99, 132)',
                        borderColor: 'rgba(255, 99, 132, 0.2)',
                    },
                ],
            });
        })
    }, []);

    useEffect(() => {
        get_ajax("Social/UserManager/PlatformActivityPerDay?day=" + selectedActivityDate, (response) => {
            setDetailedData(response);
        });
    }, [selectedActivityDate]);

    return (
        <>
            <label>{translate('admin.current_month')}</label>
            <div className="row">
                <div className="col-md-6">
                    <small className="text-secondary">{translate('generic.active_users')}:</small>
                    <Line
                        data={activeUsersData}
                        options={{
                            scales: {
                                yAxis: {
                                    ticks: {
                                        stepSize: 1
                                    }
                                }
                            }
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <small className="text-secondary">{ translate('generic.posts')}:</small>
                    <Line
                        data={postsData}
                        options={{
                            scales: {
                                yAxis: {
                                    ticks: {
                                        stepSize: 1
                                    }
                                }
                            }
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <small className="text-secondary">Like-uri:</small>
                    <Line
                        data={likesData}
                        options={{
                            scales: {
                                yAxis: {
                                    ticks: {
                                        stepSize: 1
                                    }
                                }
                            }
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <small className="text-secondary">Comentarii:</small>
                    <Line
                        data={commentsData}
                        options={{
                            scales: {
                                yAxis: {
                                    ticks: {
                                        stepSize: 1
                                    }
                                }
                            }
                        }}
                    />
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>{translate('admin.pick_date')}</label>
                        <input className="form-control no-outline" type="date" value={selectedActivityDate} onChange={(e) => { setSelectedActivityDate(e.target.value); }} />
                    </div>
                </div>                
            </div>
            <br />
            <div className="row">
                <div className="col-md-3">
                    <div className="form-group">
                        <label>{translate('generic.active_users')}</label>
                        <input value={detailedData.activityLogs} className="form-control" disabled />
                    </div>
                    <br />
                    <div className="form-group">
                        <label>{translate('admin.likes')}</label>
                        <input value={detailedData.likes} className="form-control" disabled />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>{translate('generic.posts')}:</label>
                        <input value={detailedData.posts} className="form-control" disabled />
                    </div>
                    <br />
                    <div className="form-group">
                        <label>{translate('admin.comments')}</label>
                        <input value={detailedData.comments} className="form-control" disabled />
                    </div>
                </div>
            </div>
        </>
    );
}