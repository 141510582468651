import React from 'react';

export const YesNoPicker = (props) => {
    return (
        <div className="btn-group d-flex">
            <button type="button" className={props.defaultValue == true ? "btn btn-primary no-outline" : "btn btn-secondary no-outline"} onClick={() => { props.valueUpdated(true); }}>
                {props.customLabel1 ? props.customLabel1 : 'Da' }
            </button>
            <button type="button" className={props.defaultValue == false ? "btn btn-primary no-outline" : "btn btn-secondary no-outline"} onClick={() => { props.valueUpdated(false); }}>
                {props.customLabel2 ? props.customLabel2 : 'Nu' }
            </button>
        </div>
    )
}