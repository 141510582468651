import React, { Component, useEffect, useRef, useState } from "react";
import { translate } from '../../translations/translator_utils';

export default class OverflowText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            overflowActive: false,
            text: props.text,
            isBig: false
        };
    }

    isEllipsisActive(e) {
        return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
    }

    componentDidMount() {
        this.setState({ overflowActive: this.isEllipsisActive(this.span) });
    }

    render() {
        return (
            <>
                <div
                    style={this.state.isBig ? {} : {
                        lineHeight: "1.5em",
                        maxHeight: "7.5em",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "pre-line"
                    }}
                    ref={(ref) => (this.span = ref)}
                    dangerouslySetInnerHTML={{ __html: this.state.text }}
                >                    
                </div>
                {
                    this.state.overflowActive && (
                        <>{!this.state.isBig && (<>...</>)} <button onClick={() => {
                            this.setState({ isBig: !this.state.isBig });
                        }} style={{ color: '#e74c3c', fontSize: '14px', cursor: 'pointer', border: 'none', background: 'transparent' }}>{this.state.isBig ? translate('generic.less') : translate('generic.more')}</button></>
                    )
                }
            </>
        );
    }
}