import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { get_ajax, post } from '../../../utils/DataProvider';
import { LoadingCircleComponent } from '../../buildingBlocks/LoadingCircleComponent';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { MembersPicker } from '../../buildingBlocks/MembersPicker';
import { AiOutlineUserAdd } from "react-icons/ai";
import $ from 'jquery';
import axios from 'axios';
import { GrAttachment, GrClose } from "react-icons/gr";
import authService from '../../api-authorization/AuthorizeService';
import { HubConnectionBuilder } from '@microsoft/signalr';
import SwipeableViews from 'react-swipeable-views';
import { translate } from '../../../translations/translator_utils';

const downloadAs = (url, name) => {
    axios.get(url, {
        headers: {
            "Content-Type": "application/octet-stream",
            "Access-Control-Allow-Origin": "*"
        },
        responseType: "blob"
    })
        .then(response => {
            const a = document.createElement("a");
            const url = window.URL.createObjectURL(response.data);
            a.href = url;
            a.download = name;
            a.click();
        })
        .catch(err => {
            console.log("error", err);
        });
};


export const Chat = (props) => {
    let _currentConvId;
    const [currentConvId, setCurrentConvId] = useState('');
    const [signalRConnectionStatus, setSignalRConnectionStatus] = useState('');

    const [reloadConversationList, setReloadConversationList] = useState(false);

    const [newMessages, setNewMessages] = useState([]);
    const [activeUsersIds, setActiveUsersIds] = useState([]);

    const [loadDetails, setLoadDetails] = useState(false);

    useEffect(() => {
        if (props.match.params.convId != undefined) {            
            setCurrentConvId(props.match.params.convId);
        }
        if (connection == null)
            setup_connection();
    }, [props.match.params.convId]);

    const [connection, setConnection] = useState(null);
    const setup_connection = () => {
        const newConnection = new HubConnectionBuilder()
            .withUrl('/hubs/feedhub')
            .withAutomaticReconnect()
            .build();
        setConnection(newConnection);
    }

    useEffect(() => {
        sessionStorage.setItem("currentConvId", currentConvId);
    }, [currentConvId]);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(async (result) => {
                    console.log('Connected!');

                    connection.on('NEW_CONVERSATION_ADDED', data => {                        
                        setReloadConversationList(!reloadConversationList);
                    });
                    connection.on('NEW_MESSAGE_RECEIVED', (conversationId, message) => {
                        setReloadConversationList(!reloadConversationList);
                        if (conversationId == sessionStorage.getItem("currentConvId")) {                            
                            setNewMessages([message]);
                        }
                    });
                    connection.on('MESSAGE_WAS_READ', data => {
                        setLoadDetails(!loadDetails);
                        setReloadConversationList(!reloadConversationList);
                    });

                    connection.on('UPDATE_D', (data) => {
                        setActiveUsersIds(data);
                    });

                    connection.on('connectionSlow', () => {
                        setSignalRConnectionStatus("slow");
                    });
                    connection.on('reconnecting', () => {
                        setSignalRConnectionStatus("reconnecting");
                    });
                    connection.on('reconnected', () => {
                        setSignalRConnectionStatus("reconnected");
                        setTimeout(() => {
                            setSignalRConnectionStatus("");
                        });
                    });
                    connection.on('disconnected', () => {
                        if (signalRConnectionStatus != "reconnecting")
                            connection.start();
                    });

                    await connection.invoke('AssignToChat', sessionStorage.getItem("current_user_id"));
                    await connection.invoke('RequestLiveUsersData');
                })
                .catch(e => console.log('Connection failed: ', e));

            connection.on('connectionSlow', () => {
                setSignalRConnectionStatus("slow");
            });
            connection.on('reconnecting', () => {
                setSignalRConnectionStatus("reconnecting");
            });
            connection.on('reconnected', () => {
                setSignalRConnectionStatus("reconnected");
                setTimeout(() => {
                    setSignalRConnectionStatus("");
                });
            });
            connection.on('disconnected', () => {
                if (signalRConnectionStatus != "reconnecting")
                    connection.start();
            });
        }
    }, [connection]);

    if (window.innerWidth < 900) {
        return (
            <SwipeableViews enableMouseEvents index={(currentConvId != undefined && currentConvId != '' && currentConvId != 'home') ? 1 : 0}>
                <ConversationList currentConvId={currentConvId} reloadConversationList={reloadConversationList} activeUsersIds={activeUsersIds} />
                {(currentConvId != undefined && currentConvId != '') && (
                    <ConversationContent currentConvId={currentConvId} newMessages={newMessages} loadDetails={loadDetails} />
                )}
            </SwipeableViews>)
    }

    return (
        <div className="row">
            <div className="col-md-4">
                <ConversationList currentConvId={currentConvId} reloadConversationList={reloadConversationList} activeUsersIds={activeUsersIds}/>
            </div>
            <div className="col-md-8">
                {(currentConvId != undefined && currentConvId != '') && (
                    <ConversationContent currentConvId={currentConvId} newMessages={newMessages} loadDetails={loadDetails}/>
                )}
            </div>
        </div>
    );
}

const ConversationList = (props) => {
    const [loading, setIsLoading] = useState(true);
    const [conversations, setConversations] = useState([]);

    const [newConversationModal, setNewConversationModal] = useState(false);

    useEffect(() => {
        get_ajax("Social/Chat/GetMyConversations", (conversations) => {
            setConversations(conversations);
            setIsLoading(false);
        });
    }, [props]);

    return (
        <>
            <div className="card card2 shadow">
                <div className="card-body" style={{ margin: '0', padding: '20px' }}>
                    <button className="btn btn-primary btn-block no-outline" onClick={() => { setNewConversationModal(!newConversationModal) }}>{translate('chat.new_conversation')}</button>
                    <br />
                    <ul className="list-group chat_conversation_list">
                        {loading && (<LoadingCircleComponent />)}
                        {conversations.map((conv, index) =>
                            <Link to={"/chat/" + conv.id} key={index}>
                                <li className="list-group-item list-group-item-action chat_conversation_list_group_item" style={{ cursor: 'pointer' }}>
                                    <img style={{ height: '25px', borderRadius: '50%', border: '2px solid #7f8c8d', marginRight: '10px' }} src={conv.imgPath} className="float-left" />
                                    <label className="h6" style={conv.unRead ? { fontWeight: 'bold', color: '#3D9294' } : {}}>{conv.displayName}</label>
                                    {(conv.userId != null && conv.userId != undefined) && (
                                        <span style={{ marginTop: '7px' }} className={props.activeUsersIds.includes(conv.userId) == true ? "dot_green float-right" : "dot_red float-right"}></span>
                                    )}
                                </li>
                            </Link>
                        )}
                        {(!loading && conversations.length == 0) && (
                            <>
                                <br />
                                <label>{translate('chat.no_convos')}</label>
                            </>
                        )}
                    </ul>
                </div>
            </div>
            <NewConversationDialog isOpen={newConversationModal} toggle={() => { setNewConversationModal(!newConversationModal); }} />
        </>
    );
}

const NewConversationDialog = ({ isOpen, toggle }) => {
    const [privateConv, setPrivateConv] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [loading, setLoading] = useState(true);
    const [objects, setObjects] = useState([]);

    const [toReturn, setToReturn] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        get_ajax("Social/Utils/GetAvaliableResource?resourceType=users&search=" + searchValue, (response) => {
            setObjects(response);
            setLoading(false);
        });
    }, [searchValue]);

    const createPrivateConversation = (userId) => {
        post('Social/Chat/NewPrivateConversation', { userId: userId }, (response) => {
            if (response != "error") {
                setToReturn(response);
                toggle();
            }
        });
    }

    const [groupName, setGroupName] = useState('');
    const [groupUsers, setGroupUsers] = useState([]);

    const createGroupConversation = () => {
        post('Social/Chat/NewGroupConversation', { userIds: JSON.stringify(groupUsers), convName: groupName }, (response) => {
            if (response != "error") {
                setToReturn(response);
                toggle();
            }
        })
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} size='lg'>
            <ModalHeader toggle={toggle}>{translate('chat.new_conversation')}</ModalHeader>
            <ModalBody>
                <ul class="nav nav-tabs">
                    <li className="nav-item" onClick={() => { setPrivateConv(true); }}><a className={privateConv == true ? "nav-link active" : "nav-link"}>{translate('chat.private')}</a></li>
                    <li className="nav-item" onClick={() => { setPrivateConv(false); }}><a className={privateConv == false ? "nav-link active" : "nav-link"}>{translate('chat.group')}</a></li>
                </ul>
                <br />
                {privateConv && (
                    <>
                        <br />
                        <input value={searchValue} onChange={(e) => { setSearchValue(e.target.value); }} className="form-control no-outline" placeholder={translate('generic.search')} />
                        <br />
                        {loading && <LoadingCircleComponent />}
                        <div className='row'>
                            {objects.map((user, key) =>
                                <div className="col-md-6" key={key}>
                                    <div className="groupListIcon" style={{ height: '62px' }} onClick={() => { createPrivateConversation(user.id) }}>
                                        <img style={{ height: '40px', borderRadius: '50%', border: '2px solid #7f8c8d', marginRight: '10px' }} src={user.imagePath} className="float-left" />
                                        <div>
                                            <p style={{ marginBottom: '0' }} className="h6">{user.displayName}</p>
                                            <small className="text-muted">{user.departament}</small>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
                {!privateConv && (
                    <>
                        <br />
                        <input value={groupName} onChange={(e) => { setGroupName(e.target.value); }} className="form-control no-outline" placeholder={translate('chat.group')} />
                        <br />
                        <MembersPicker
                            onlyUsers={true}
                            defaultSelectedUsers={[]}
                            defaultSelectedGroups={[]}
                            defaultSelectedFunctii={[]}
                            defaultSelectedDepartamente={[]}
                            defaultSelectedLocatii={[]}
                            saveCallback={(selectedUsers, selectedGroupsRules, selectedFunctiiRules, selectedDepartamenteRules, selectedLocatiiRules, allUsers) => {
                                setGroupUsers(selectedUsers);
                            }}
                        />
                        <br />
                        <button className="btn btn-primary float-right" onClick={() => { createGroupConversation(); }}>{ translate('generic.add')}</button>
                    </>
                )}

                {(toReturn != undefined) && (
                    <Redirect to={"/chat/" + toReturn} />
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" className="no-outline" onClick={toggle}>{translate('generic.close')}</Button>
            </ModalFooter>
        </Modal>
    );
}

const ConversationContent = ({ currentConvId, newMessages, loadDetails }) => {
    const [messages, setMessages] = useState([]);
    const [loadingBIG, setLoadingBIG] = useState(false);
    const [loading, setLoading] = useState(true);

    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [type, setType] = useState(0);
    const [unread, setUnread] = useState(false);

    const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);

    useEffect(() => {
        load();
    }, [currentConvId]);

    useEffect(() => {        
        if (newMessages.length != 0) {
            const msg_upd = [...messages];
            for (var i in newMessages)
                msg_upd.push(newMessages[i]);
            setMessages(msg_upd)
        }
    }, [newMessages]);

    useEffect(() => {
        setTimeout(() => {
            var d = $("#_A");
            d.animate({
                scrollTop: d.prop("scrollHeight")
            }, 500);
        }, 100);
        post('Social/Chat/MarkAsRead', { conversationId: currentConvId }, () => { });
    }, [messages]);

    const load = () => {
        load_details();
        get_ajax('Social/Chat/GetConversation?conversationId=' + currentConvId, (m) => {
            setMessages(m);
            setLoading(false);           
        });
    }
    const load_details = () => {
        get_ajax('Social/Chat/GetConversationDetails?conversationId=' + currentConvId, (m) => {
            if (m != null) {
                setName(m.name);
                setImage(m.imgPath);
                setType(m.type);
                setLoadingBIG(true);
                setUnread(m.unRead);
            }
        });
    }

    useEffect(() => {
        load_details();
    }, [loadDetails]);

    const [newMessage, setNewMessage] = useState('');

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadFileProgress, setUploadFileProgress] = useState(0);
    const [uploadFileProgressTotal, setUploadFileProgressTotal] = useState(0);

    const inputFileChanged = (e) => {
        setSelectedFile(e.target.files[0]);
    }
    const sendMessage = () => {        
        post('Social/Chat/SendMessage', { conversationId: currentConvId, newComment: newMessage }, async (response) => {
            if (response != "error") {
                setUnread(true);
                setNewMessage("");
                if (selectedFile != null) {
                    let targetUrl = '/Social/Chat/UploadMessageAttachment?messageId=' + response

                    var data = new FormData();
                    data.encoding = "multipart/form-data";
                    data.append('files', selectedFile);
                    var request = new XMLHttpRequest();

                    request.upload.addEventListener('progress', function (e) {
                        setUploadFileProgress(e.loaded);
                        setUploadFileProgressTotal(e.total);
                        if (e.total === e.loaded) {
                            setUploadFileProgress(0);
                            setUploadFileProgressTotal(0);
                            setSelectedFile(null);
                        }
                    }.bind(this), false);

                    request.open('POST', targetUrl);
                    const token = await authService.getAccessToken();
                    request.setRequestHeader('Authorization', `Bearer ${token}`);
                    request.send(data);                    
                }                                
            }                        
        });
    }

    const get_message_text = (msg) =>
    {        
        try {
            for (var i = 0; i < msg.translations.length; i++) {
                if (msg.translations[i].to === sessionStorage.getItem("lang_srv")) {
                    return msg.translations[i].text;
                }
            }
        }
        catch(e) {}
        return msg.message;
    }

    let perc = (uploadFileProgress * 100) / uploadFileProgressTotal;
    if (loadingBIG == false) return (<></>);
    else return (
        <div className="card card3 shadow">
            <div className="card-header">
                <img src={image} className="float-left" style={{ borderRadius: '50%', width: '30px', height: '30px', marginRight: '10px' }} />
                <h5 className="card-title" style={{ display: 'inline' }}>{name}</h5>

                {type == 1 && (
                    <button className="btn btn-secondary no-outline float-right" onClick={() => { setInviteUserDialogOpen(true); }}><AiOutlineUserAdd /></button>
                )}
            </div>
            <div className="card-body" style={{ margin: '0', padding: '20px' }}>
                <div className="messagesBox" id="_A">
                    {messages.map((msg, index) =>
                        <>
                            <div key={index} className="msg_bubble_container">
                                <div className={msg.user.id == "platform" ? "msg_bubble platform" : msg.mine ? "msg_bubble mine" : "msg_bubble"}>
                                    {type == 1 && msg.user.id != "platform" && (
                                        <>
                                            {type == 1 && (
                                                <>
                                                    <img src={msg.user.imagePath} className="float-left" style={{ borderRadius: '50%', width: '25x', height: '25px', marginRight: '5px', marginTop: '1px' }} />
                                                    <label>{msg.user.displayName}</label>
                                                </>
                                            )}
                                            <label className="float-right" style={{ fontSize: '10px' }}>{msg.sentAt}</label>
                                            <br />
                                        </>
                                    )}
                                    {type == 0 && (
                                        <>
                                            <label style={{ fontSize: '10px' }}>{msg.sentAt}</label>
                                            <br />
                                        </>
                                    )}
                                    <div id={`msg-tlp-${index}`}>
                                        {get_message_text(msg)}
                                    </div>
                                    <UncontrolledTooltip placement="top" target={`msg-tlp-${index}`}>
                                        <div dangerouslySetInnerHTML={{ __html: msg.message }}/>
                                    </UncontrolledTooltip>
                                    {msg.attachment != null && (
                                        <>
                                            {(!msg.attachment.originalName.toLowerCase().includes(".png") && !msg.attachment.originalName.toLowerCase().includes(".gif") && !msg.attachment.originalName.toLowerCase().includes(".jpg") && !msg.attachment.originalName.toLowerCase().includes(".jpeg")) ? (
                                                <>
                                                    <br />
                                                    <a className="dropDownPost" style={{ cursor: 'pointer' }} onClick={() => { downloadAs("https://joyhubregular.azureedge.net/" + msg.attachment.blobName, msg.attachment.originalName) }}>
                                                        {msg.attachment.originalName}
                                                    </a>
                                                </>
                                            ) : (<img src={"https://joyhubregular.azureedge.net/" + msg.attachment.blobName} style={{ width: "100%", height: 'auto' }} />)}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div class="spacer" style={{ clear: 'both' }}></div>
                        </>
                    )}
                    {(type == 0 && unread == true) && (
                        <label style={{ margin: '0', fontSize: '10px' }} className="float-right">{translate('chat.unread')}</label>
                    )}
                </div>
            </div>
            <div className="card-footer">
                <textarea
                    className="form-control no-outline"
                    placeholder={translate('chat.new_message')}
                    value={newMessage}
                    onChange={(e) => { setNewMessage(e.target.value); }}
                ></textarea>
                <div style={{ marginTop: '10px' }}>
                    <button className="btn btn-secondary no-outline atchBTN"
                        onClick={() => {
                            $("#cmd_aa").trigger('click');
                        }}
                    >
                        <GrAttachment stroke="white!important" /> { translate('chat.add_file')}
                    </button>
                    {selectedFile != null && (
                        <label style={{ marginLeft: '5px' }}>{selectedFile.name} <a onClick={() => { setSelectedFile(null); }}><GrClose /></a></label>)}
                    <button className="btn btn-primary float-right no-outline" onClick={sendMessage}>{translate('chat.send')}</button>
                </div>
                {uploadFileProgressTotal != 0 && (
                    <>
                        <br />
                        <div style={{ margin: 'auto', width: "calc(100% - 20px)" }}>
                            <br />
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow={perc}
                                    aria-valuemin="0" aria-valuemax="100" style={{ width: perc + "%" }}>
                                    {perc + "%"}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <AddUserToGroupConvoModal isOpen={inviteUserDialogOpen} toggle={() => { setInviteUserDialogOpen(false) }} conversationId={currentConvId} />
            <input hidden style={{ display: 'none', position: 'fixed', top: '-5000em' }} id={"cmd_aa"} type="file" onChange={inputFileChanged} />
        </div>
    );
}

const AddUserToGroupConvoModal = ({ isOpen, toggle, conversationId }) => {    
    const [searchValue, setSearchValue] = useState("");
    const [loading, setLoading] = useState(true);
    const [objects, setObjects] = useState([]);    

    useEffect(() => {
        setLoading(true);
        get_ajax("Social/Utils/GetAvaliableResource?resourceType=users&search=" + searchValue, (response) => {
            setObjects(response);
            setLoading(false);
        });
    }, [searchValue]);

    const createPrivateConversation = (userId) => {
        post('Social/Chat/InviteToConversation', { conversationId: conversationId, userId: userId }, (response) => {
            if (response != false) {
                toggle();
            }
            else {
                alert("Eroare");
            }
        });
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} size='lg'>
            <ModalHeader toggle={toggle}>Invita in conversatie</ModalHeader>
            <ModalBody>                
                <br />
                <input value={searchValue} onChange={(e) => { setSearchValue(e.target.value); }} className="form-control no-outline" placeholder={translate('generic.search')} />
                <br />
                {loading && <LoadingCircleComponent />}
                <div className='row'>
                    {objects.map((user, key) =>
                        <div className="col-md-6" key={key}>
                            <div className="groupListIcon" style={{ height: '62px' }} onClick={() => { createPrivateConversation(user.id) }}>
                                <img style={{ height: '40px', borderRadius: '50%', border: '2px solid #7f8c8d', marginRight: '10px' }} src={user.imagePath} className="float-left" />
                                <div>
                                    <p style={{ marginBottom: '0' }} className="h6">{user.displayName}</p>
                                    <small className="text-muted">{user.departament}</small>
                                </div>
                            </div>
                        </div>
                    )}
                </div>                
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" className="no-outline" onClick={toggle}>{translate('generic.close')}</Button>
            </ModalFooter>
        </Modal>
    );
}