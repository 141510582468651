import React, { useEffect, useState } from 'react';
import { translate } from '../../translations/translator_utils';
import { get_ajax, post } from '../../utils/DataProvider';

export const Notifications = () => {

    const [notifications_LIST, setNotifications] = useState([]);
    const [notificationsCount, setNotificationsCount] = useState(0);

    useEffect(() => {
        get_ajax("/Social/UserManager/GetAllNotifications", (notifications) => {
            setNotifications(notifications);
            setNotificationsCount(notifications.filter(x => x.is_read == false).length);

            post("/Social/UserManager/MarkNotificationsAsRead", {}, (response) => {
                if (response !== true) {
                    alert("Eroare la actualizarea notificarilor");
                }
            });
        });
    }, []);

    return (
        <>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{translate('notifications.title')}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">{notificationsCount + " " + translate('notifications.unred_notifications')}</h6>
                    <br />
                    <ul class="list-group">
                        {notifications_LIST.map((notif, key) =>
                            <li
                                onClick={() => { window.location.href = notif.redirect_to }}
                                className={notif.is_read ? "list-group-item" : "list-group-item font-weight-bold"} style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                                <small className="float-right">{notif.created_at}</small>
                                <br />
                                <p style={{ padding: '0' }}>{notif.text_content}</p>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </>
    );

}