import React, { useEffect, useState } from 'react';
import { get_ajax } from '../../utils/DataProvider';
import { LoadingCircleComponent } from '../buildingBlocks/LoadingCircleComponent';
import { PostBox } from './Home';

export const PostDetails = (props) => {

    const [post, setPost] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadGroupPosts();
    }, []);

    const loadGroupPosts = () => {
        get_ajax('Social/Feed/GetPost?postId=' + props.match.params.postId, (response) => {
            setPost(response[0])
            setLoading(false);
        });
    }

    return (
        <>
            {loading && (<LoadingCircleComponent />)}
            {!loading && (
                <PostBox post={post} reloadPosts={() => { }} showToggle={false} setPost={(x) => { setPost(x) } }/>
            )}
        </>
    );
}