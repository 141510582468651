import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { CircularProgressbar } from 'react-circular-progressbar';
import { LoadingCircleComponent } from './LoadingCircleComponent';

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { translate } from '../../translations/translator_utils';

export const RoundImageUploadField = (props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: 'px', width: 50, aspect: 1 / 1 });
    const [completedCrop, setCompletedCrop] = useState(null);

    const [defaultImagePreview, setDefaultImagePreview] = useState("/images/default_group_image.png");

    useEffect(() => {
        setDefaultImagePreview(props.defaultImagePreview);
    }, [props]);

    const imgBoxClick = () => $('#imgupload').trigger('click');

    function imageInputFileChanged(e) {
        if (e.target.files && e.target.files.length > 0) {            
            setUpImg(URL.createObjectURL(e.target.files[0]));            
            setModalVisible(true);
            setHideSave(false);
        }
    }

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
    }, [completedCrop]);

    function resizeImage(base64Str) {

        var img = new Image();
        img.src = base64Str;
        var canvas = document.createElement('canvas');
        var MAX_WIDTH = 400;
        var MAX_HEIGHT = 350;
        var width = img.width;
        var height = img.height;

        if (width > height) {
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
        } else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        return canvas.toDataURL();
    }

    const [hideSave, setHideSave] = useState(false);
    const saveFile = async () => {                
        props.onSave(croppedIMG);
        setModalVisible(false);
    }

    const [croppedIMG, setCroppedIMG] = useState('');
    const cropperRef = useRef(null);
    const onCrop = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        setCroppedIMG(cropper.getCroppedCanvas().toDataURL());
    };

    return (
        <>
            <input type="file" id="imgupload" accept="image/*" onChange={imageInputFileChanged} style={{ display: 'none' }} />
            <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <div className="imgwrap" style={{ cursor: 'pointer' }}>
                        <div className="service smooth" onClick={imgBoxClick}>
                            <img id="imguploadpreviewcircle" className="img-responsive centerImg" src={defaultImagePreview} />
                            <p style={{ width: '100%', textAlign: 'center' }} className="imgdescription">{translate('generic.pick_image')}</p>
                            <span className="imagePlusIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z" /></svg>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-md-4"></div>
            </div>


            <Modal isOpen={modalVisible} toggle={() => { setModalVisible(false); }}>
                <ModalHeader toggle={() => { setModalVisible(false); }}>{translate('generic.upload_image')}</ModalHeader>
                <ModalBody>                    
                    <Cropper
                        src={upImg}
                        style={{ height: 400, width: "100%" }}                        
                        aspectRatio={1 / 1}
                        autoCropArea={1}
                        guides={false}
                        crop={onCrop}
                        ref={cropperRef}
                    />
                </ModalBody>
                <ModalFooter>
                    {!hideSave && (
                        <>
                            <Button color="primary" onClick={() => { saveFile(); }}>{translate('generic.save')}</Button>
                            <Button color="warning" onClick={() => { setModalVisible(false); }}>{translate('generic.cancel')}</Button>
                        </>
                    )}
                    {hideSave && (<LoadingCircleComponent />)}
                </ModalFooter>
            </Modal>
        </>
    );
}