import React, { useEffect, useState } from 'react';
import { get_ajax, post } from '../../../utils/DataProvider';
import { Collapse } from 'reactstrap';
import { LoadingCircleComponent } from '../../buildingBlocks/LoadingCircleComponent';
import Autocomplete from '../../buildingBlocks/AutocompleteTextBox';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { translate } from '../../../translations/translator_utils';

const languages_list = ["RO", "EN", "HU", "CS", "SK"];

export const ManageUsers = ({ userStatus, adminsOnly }) => {

    const [loading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);

    const [locatiiAutocompleteList, setLocatiiAutocompleteList] = useState([]);
    const [departamenteAutocompleteList, setDepartamenteAutocompleteList] = useState([]);
    const [functiiAutocompleteList, setFunctiiAutocompleteList] = useState([]);

    const [isAddingNewUser, setIsAddingNewUser] = useState(false);
    const [connection, setConnection] = useState(null);

    const [activeUsersIds, setActiveUsersIds] = useState([]);

    const setup_connection = () => {
        const newConnection = new HubConnectionBuilder()
            .withUrl('/hubs/feedhub')
            .withAutomaticReconnect()
            .build();
        setConnection(newConnection);
    }

    useEffect(() => {
        loadUsers();
        setup_connection();
        get_ajax('Social/UserManager/GetPublicAvailableGroups', (response) => {
            setGroupsOptions(response);
        });
    }, []);    

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(async (result) => {
                    console.log('Connected!');

                    connection.on('UPDATE_D', (data) => {
                        setActiveUsersIds(data);                        
                    });

                    await connection.invoke('RequestLiveUsersData')
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    const loadUsers = async () => {
        await get_ajax('Social/UserManager/GetClientUsers?state=' + userStatus + '&adminsOnly=' + adminsOnly, (response) => {
            if(langFilter == 'all')
            {
                setUsers(response);
            }
            else if(langFilter == 'none')
            {
                var newArray = response.filter(function (el) {
                    return !languages_list.includes(el.lang);
                });
                setUsers(newArray);
            }
            else {
                var newArray = response.filter(function (el) {
                    return el.lang == langFilter;
                });
                setUsers(newArray);
            }
            setIsLoading(false);          
        });

        await getLocatiiAutoComplete();
        await getDepartamenteAutoComplete();
        await getFunctiiAutoComplete();
    }

    const getLocatiiAutoComplete = async () => {
        await get_ajax('Social/UserManager/GetCurrentLocatii', (response) => { setLocatiiAutocompleteList(response) });
    }

    const getDepartamenteAutoComplete = async () => {
        await get_ajax('Social/UserManager/GetCurrentDepartamente', (response) => { setDepartamenteAutocompleteList(response); });
    }

    const getFunctiiAutoComplete = async () => {
        await get_ajax('Social/UserManager/GetCurentFunctii', (response) => { setFunctiiAutocompleteList(response); });
    }

    const [groupsOptions, setGroupsOptions] = useState([]);
    
    const [langFilter, setLangFilter] = useState(sessionStorage.getItem("filter") || 'all');
    const [isImportUsers, setIsImportUsers] = useState(false);

    useEffect(()=>{
        setIsLoading(true);
        get_ajax('Social/UserManager/GetClientUsers?state=' + userStatus + '&adminsOnly=' + adminsOnly, (response) => {
            if(langFilter == 'all')
                {
                    setUsers(response);
                }
            else if(langFilter == 'none')
            {
                var newArray = response.filter(function (el) {
                    return !languages_list.includes(el.lang);
                  });
                  setUsers(newArray);
            }
            else {
                var newArray = response.filter(function (el) {
                    return el.lang == langFilter;
                  });
                  setUsers(newArray);
            }
            setIsLoading(false);            
        });
        sessionStorage.setItem("filter",langFilter);
    }, [langFilter]);

    return (
        loading ? <LoadingCircleComponent/> :
            <>
{!isAddingNewUser && (
                    <>
                        <label style={{ marginLeft: "5px" }}>{users.length} {translate('generic.users')}</label>
                        <div className="row">
                            <div className="col-md-4">
                                <select className="form-control no-outline" value={langFilter} onChange={(e) => {setLangFilter(e.target.value)}}>
                                    <option value="all">All</option>
                                    {languages_list.map((x, i) =>
                                        <option key={i} value={x}>{x}</option>
                                    )}                                    
                                </select>
                            </div>
                            <div className='col-md-4'></div>
                            <div className='col-md-4'>
                                <button style={{visibility: 'hidden'}} type="button" class="btn btn-primary float-right" onClick={() => { setIsImportUsers(true) }}>! IMPORT !</button>
                            </div>
                        </div>                                                
                        <button type="button" class="btn btn-primary float-right" style={{ marginBottom: '10px' }} onClick={() => { setIsAddingNewUser(true) }}>{translate('generic.add')}</button>
                        <table className="table tableLastRight" style={{ borderTop: 'none' }}>
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col"></th>
                                    <th scope="col">{translate('generic.name')}</th>
                                    <th scope="col">{translate('admin.forename')}</th>
                                    <th scope="col">{translate('admin.username')}</th>
                                    <th scope="col">{userStatus ? translate('admin.create_date') : translate('admin.disable_date')}</th>
                                    <th scope="col">{translate('admin.details')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, index) =>
                                    <TableUserRow
                                        user={user}
                                        rowCount={index}
                                        key={index}
                                        forceUsersReload={loadUsers}
                                        aud={activeUsersIds.includes(user.id)}
                                        collapseOpen={false}

                                        targetState={!userStatus}
                                        groupsOptions={groupsOptions}
                                        departamenteAutocompleteList={departamenteAutocompleteList}
                                        locatiiAutocompleteList={locatiiAutocompleteList}
                                        functiiAutocompleteList={functiiAutocompleteList}
                                    />
                                )}
                            </tbody>
                        </table>
                    </>
                )}
{isAddingNewUser && (
                    <AddNewUserView
                        departamenteAutocompleteList={departamenteAutocompleteList}
                        locatiiAutocompleteList={locatiiAutocompleteList}
                        functiiAutocompleteList={functiiAutocompleteList}
                        cancelCallback={() => { setIsAddingNewUser(false); }}

                        forceUsersReload={loadUsers}
                    />
                )}
                {isImportUsers && (
                    <ImportNewUserView
                        departamenteAutocompleteList={departamenteAutocompleteList}
                        locatiiAutocompleteList={locatiiAutocompleteList}
                        functiiAutocompleteList={functiiAutocompleteList}
                        cancelCallback={() => { setIsAddingNewUser(false); }}

                        forceUsersReload={loadUsers}
                    />
                )}
</>
    );
};

const ImportNewUserView = (props) => {
    const [userName, setUserName] = useState('');
    const [parola, setPassword] = useState('');

    const [nume, setNume] = useState('');
    const [prenume, setPrenume] = useState('');
    const [email, setEmail] = useState('');
    const [telefon, setTelefon] = useState('');

    const [departament, setDepartament] = useState('');
    const [locatie, setLocatie] = useState('');
    const [functie, setFunctie] = useState('');

    const [dataNastere, setDataNastere] = useState('');

    const [numarMatricol, setNumarMatricol] = useState('');

    const [lang, setLang] = useState(languages_list[0]);
    const [defaultGroup, setDefaultGroup] = useState('-');

    const [groupsOptions, setGroupsOptions] = useState([]);

    useEffect(() => {        
        get_ajax('Social/UserManager/GetPublicAvailableGroups', (response) => {
            setGroupsOptions(response);
        });
    }, []);

    const createNewUser = () => {                
        const lines = csv.split('\n');
        lines.forEach(line => {
            var parts = line.split(',');                        
             post('Social/UserManager/CreateNewUser', {
                "username": parts[0],
                "password": "MyMarionnaud123!",
                "nume": parts[1],
                "prenume": parts[2],
                "email": parts[3],
                "phone": parts[4],
                "departament": parts[7],
                "locatie": parts[8],
                "functie": parts[6],
                "bDay": parts[5],
                "numarMatricol": parts[9],
                "lang": lang,
                "defaultGroup": defaultGroup
            }, (response) => {
                if (response) {
                    alert(`OK ${parts[0]}`);
                }
                else {
                    alert("Eroare la salvare");
                }
            });
        });        
    }

    const [csv, setCSV] = useState('');

    return (
        <>
            <div>
                <label>CSV Data:</label>
                <textarea rows={10} className='form-control' value={csv} onChange={(e)=>{setCSV(e.target.value)}}></textarea>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>{translate('admin.username')}</label>
                        <input
                            className="form-control no-outline"
                            placeholder={translate('admin.username')}
                            value={userName}
                            onChange={(e) => { setUserName(e.target.value); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.password')}</label>
                        <input
                            className="form-control no-outline"
                            placeholder={translate('admin.password')}
                            value={parola}
                            onChange={(e) => { setPassword(e.target.value); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('generic.name')}</label>
                        <input
                            className="form-control no-outline"
                            placeholder={translate('generic.name')}
                            value={nume}
                            onChange={(e) => { setNume(e.target.value); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.forename')}</label>
                        <input
                            className="form-control no-outline"
                            placeholder={translate('admin.forename')}
                            value={prenume}
                            onChange={(e) => { setPrenume(e.target.value); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.email')}</label>
                        <input
                            className="form-control no-outline"
                            placeholder={translate('admin.email')}
                            value={email}
                            onChange={(e) => { setEmail(e.target.value); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.phone')}</label>
                        <input
                            className="form-control no-outline"
                            placeholder={translate('admin.phone')}
                            value={telefon}
                            onChange={(e) => { setTelefon(e.target.value); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.birth_date')}</label>
                        <input value={dataNastere} onChange={(e) => { setDataNastere(e.target.value); }} className="form-control no-outline" type="date" />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.lang')}</label>
                        <select                            
                            className="form-control no-outline"
                            value={lang}
                            onChange={(e) => { setLang(e.target.value); }}
                        >
                            {languages_list.map((x, i) =>
                                <option key={i} value={x}>{x}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>{translate('admin.functie')}</label>
                        <Autocomplete
                            placeholder={translate('admin.functie')}
                            suggestions={props.functiiAutocompleteList}
                            value={functie}
                            onChange={(e) => { setFunctie(e); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.dept')}</label>
                        <Autocomplete
                            placeholder={translate('admin.dept')}
                            suggestions={props.departamenteAutocompleteList}
                            value={departament}
                            onChange={(e) => { setDepartament(e); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.loc')}</label>
                        <Autocomplete
                            placeholder={translate('admin.loc')}
                            suggestions={props.locatiiAutocompleteList}
                            value={locatie}
                            onChange={(e) => { setLocatie(e); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.nr_mat')}</label>
                        <input
                            className="form-control no-outline"
                            placeholder={translate('admin.nr_mat')}
                            value={numarMatricol}
                            onChange={(e) => { setNumarMatricol(e.target.value); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.default_group')}</label>
                        <select
                            className="form-control no-outline"
                            value={defaultGroup}
                            onChange={(e) => { setDefaultGroup(e.target.value); }}
                        >
                            <option value={'-'}>-</option>
                            {groupsOptions.map((x, index) =>
                                <option value={x.id} key={index}>{x.groupName}</option>
                            )}
                        </select>
                    </div>
                    <div className="form-group float-right">
                        <br />
                        <button type="button" className="btn btn-secondary no-outline" onClick={props.cancelCallback} style={{ marginTop: '8px', marginRight: '5px' }}>{translate('generic.cancel')}</button>
                        <button type="button" className="btn btn-primary no-outline" onClick={createNewUser} style={{ marginTop: '8px' }}>{translate('generic.save')}</button>
                    </div>
                </div>
            </div>
        </>
    );
}

const AddNewUserView = (props) => {
    const [userName, setUserName] = useState('');
    const [parola, setPassword] = useState('');

    const [nume, setNume] = useState('');
    const [prenume, setPrenume] = useState('');
    const [email, setEmail] = useState('');
    const [telefon, setTelefon] = useState('');

    const [departament, setDepartament] = useState('');
    const [locatie, setLocatie] = useState('');
    const [functie, setFunctie] = useState('');

    const [dataNastere, setDataNastere] = useState('');

    const [numarMatricol, setNumarMatricol] = useState('');

    const [lang, setLang] = useState(languages_list[0]);
    const [defaultGroup, setDefaultGroup] = useState('-');

    const [groupsOptions, setGroupsOptions] = useState([]);

    useEffect(() => {        
        get_ajax('Social/UserManager/GetPublicAvailableGroups', (response) => {
            setGroupsOptions(response);
        });
    }, []);

    const createNewUser = () => {
        post('Social/UserManager/CreateNewUser', {
            "username": userName,
            "password": parola,
            "nume": nume,
            "prenume": prenume,
            "email": email,
            "phone": telefon,
            "departament": departament,
            "locatie": locatie,
            "functie": functie,
            "bDay": dataNastere,
            "numarMatricol": numarMatricol,
            "lang": lang,
            "defaultGroup": defaultGroup
        }, (response) => {
            if (response) {
                props.forceUsersReload();
                props.cancelCallback();
            }
            else {
                alert("Eroare la salvare");
            }
        });
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>{translate('admin.username')}</label>
                        <input
                            className="form-control no-outline"
                            placeholder={translate('admin.username')}
                            value={userName}
                            onChange={(e) => { setUserName(e.target.value); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.password')}</label>
                        <input
                            className="form-control no-outline"
                            placeholder={translate('admin.password')}
                            value={parola}
                            onChange={(e) => { setPassword(e.target.value); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('generic.name')}</label>
                        <input
                            className="form-control no-outline"
                            placeholder={translate('generic.name')}
                            value={nume}
                            onChange={(e) => { setNume(e.target.value); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.forename')}</label>
                        <input
                            className="form-control no-outline"
                            placeholder={translate('admin.forename')}
                            value={prenume}
                            onChange={(e) => { setPrenume(e.target.value); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.email')}</label>
                        <input
                            className="form-control no-outline"
                            placeholder={translate('admin.email')}
                            value={email}
                            onChange={(e) => { setEmail(e.target.value); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.phone')}</label>
                        <input
                            className="form-control no-outline"
                            placeholder={translate('admin.phone')}
                            value={telefon}
                            onChange={(e) => { setTelefon(e.target.value); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.birth_date')}</label>
                        <input value={dataNastere} onChange={(e) => { setDataNastere(e.target.value); }} className="form-control no-outline" type="date" />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.lang')}</label>
                        <select                            
                            className="form-control no-outline"
                            value={lang}
                            onChange={(e) => { setLang(e.target.value); }}
                        >
                            {languages_list.map((x, i) =>
                                <option key={i} value={x}>{x}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>{translate('admin.functie')}</label>
                        <Autocomplete
                            placeholder={translate('admin.functie')}
                            suggestions={props.functiiAutocompleteList}
                            value={functie}
                            onChange={(e) => { setFunctie(e); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.dept')}</label>
                        <Autocomplete
                            placeholder={translate('admin.dept')}
                            suggestions={props.departamenteAutocompleteList}
                            value={departament}
                            onChange={(e) => { setDepartament(e); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.loc')}</label>
                        <Autocomplete
                            placeholder={translate('admin.loc')}
                            suggestions={props.locatiiAutocompleteList}
                            value={locatie}
                            onChange={(e) => { setLocatie(e); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.nr_mat')}</label>
                        <input
                            className="form-control no-outline"
                            placeholder={translate('admin.nr_mat')}
                            value={numarMatricol}
                            onChange={(e) => { setNumarMatricol(e.target.value); }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.default_group')}</label>
                        <select
                            className="form-control no-outline"
                            value={defaultGroup}
                            onChange={(e) => { setDefaultGroup(e.target.value); }}
                        >
                            <option value={'-'}>-</option>
                            {groupsOptions.map((x, index) =>
                                <option value={x.id} key={index}>{x.groupName}</option>
                            )}
                        </select>
                    </div>
                    <div className="form-group float-right">
                        <br />
                        <button type="button" className="btn btn-secondary no-outline" onClick={props.cancelCallback} style={{ marginTop: '8px', marginRight: '5px' }}>{translate('generic.cancel')}</button>
                        <button type="button" className="btn btn-primary no-outline" onClick={createNewUser} style={{ marginTop: '8px' }}>{translate('generic.save')}</button>
                    </div>
                </div>
            </div>
        </>
    );
}

const TableUserRow = (props) => {

    const [groupsOptions, setGroupsOptions] = useState(props.groupsOptions);

    useEffect(() => {
        setIsCollapseOpen(props.collapseOpen);        
    }, [props]);

    const [isCollapseOpen, setIsCollapseOpen] = useState(false);
    const [isFormInEditMode, setIsInEditMode] = useState(false);

    const [nume, setNume] = useState(props.user.nume);
    const [prenume, setPrenume] = useState(props.user.prenume);
    const [email, setEmail] = useState(props.user.email);
    const [telefon, setTelefon] = useState(props.user.phoneNumber);

    const [departament, setDepartament] = useState(props.user.departament);
    const [locatie, setLocatie] = useState(props.user.locatie);
    const [functie, setFunctie] = useState(props.user.functie);

    const [dataNastere, setDataNastere] = useState(props.user.birthDate);
    const [numarMatricol, setNumarMatricol] = useState(props.user.numarMatricol);

    const [lang, setLang] = useState(props.user.lang);
    const [defaultGroup, setDefaultGroup] = useState(props.user.group);

    const saveChanges = () => {        
        post('Social/UserManager/UpdateExistingUser', {
            "targetUserId": props.user.id,
            "nume": nume,
            "prenume": prenume,
            "email": email,
            "phone": telefon,
            "departament": departament,
            "locatie": locatie,
            "functie": functie,
            "bDay": dataNastere,
            "numarMatricol": numarMatricol,
            "lang": lang,
            "defaultGroup": defaultGroup
        }, (response) => {
            if (response) {
                props.forceUsersReload();
                window.location.reload();
            }
            else {
                alert("Eroare la salvare");
            }
        });
    }

    const updateStareUtilizator = () => {
        post('Social/UserManager/UpdateStareUtilizator', {
            "targetUserId": props.user.id,
            "active": props.targetState
        }, (response) => {
            if (response) {
                props.forceUsersReload();
            }
            else {
                alert("Eroare la salvare");
            }
        });
    }

    const updateAdminUtilizator = (value) => {
        post('Social/UserManager/SetAdminRole', {
            "targetUserId": props.user.id,
            "isAdmin": value
        }, (response) => {
            if (response) {
                props.forceUsersReload();
            }
            else {
                alert("Eroare la salvare");
            }
        });
    }

    const resetPassword = () => {
        post('Social/UserManager/ResetPassword', {
            "targetUserId": props.user.id,           
        }, (response) => {
            alert(response);
        });
    }

    return (
        <>
            <tr>
                <th scope="row">{props.rowCount + 1}</th>
                <th>
                    <span className={props.aud == true ? "dot_green" : "dot_red"}></span>
                </th>
                <td>{props.user.nume}</td>
                <td>{props.user.prenume}</td>
                <td>{props.user.userName}</td>
                {props.targetState === false && (
                    <td>{props.user.dataCreare}</td>
                )}
                {props.targetState === true && (
                    <td>{props.user.dataDezactivare}</td>
                )}
                <td onClick={() => { setIsCollapseOpen(!isCollapseOpen); }}>
                    <svg className={isCollapseOpen ? "tableRowIconRotated tableRowIcon" : "tableRowIcon"} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 12c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12zm-18.005-1.568l1.415-1.414 4.59 4.574 4.579-4.574 1.416 1.414-5.995 5.988-6.005-5.988z" /></svg>
                </td>
            </tr>
            <tr>
                <td colSpan={6} className="hiddenRow">
                    <Collapse isOpen={isCollapseOpen} style={{ textAlign: 'left', padding: '10px' }}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{translate('generic.name')}</label>
                                    <input
                                        className="form-control no-outline"
                                        placeholder={translate('generic.name')}
                                        disabled={!isFormInEditMode}
                                        value={nume}
                                        onChange={(e) => { setNume(e.target.value); }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{translate('admin.forename')}</label>
                                    <input
                                        className="form-control no-outline"
                                        placeholder={translate('admin.forename')}
                                        disabled={!isFormInEditMode}
                                        value={prenume}
                                        onChange={(e) => { setPrenume(e.target.value); }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{translate('admin.email')}</label>
                                    <input
                                        className="form-control no-outline"
                                        placeholder={translate('admin.email')}
                                        disabled={!isFormInEditMode}
                                        value={email}
                                        onChange={(e) => { setEmail(e.target.value); }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{translate('admin.phone')}</label>
                                    <input
                                        className="form-control no-outline"
                                        placeholder={translate('admin.phone')}
                                        disabled={!isFormInEditMode}
                                        value={telefon}
                                        onChange={(e) => { setTelefon(e.target.value); }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{translate('admin.birth_date')}</label>
                                    <input disabled={!isFormInEditMode} value={dataNastere} onChange={(e) => { setDataNastere(e.target.value); }} className="form-control" type="date" />
                                </div>

                                <div className="form-group">
                                    <label>{translate('admin.lang')}</label>
                                    <select
                                        disabled={!isFormInEditMode}
                                        className="form-control no-outline"
                                        value={lang}
                                        onChange={(e) => { setLang(e.target.value); }}
                                    >
                                        {languages_list.map((x, i) =>
                                            <option key={i} value={x}>{x}</option>
                                        )}                                       
                                    </select>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{translate('admin.functie')}</label>
                                    <Autocomplete
                                        placeholder={translate('admin.functie')}
                                        disabled={!isFormInEditMode}
                                        suggestions={props.functiiAutocompleteList}
                                        value={functie}
                                        onChange={(e) => { setFunctie(e); }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{translate('admin.dept')}</label>
                                    <Autocomplete
                                        placeholder={translate('admin.dept')}
                                        disabled={!isFormInEditMode}
                                        suggestions={props.departamenteAutocompleteList}
                                        value={departament}
                                        onChange={(e) => { setDepartament(e); }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{translate('admin.loc')}</label>
                                    <Autocomplete
                                        placeholder={translate('admin.loc')}
                                        disabled={!isFormInEditMode}
                                        suggestions={props.locatiiAutocompleteList}
                                        value={locatie}
                                        onChange={(e) => { setLocatie(e); }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{translate('admin.nr_mat')}</label>
                                    <input
                                        className="form-control no-outline"
                                        placeholder={translate('admin.nr_mat')}
                                        disabled={!isFormInEditMode}
                                        value={numarMatricol}
                                        onChange={(e) => { setNumarMatricol(e.target.value); }}
                                    />
                                </div>
                                
                                {props.targetState === false && (
                                    <>
                                        {props.user.dataPrimaLogare == "01-01-0001 00:00" && (
                                            <div className="form-group">
                                                <label>{translate('admin.original_pass')}</label>
                                                <input disabled={true} value={props.user.originalPassword} className="form-control no-outline" />
                                            </div>
                                        )}
                                        {props.user.dataPrimaLogare != "01-01-0001 00:00" && (
                                            <div className="form-group">
                                                <label>{translate('admin.f_pass')}</label>
                                                <input disabled={true} value={props.user.dataPrimaLogare} className="form-control no-outline" />
                                            </div>
                                        )}
                                    </>
                                )}
                                {props.targetState === true && (
                                    <div className="form-group">
                                        <label>{translate('admin.create_date')}</label>
                                        <input disabled={true} value={props.user.dataCreare} className="form-control no-outline" />
                                    </div>
                                )}

                                <div className="form-group">
                                    <label>{translate('admin.default_group')}</label>
                                    <select
                                        className="form-control no-outline"
                                        disabled={!isFormInEditMode}
                                        value={defaultGroup}
                                        onChange={(e) => { setDefaultGroup(e.target.value); }}
                                    >
                                        <option value={'-'}>-</option>
                                        {groupsOptions.map((x, index) =>
                                            <option value={x.id} key={index}>{x.groupName}</option>
                                        )}                                        
                                    </select>
                                </div>

                                <br />
                                <button onClick={updateStareUtilizator} type="button" className="btn btn-danger no-outline" style={{ marginTop: '8px', marginRight: '5px' }}>{props.targetState === false ? translate('admin.disable') : translate('admin.enable')}</button>

                                <button onClick={() => {
                                    updateAdminUtilizator(!props.user.isAdmin);
                                }} type="button" className="btn btn-secondary no-outline" style={{ marginTop: '8px', marginRight: '5px' }}>{props.user.isAdmin === false ? translate('admin.name_admin') : translate('admin.revoke_admin')}</button>

                                <button onClick={() => {
                                    resetPassword();
                                }} type="button" className="btn btn-danger no-outline" style={{ marginTop: '8px', marginRight: '5px' }}>{translate('admin.reset_password')}</button>

                                <div className="form-group float-right">                                                   
                                    <button type="button" className="btn btn-warning no-outline" onClick={() => { setIsInEditMode(!isFormInEditMode); }} style={{ marginTop: '8px', marginRight: '5px' }}>{isFormInEditMode ? translate('generic.cancel') : translate('generic.edit')}</button>
                                    <button type="button" className="btn btn-primary no-outline" onClick={saveChanges} disabled={!isFormInEditMode} style={{ marginTop: '8px' }}>{translate('generic.save')}</button>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </td>
            </tr>
        </>
    );
}