import React, { useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { get_ajax, post, post_dn } from '../../utils/DataProvider';
import authService from '../api-authorization/AuthorizeService';
import { LoadingCircleComponent } from '../buildingBlocks/LoadingCircleComponent';
import { ButtonDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Collapse, Button, CardBody, Card, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import { RiDeleteBin5Line, RiCameraFill, RiEdit2Line, RiStarSFill, RiStarSLine, RiLinksFill, RiAttachmentLine, RiImageLine, RiLiveLine } from 'react-icons/ri';
import { ImFilesEmpty, ImFileZip, ImFilePdf, ImFileMusic, ImFileText2, ImFileExcel, ImFileWord } from 'react-icons/im';
import { BsCardText } from "react-icons/bs";
import { AiOutlineFilePpt, AiOutlinePhone, AiOutlineComment, AiOutlineLike } from 'react-icons/ai'
import { BiMessageRoundedDots } from 'react-icons/bi';
import { VscPinned } from 'react-icons/vsc';
import { GrAttachment, GrCircleQuestion } from "react-icons/gr";
import OverflowText from '../buildingBlocks/OverflowText';
import axios from 'axios';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { FacebookSelector } from '@charkour/react-reactions';
import { useInView } from 'react-intersection-observer';
import { HomeFeed } from './Home';
import { CircularProgressbarWithChildren, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { IoLocationOutline } from 'react-icons/io5';
import { VscOrganization, VscLocation, VscMail } from 'react-icons/vsc';
import { RiCake2Line } from 'react-icons/ri';
import { translate } from '../../translations/translator_utils';

export const Profile = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.match.params.profileId]);

    const [onlyMyPosts, setOnlyMyPosts] = useState(false);
    return (
        <div className="row">
            <div className="col-md-3" >
                <SideMenu userId={props.match.params.profileId} postarileMeleCallback={() => {
                    setOnlyMyPosts(true);
                }} />
            </div>
            <div className="col-md-9">
                <div class="d-none d-md-block">
                    <HomeFeed filterByUser={onlyMyPosts ? props.match.params.profileId : undefined} blockPosts={true} />
                </div>
            </div>
        </div>
    );
}

export const SideMenu = ({ userId, postarileMeleCallback }) => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [redirectClicked, setRedirectClicked] = useState(false);

    const [toReturn, setToReturn] = useState(undefined);

    useEffect(() => {
        get_ajax("Social/UserManager/GetUserDetails?userId="+userId, (response) => {
            setUserData(response);
            setLoading(false);
            console.log(response);
        });
    }, [userId]);

    return (
        loading ? <LoadingCircleComponent /> :
            <div className="sideMenu">
                {(toReturn != undefined) && (
                    <Redirect to={"/chat/" + toReturn} />
                )}
                <div className="card text-center shadow" style={{ marginBottom: '10px' }}>
                    <div className="card-body">
                        <img src={userData.imagePath} className="shadow" style={{ display: 'block', margin: 'auto', borderRadius: '50%', border: '5px solid #3D9294', marginTop: '-10px', marginBottom: '5px', width: '75%' }} />
                        <h4 className="card-title" style={{ marginBottom: '0' }}>{userData.prenume + " " + userData.nume}</h4>
                        <h5 className="card-title text-secondary" style={{ marginTop: '-10px;' }}>{userData.functie != null ? userData.functie.nume : ''}</h5>
                        <button className="btn btn-warning" style={userData.active == false ? { filter: 'grayscale(1)' } : {}} disabled={userData.active == false} onClick={() => {
                            if (sessionStorage.getItem("current_user_id") == userData.id)
                                setRedirectClicked(true);
                            else{
                                post('Social/Chat/NewPrivateConversation', { userId: userData.id }, (response) => {
                                    if (response != "error") {
                                        setToReturn(response);                                        
                                    }
                                });
                            }
                        }}>
                            {sessionStorage.getItem("current_user_id") == userData.id ? translate('generic.edit') : userData.active ? translate('profile.private_message') : translate('profile.user_disabled')}
                        </button>
                        {redirectClicked && (<>
                            <Redirect to={'/editProfile'} />
                        </>)}
                    </div>
                </div>
                <UserBadge userId={userId} />
                <div className="card shadow" style={{ marginBottom: '10px' }}>
                    <div className="card-body nopad">
                        <p>
                            <VscLocation />
                            {userData.locatie ? userData.locatie.nume : ''}
                        </p>
                        <p>
                            <VscOrganization />
                            {userData.departament ? userData.departament.nume : ''}
                        </p>
                        <div className="d-none d-md-block">
                            <button onClick={() => { postarileMeleCallback(); }} className=" btn btn-primary btn-block no-outline" style={{ marginTop: '10px' }}>
                                {sessionStorage.getItem("current_user_id") == userData.id ? translate('profile.my_posts') : translate('profile.users_post')}
                            </button>
                        </div>
                        <div className="d-md-none">
                            <Link to={'/profileMyPostsMobile/' + userData.id} className="btn btn-primary btn-block no-outline asdfasd" style={{ marginTop: '10px', color: 'white!important' }}>
                                {sessionStorage.getItem("current_user_id") == userData.id ? translate('profile.my_posts') : translate('profile.users_post')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export const UserBadge = ({ userId }) => {
    const [percentage1, setPercentage1] = useState(60);
    const [percentage2, setPercentage2] = useState(20);
    const [percentage3, setPercentage3] = useState(85);

    const [levelName, setLevelName] = useState('');
    const [levelBadge, setLevelBadge] = useState('');    

    useEffect(() => {
        get_ajax('Social/UserManager/GetBadgeDetails?userId=' + userId, (response) => {
            setLevelName(response.levelName);
            setLevelBadge(response.levelBadge);

            setPercentage1(100 * response.likesCount / response.likesTarget);
            setPercentage2(100 * response.commentsCount / response.commentsTarget);
            setPercentage3(100 * response.postsCount / response.postsTarget);
        });
    }, [userId]);

    return (
        <div className="card text-center shadow" style={{ marginBottom: '10px' }}>
            <div className="card-body">
                <div className="badgeBox">
                    <h5 className="card-title overlayText">{levelName}</h5>
                    <img src={levelBadge} style={{ marginBottom: '10px' }} />
                    <div className="row">
                        <div className="col-4">
                            <CircularProgressbarWithChildren                                
                                value={percentage1}
                                styles={buildStyles({
                                    pathColor: '#e74c3c'
                                })}
                            >
                                <AiOutlineLike />                                
                            </CircularProgressbarWithChildren>
                        </div>
                        <div className="col-4">
                            <CircularProgressbarWithChildren
                                value={percentage2}
                            >
                                <AiOutlineComment />
                            </CircularProgressbarWithChildren>
                        </div>
                        <div className="col-4">
                            <CircularProgressbarWithChildren
                                value={percentage3}
                                styles={buildStyles({
                                    pathColor: '#16a085'
                                })}
                            >
                                <BsCardText />
                            </CircularProgressbarWithChildren>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}