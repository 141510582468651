import authService from "../components/api-authorization/AuthorizeService"
import $ from 'jquery';

export const get = async (path) => {
    const token = await authService.getAccessToken();
    const response = await fetch(path, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    if (response.status == 401) {
        authService.signIn();
    }
    return await response.json();
}

export const post = async (path, body, callBack) => {
    const token = await authService.getAccessToken();
    await $.ajax({
        type: "POST",
        url: path,
        headers: !token ? {} : {
            'Authorization': `Bearer ${token}`
        },
        data: body,
        async: true,
        success: function (data) {
            callBack(data);
        }
    }) 
}
export const post_dn = async (path, body, callBack) => {
    const token = await authService.getAccessToken();
    await $.ajax({
        type: "POST",
        url: path,
        headers: !token ? {} : {
            'Authorization': `Bearer ${token}`
        },
        data: body,
        async: true,
        success: function (data) {
            callBack(data);
        }
    })
}

export const get_ajax = async (path, callback) => {
    const token = await authService.getAccessToken();
    await $.ajax({
        type: "GET",
        url: path,
        headers: !token ? {} : {
            'Authorization': `Bearer ${token}`
        },        
        async: true,
        success: function (data) {
            callback(data);
        }
    })
}