import React, { useState } from 'react';
import { translate } from '../../../translations/translator_utils';
import { ManageUsers } from './ManageUsers';
import { PlatformActivity } from './PlatformActivity';

export const AdminDashboard = () => {
    const [currentTab, setCurrentTab] = useState("activeUsers");

    return (
        <div className="card">
            <div className="card-body">
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className={currentTab == "activeUsers" ? "nav-link active" : "nav-link"} onClick={() => { setCurrentTab("activeUsers") }}>{translate('generic.active_users')}</a>
                    </li>
                    <li className="nav-item">
                        <a className={currentTab == "disabledUsers" ? "nav-link active" : "nav-link"} onClick={() => { setCurrentTab("disabledUsers") }}>{translate('generic.disabled_users')}</a>
                    </li>
                    <li className="nav-item">
                        <a className={currentTab == "adminUsers" ? "nav-link active" : "nav-link"} onClick={() => { setCurrentTab("adminUsers") }}>{translate('groups.administrators')}</a>
                    </li>
                    <li className="nav-item">
                        <a className={currentTab == "platformActivity" ? "nav-link active" : "nav-link"} onClick={() => { setCurrentTab("platformActivity") }}>{translate('admin.platform_activity')}</a>
                    </li>
                </ul>
                <br/>
                {currentTab === "activeUsers" && (
                    <ManageUsers adminsOnly={false} userStatus={true} />
                )}
                {currentTab === "disabledUsers" && (
                    <ManageUsers adminsOnly={false} userStatus={false} />
                )}
                {currentTab === "adminUsers" && (
                    <ManageUsers adminsOnly={true} userStatus={true} />
                )}
                {currentTab === "platformActivity" && (
                    <PlatformActivity/>
                )}
            </div>
        </div>
    );
}