import React, { useEffect, useState } from 'react'
import { get_ajax, post } from '../../utils/DataProvider';
import { RoundImageUploadField } from '../buildingBlocks/RoundImageUploadModal';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import { translate } from '../../translations/translator_utils';

export const ProfileEditor = () => {
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [nume, setNume] = useState('');
    const [prenume, setPrenume] = useState('');
    const [email, setEmail] = useState('');
    const [telefon, setTelefon] = useState('');
    const [dataNasterii, setDataNasterii] = useState('');
    const [functie, setFunctie] = useState('');
    const [departament, setDepartament] = useState('');
    const [locatie, setLocatie] = useState('');
    const [nrMatricol, setNrMatricol] = useState('');

    const [imagePath, setImagePath] = useState('');
    const [imageData, setImageData] = useState(null);

    useEffect(() => {
        get_ajax("Social/UserManager/GetUserDetails", (response) => {
            setUserId(response.id);
            setUserName(response.uname);
            setNume(response.nume);
            setPrenume(response.prenume);
            setEmail(response.email);
            setTelefon(response.phoneNumber);
            setDataNasterii(response.birthDateF);
            setFunctie(response.functie ? response.functie.nume: '');
            setDepartament(response.departament ? response.departament.nume: '');
            setLocatie(response.locatie ? response.locatie.nume : '');
            setNrMatricol(response.numarMatricol);            

            setImagePath(response.imagePath);
        });
    }, []);

    const [killEdit, setKillEdit] = useState(false);

    const saveEdits = async () => {
        setKillEdit(true);
        post('Social/UserManager/UpdateOwnProfile', {
            'name': nume,
            'forename': prenume,
            'email': email,
            'phone': telefon,
            'birthDate': dataNasterii,
            'image': imagePath
        }, (response) => {
            if (response != "true") {
                alert(response);
                alert("Eroare la salvare!");
            }
            else {
                window.location.reload();
            }
        });
    }

    const [isPasswordResetDialogOpen, setIsPasswordResetDialogOpen] = useState(false);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

    const onConfirm = () => {
        post('Social/UserManager/UpdateUserPassword', {
            'currentPass': currentPassword,
            'newPass': newPassword,
            'newPassConfirm': newPasswordConfirm
        }, (response) => {
            if (response != "true") {
                alert(response);
            }
            else {
                window.location.reload();
            }
        });
    };

    return (
        <div className="card">
            <div className="card-body">
                <RoundImageUploadField
                    defaultImagePreview={imagePath}
                    onSave={async (blob) => {                        
                        setImagePath(blob);                        
                    }} />
                <br />
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>{translate('admin.username')}</label>
                            <input disabled className="form-control no-outline" value={userName} />
                        </div>
                        <div className="form-group">
                            <label>{translate('generic.name')}</label>
                            <input className="form-control no-outline" value={nume} onChange={(e) => { setNume(e.target.value); }} />
                        </div>
                        <div className="form-group">
                            <label>{translate('admin.forename')}</label>
                            <input className="form-control no-outline" value={prenume} onChange={(e) => { setPrenume(e.target.value); }} />
                        </div>
                        <div className="form-group">
                            <label>{translate('admin.email')}</label>
                            <input className="form-control no-outline" value={email} onChange={(e) => { setEmail(e.target.value); }} />
                        </div>
                        <div className="form-group">
                            <label>{translate('admin.phone')}</label>
                            <input className="form-control no-outline" value={telefon} onChange={(e) => { setTelefon(e.target.value); }} />
                        </div>
                        <div className="form-group">
                            <label>{translate('admin.birth_date')}</label>
                            <input type="date" className="form-control no-outline" value={dataNasterii} onChange={(e) => { setDataNasterii(e.target.value); }} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>{translate('admin.functie')}</label>
                            <input disabled className="form-control no-outline" value={functie}/>
                        </div>
                        <div className="form-group">
                            <label>{translate('admin.dept')}</label>
                            <input disabled className="form-control no-outline" value={departament} />
                        </div>
                        <div className="form-group">
                            <label>{translate('admin.loc')}</label>
                            <input disabled className="form-control no-outline" value={locatie} />
                        </div>
                        <div className="form-group">
                            <label>{translate('admin.nr_mat')}</label>
                            <input disabled className="form-control no-outline" value={nrMatricol} />
                        </div>
                    </div>
                </div>
                {!killEdit && (
                    <button type="submit" className="btn btn-primary no-outline" onClick={() => { saveEdits(); }}>{translate('generic.save')}</button>
                )}
                <button type="submit" className="btn btn-danger no-outline float-right" onClick={() => { setIsPasswordResetDialogOpen(true); }}>{translate('admin.change_password')}</button>
            </div>

            <Modal isOpen={isPasswordResetDialogOpen} toggle={() => { setIsPasswordResetDialogOpen(false);}} size='sm'>
                <ModalHeader toggle={() => { setIsPasswordResetDialogOpen(false); }}>{translate('generic.confirm_q')}</ModalHeader>
                <ModalBody>
                    <p>{translate('admin.check_pass_change')}</p>
                    <small>{translate('admin.check_pass_check')}</small>
                    <br/>
                    <div className="form-group">
                        <label>{translate('admin.current_pass')}</label>
                        <input placeholder={translate('admin.current_pass')} type="password" className="form-control no-outline" value={currentPassword} onChange={(e) => { setCurrentPassword(e.target.value); }} />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.new_pass')}</label>
                        <input placeholder={translate('admin.new_pass')} type="password" className="form-control no-outline" value={newPassword} onChange={(e) => { setNewPassword(e.target.value); }} />
                    </div>
                    <div className="form-group">
                        <label>{translate('admin.confirm_new_pass')}</label>
                        <input type="password" placeholder={translate('admin.confirm_new_pass')} className="form-control no-outline" onChange={(e) => { setNewPasswordConfirm(e.target.value); }} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="warning" className="no-outline" onClick={() => { setIsPasswordResetDialogOpen(false); }}>{translate('generic.cancel')}</Button>
                    <Button color="primary" className="no-outline" onClick={() => { onConfirm(); setIsPasswordResetDialogOpen(false); }}>{translate('generic.save')}</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}