import React, { useEffect, useState } from 'react';
import { post, get, get_ajax } from '../../utils/DataProvider';
import { LoadingCircleComponent } from '../buildingBlocks/LoadingCircleComponent';
import { LoadingIndicatorComponent } from '../buildingBlocks/LoadingIndicatorComponent';
import { MembersPicker } from '../buildingBlocks/MembersPicker';
import { RoundImageUploadField } from '../buildingBlocks/RoundImageUploadModal';
import { YesNoPicker } from '../buildingBlocks/YesNoPicker';
import { Link } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService';
import { translate } from '../../translations/translator_utils';

export const GroupsPage = () => {    
    const [createGroupView, setCreateGroupView] = useState(false);
    const [reloadMyGroupsPage, setReloadMyGroupsPage] = useState(true);

    const createNewGroupSuccessCallback = () => {
        setReloadMyGroupsPage(true);
        setCreateGroupView(false);
    }

    return (
        <div className="card">
            <div className="card-body">
                {!createGroupView && (
                    <MainGroupView
                        reloadMyGroupsPage={reloadMyGroupsPage}
                        updateCreateGroupViewState={setCreateGroupView}
                    />
                )}
                {createGroupView && (
                    <CreateGroupView
                        updateCreateGroupViewState={setCreateGroupView}
                        createNewGroupSuccessCallback={createNewGroupSuccessCallback}
                    />
                )}
            </div>
        </div>
    );
}

const MainGroupView = (props) => {
    const [currentTab, setCurrentTab] = useState("myGroups");
    return (
        <>
            {(sessionStorage.getItem("current_user_role") == "PlatformAdmin" ||
                sessionStorage.getItem("current_user_role") == "Administrator"
                || sessionStorage.getItem("current_user_role") == "GodAdmin") && (
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <button type="button" className="btn btn-primary no-outline" onClick={() => { props.updateCreateGroupViewState(true); }}>{translate('groups.create_new_group')}</button>
                </div>
                )}            
            <br />
            <ul className="nav nav-tabs">
                <li className="nav-item initialA">
                    <a className={currentTab == "myGroups" ? "nav-link active" : "nav-link"} onClick={() => { setCurrentTab("myGroups") }}>{translate('groups.my_groups')}</a>
                </li>
                <li className="nav-item initialA">
                    <a className={currentTab == "adminGroups" ? "nav-link active" : "nav-link"} onClick={() => { setCurrentTab("adminGroups") }}>{translate('groups.admin')}</a>
                </li>
                <li className="nav-item initialA">
                    <a className={currentTab == "otherGroups" ? "nav-link active" : "nav-link"} onClick={() => { setCurrentTab("otherGroups") }}>{translate('groups.other_groups')}</a>
                </li>
            </ul>
            <br />
            {currentTab == "myGroups" && (<MyGroups props={props.reloadMyGroupsPage}/>)}
            {currentTab == "adminGroups" && (<AdminGroups props={props.reloadMyGroupsPage} />)}
            {currentTab == "otherGroups" && (<OtherGroups props={props.reloadMyGroupsPage} />)}            
        </>
    );
}

const MyGroups = (props) => {
    const [loading, setLoading] = useState(true);
    const [publicGroups, setPublicGroups] = useState([]);
    const [privateGroups, setPrivateGroups] = useState([]);

    useEffect(() => { loadGroups(); }, [props.reloadMyGroupsPage]);

    const loadGroups = async () => {
        await get_ajax('Social/Groups/GetMyGroups', (response) => {            
            setPublicGroups(response.filter(function (group) {
                return group.groupIsPrivate == false;
            }));
            setPrivateGroups(response.filter(function (group) {
                return group.groupIsPrivate == true;
            }));
            setLoading(false);
        });
    };

    return (
        loading ? <LoadingCircleComponent /> :
        <>
                <h5 style={{ color: '#732E81' }}>{translate('groups.public_groups')}</h5>
            <br />
            <div className="row">
                {publicGroups.map((group, index) =>
                    <GroupListIcon group={group} key={index} />
                )}
            </div>
            <br/>

                <h5 style={{ color: '#732E81' }}>{translate('groups.private_groups')}</h5>
            <br />
            <div className="row">
                {privateGroups.map((group, index) =>
                    <GroupListIcon group={group} key={index}/>
                )}
            </div>
            <br />
        </>
    );
}

const AdminGroups = (props) => {
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    useEffect(() => { loadGroups(); }, [props.reloadMyGroupsPage]);
    const loadGroups = async () => {
        await get_ajax('Social/Groups/GetMyGroupsAdminRights', (response) => {
            setGroups(response);
            setLoading(false);
        });        
    };
    return (
        loading ? <LoadingCircleComponent /> :
        <>
            <br />
            <div className="row">
                {groups.map((group, index) =>
                    <GroupListIcon group={group} key={index} />
                )}
            </div>
            <br />
        </>
    );
}

const OtherGroups = (props) => {
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [privateGroups, setPrivateGroups] = useState([]);
    useEffect(() => { loadGroups(); }, [props.reloadMyGroupsPage]);
    const loadGroups = async () => {
        await get_ajax('Social/Groups/GetOtherGroups', (response) => {
            setGroups(response);
            setLoading(false);
        });

        await get_ajax('Social/Groups/GetOtherGroupsPrivate', (response) => {
            setPrivateGroups(response);
            setLoading(false);
        });
    };
    return (
        loading ? <LoadingCircleComponent /> :
            <>
                <h5 style={{ color: '#732E81' }}>{translate('groups.public_groups')}</h5>
                <br />
                <div className="row">
                    {groups.map((group, index) =>
                        <GroupListIcon group={group} key={index} />
                    )}
                </div>
                <br />
                {
                    ((sessionStorage.getItem("current_user_role") == "GodAdmin" || sessionStorage.getItem("current_user_role") == "PlatformAdmin") && (
                        <>
                            <h5 style={{ color: '#732E81' }}>{translate('groups.private_groups')}</h5>
                            <br />
                            <div className="row">
                                {privateGroups.map((group, index) =>
                                    <GroupListIcon group={group} key={index} />
                                )}
                            </div>
                            <br />
                        </>
                    ))
                }
            </>
    );
}


const GroupListIcon = (props) => {
    return (
        <div className="col-6 col-md-3">
            <div className="groupListIcon">
                <Link to={"/feed/" + props.group.id} className="initialA">
                    <img style={{ height: '40px', borderRadius: '50%', border: '2px solid #3D9294', marginRight: '10px' }} src={props.group.groupImage} className="float-left" />
                    <div>
                        <p style={{ marginBottom: '0' }} className="h6">{props.group.groupName}</p>
                        <small className="text-muted">{props.group.membersCount} {props.group.membersCount == 1 ? translate('generic.user') : translate('generic.users')}</small>
                    </div>
                </Link>
            </div>
        </div>
    )
}


const CreateGroupView = (props) => {
    const [image, setImage] = useState("/images/default_group_image.png");
    const [imageData, setImageData] = useState(null);
    const [privateGroup, setPrivateGroup] = useState(false);
    const [anyoneCanPost, setAnyoneCanPost] = useState(true);
    const [anyoneCanComment, setAnyoneCanComment] = useState(true);
    const [groupName, setGroupName] = useState('');
    const [groupDesc, setGroupDesc] = useState('');

    const [administratorsUsers, setAdministratorsUsers] = useState([sessionStorage.getItem("current_user_id")]);
    const [administratorsGroups, setAdministratorsGroups] = useState([]);
    const [administratorsFunctii, setAdministratorsFunctii] = useState([]);
    const [administratorsDepartamente, setAdministratorsDepartamente] = useState([]);
    const [administratorsLocatii, setAdministratorsLocatii] = useState([]);

    const [membersUsers, setmembersUsers] = useState([]);
    const [membersGroups, setmembersGroups] = useState([]);
    const [membersFunctii, setmembersFunctii] = useState([]);
    const [membersDepartamente, setmembersDepartamente] = useState([]);
    const [membersLocatii, setmembersLocatii] = useState([]);
    const [membersAllUsers, setMembersAllUsers] = useState(true);

    const createGroup = async () => {                
        var data = new FormData();
        data.encoding = "multipart/form-data";
        data.append("base64GroupImg", image)
        data.append('groupName', groupName);
        data.append('groupDesc', groupDesc);
        data.append('isPrivate', privateGroup);
        data.append('administrators_json', JSON.stringify({
            "Users": administratorsUsers,
            "Groups": administratorsGroups,
            "Functii": administratorsFunctii,
            "Departamente": administratorsDepartamente,
            "Locatii": administratorsLocatii,
            "AllowAll": false
        }));
        data.append('members_json', JSON.stringify({
            "Users": membersUsers,
            "Groups": membersGroups,
            "Functii": membersFunctii,
            "Departamente": membersDepartamente,
            "Locatii": membersLocatii,
            "AllowAll": membersAllUsers
        }));
        data.append('canAnyonePost', anyoneCanPost);
        data.append('canAnyoneComment', anyoneCanComment);

        var request = new XMLHttpRequest();
        request.upload.addEventListener('progress', function (e) {
            if (e.total === e.loaded) {
                props.createNewGroupSuccessCallback();
                return;
            }
        }.bind(this), false);
        request.open('POST', 'Social/Groups/CreateNewGroup');
        const token = await authService.getAccessToken();
        request.setRequestHeader('Authorization', `Bearer ${token}`);
        request.send(data);
    }

    return (
        <>
            <RoundImageUploadField
                defaultImagePreview={image}
                onSave={(blob) => {
                    setImage(blob);                    
                }} />
            <br />
            <div className="row">
                <div className="col-md-8">
                    <div className="form-group">
                        <label>{translate('generic.name')}</label>
                        <input value={groupName} onChange={(e) => { setGroupName(e.target.value); }} className="form-control no-outline" placeholder={translate('generic.name')}></input>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>{translate('generic.private')}</label>
                        <YesNoPicker defaultValue={privateGroup} valueUpdated={(value) => { setPrivateGroup(value); }} />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="form-group">
                    <label>{translate('generic.description')}</label>
                    <textarea value={groupDesc} onChange={(e) => { setGroupDesc(e.target.value); }} className="form-control no-outline" placeholder={translate('generic.description')} rows={2}></textarea>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>{translate('groups.administrators')}</label>
                        <MembersPicker
                            defaultSelectedUsers={administratorsUsers}
                            defaultSelectedGroups={administratorsGroups}
                            defaultSelectedFunctii={administratorsFunctii}
                            defaultSelectedDepartamente={administratorsDepartamente}
                            defaultSelectedLocatii={administratorsLocatii}
                            saveCallback={(selectedUsers, selectedGroupsRules, selectedFunctiiRules, selectedDepartamenteRules, selectedLocatiiRules, allUsers) => {
                                setAdministratorsUsers(selectedUsers);
                                setAdministratorsGroups(selectedGroupsRules)
                                setAdministratorsFunctii(selectedFunctiiRules);
                                setAdministratorsDepartamente(selectedDepartamenteRules);
                                setAdministratorsLocatii(selectedLocatiiRules);                                
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('generic.posts_mode')}</label>
                        <YesNoPicker defaultValue={anyoneCanPost} valueUpdated={(value) => { setAnyoneCanPost(value) }} customLabel1={translate('generic.all_members')} customLabel2={translate('groups.only_admins')} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>{translate('generic.select_users')}</label>
                        <MembersPicker
                            defaultSelectedUsers={membersUsers}
                            defaultSelectedGroups={membersGroups}
                            defaultSelectedFunctii={membersFunctii}
                            defaultSelectedDepartamente={membersDepartamente}
                            defaultSelectedLocatii={membersLocatii}
                            saveCallback={(selectedUsers, selectedGroupsRules, selectedFunctiiRules, selectedDepartamenteRules, selectedLocatiiRules, allUsers) => {
                                setmembersUsers(selectedUsers);
                                setmembersGroups(selectedGroupsRules)
                                setmembersFunctii(selectedFunctiiRules);
                                setmembersDepartamente(selectedDepartamenteRules);
                                setmembersLocatii(selectedLocatiiRules);
                                setMembersAllUsers(allUsers);
                            }}
                            allowAll={true}
                            membersAllUsers={membersAllUsers}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('generic.comments_mode')}</label>
                        <YesNoPicker defaultValue={anyoneCanComment} valueUpdated={(value) => { setAnyoneCanComment(value) }} customLabel1={translate('generic.all_members')} customLabel2={translate('groups.only_admins')} />
                    </div>
                </div>
            </div>

            <button type="submit" className="btn btn-primary no-outline" onClick={createGroup}>{translate('generic.save')}</button>
            <button type="submit" className="btn btn-link no-outline" onClick={() => { props.updateCreateGroupViewState(false); }}>{translate('generic.cancel')}</button>
        </>
    )
}
