import React, { useEffect, useState } from 'react';
import { translate } from '../../translations/translator_utils';

export const LoadingIndicatorComponent = () => {

    const [displayPleaseRefreshMessage, setDisplayRefreshMessage] = useState(false);

    useEffect(() => {
        var x1 = setTimeout(() => { setDisplayRefreshMessage(true); }, 2.5 * 1000);
        var x2 = setTimeout(() => { window.location.reload(); }, 5 * 1000);

        return () => {
            clearTimeout(x1);
            clearTimeout(x2);
        }
    }, []);

    return (
        <div style={{ textAlign: 'center', width: '100%' }}>
            <div className="dl">
                <img className="dl__square" src="/images/joyhub_auth_image.png" style={{ cursor: 'pointer' }} onClick={() => { window.location.reload(); }} />
            </div>
            {displayPleaseRefreshMessage && (
                <p>{translate('generic.request_reload')}</p>
            )}
        </div>
    );
}