import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './styles.scss';
import { get, get_ajax } from './utils/DataProvider';
import { GroupsPage } from './components/pages/Groups';
import { Home } from './components/pages/Home';
import { AdminDashboard } from './components/pages/adminUI/AdminDashboard';
import { GroupDetails } from './components/pages/GroupDetails';
import { PostDetails } from './components/pages/PostDetails';
import { Profile } from './components/pages/Profile';
import { ProfileMyPostsMobileView } from './components/pages/ProfileMyPosts';
import { ProfileEditor } from './components/pages/ProfileEditor';
import { Notifications } from './components/pages/Notifications';
import { Archive } from './components/pages/archive/Archive';
import { Chat } from './components/pages/chat/Chat';

export default class App extends Component {
  static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = { loadedClientConfig: false };
    }

    componentDidMount() {
        this.getCurrentClientConfig();
    }

    async getCurrentClientConfig() {       
        const data = await get('api/ClientUI');

        //TODO It might be 3 after adding the true domain
        var splits = window.location.host.split('.');

        var target_segments = 2;
        if (window.location.hostname.includes("localhost"))
            target_segments = 2;
        else if (window.location.hostname.includes("azurewebsites"))
            target_segments = 4;
        else
            target_segments = 3;

        // if (splits.length == target_segments) {
        //     var subdomain = splits[1] ? splits[0] : false;
        //     if (subdomain != false)
        //         if (data.host != subdomain) {
        //             var newURL = window.location.hostname.replace(subdomain, data.host);
        //             if (newURL[0] == '.')
        //                 newURL = newURL.slice(1);
        //             alert(newURL);
        //             window.location.href = newURL;
        //         }
        // }

        sessionStorage.setItem("client_host", data.host);

        let theme = data.host;
        if (theme == "" || theme == undefined || theme == null)
            theme = "default";
        document.getElementById("___body___").className = theme;

        sessionStorage.setItem("client_name", data.clientName);
        sessionStorage.setItem("client_navbar_logo", data.clientNavbarLogo);
        sessionStorage.setItem("current_user_id", data.userId);
        sessionStorage.setItem("current_user_role", data.role);
        sessionStorage.setItem("avatar", data.avatar);
        sessionStorage.setItem("lang", data.lang);
        try {
            sessionStorage.setItem("lang_srv", data.lang.toLowerCase());
        }
        catch (e) {}

        this.setState({ loadedClientConfig: true });
    }

    render() {
        if (this.state.loadedClientConfig)
            return (
                <div>
                    <Layout loadedClientConfig={this.state.loadedClientConfig}>
                        <AuthorizeRoute exact path='/' component={Home} />
                        <AuthorizeRoute exact path='/feed/:groupId' component={Home} />
                        <AuthorizeRoute exact path='/groupDetails/:groupId' component={GroupDetails} />
                        <AuthorizeRoute exact path='/postDetails/:postId' component={PostDetails} />
                        <AuthorizeRoute exact path='/profile/:profileId' component={Profile} />
                        <AuthorizeRoute exact path='/profileMyPostsMobile/:profileId' component={ProfileMyPostsMobileView} />
                        <AuthorizeRoute exact path='/editProfile' component={ProfileEditor} />

                        <AuthorizeRoute path='/groups' component={GroupsPage} />
                        <AuthorizeRoute path='/counter' component={Counter} />
                        <AuthorizeRoute path='/fetch-data' component={FetchData} />
                        <AuthorizeRoute path='/notif' component={Notifications} />

                        <AuthorizeRoute path='/admin' component={AdminDashboard} />

                        <AuthorizeRoute path='/archive/:directoryPath' component={Archive} />
                        <AuthorizeRoute path='/archive_files/:filePath' component={Archive} />

                        <AuthorizeRoute path='/chat/:convId' component={Chat}/>

                        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                    </Layout>                    
                </div>
            );
        else
            return (
                <>
                    <Layout loadedClientConfig={this.state.loadedClientConfig}>
                        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                    </Layout>
                </>
            );
    }
}
