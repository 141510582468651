import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from '../../translations/translator_utils';
import { get_ajax } from '../../utils/DataProvider';
import { LoadingCircleComponent } from './LoadingCircleComponent';

//Ar trbui sa vezi daca useri selectati manuali sunt in grupuri si sa nu ii mai pui la total, dar mna....

export const MembersPicker = (props) => {
    const [modalVisible, setModalVisible] = useState(false);

    const [currentModalTab, setCurrentModalTab] = useState('users');

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedUsersTarget, setSelectedUsersTarget] = useState(0);

    const [selectedGroupsRules, setSelectedGroupsRules] = useState([]);
    const [selectedGroupsTarget, setSelectedGroupsTarget] = useState(0);

    const [selectedFunctiiRules, setSelectedFunctiiRules] = useState([]);
    const [selectedFunctiiTarget, setSelectedFunctiiTarget] = useState(0);

    const [selectedDepartamenteRules, setSelectedDepartamenteRules] = useState([]);
    const [selectedDepartamenteTarget, setSelectedDepartamenteTarget] = useState(0);

    const [selectedLocatiiRules, setSelectedLocatiiRules] = useState([]);
    const [selectedLocatiiTarget, setSelectedLocatiiTarget] = useState(0);

    const [allUsers, setAllUsers] = useState(props.membersAllUsers);
    const [prevAllUsers, setPrevAllUsers] = useState(props.membersAllUsers);

    useEffect(() => {
        loadDefaultValues();
    }, []);    

    let fRun = true;
    useEffect(() => {
        if(!fRun)
            props.saveCallback(selectedUsers, selectedGroupsRules, selectedFunctiiRules, selectedDepartamenteRules, selectedLocatiiRules, allUsers);
        fRun = false;
    }, [allUsers]);

    const loadDefaultValues = () => {
        if (props.defaultSelectedUsers) { setSelectedUsers(props.defaultSelectedUsers); }
        else { setSelectedUsers([]); }

        if (props.defaultSelectedGroups) { setSelectedGroupsRules(props.defaultSelectedGroups); }
        else { setSelectedGroupsRules([]); }

        if (props.defaultSelectedFunctii) { setSelectedFunctiiRules(props.defaultSelectedFunctii); }
        else { setSelectedFunctiiRules([]); }

        if (props.defaultSelectedDepartamente) { setSelectedDepartamenteRules(props.defaultSelectedDepartamente); }
        else { setSelectedDepartamenteRules([]); }

        if (props.defaultSelectedLocatii) { setSelectedLocatiiRules(props.defaultSelectedLocatii); }
        else { setSelectedLocatiiRules([]); }
    }    

    const getSelectedUsers = (resource) => {
        switch (resource) {
            case "users":
                return selectedUsersTarget;
            case "groups":
                return selectedGroupsTarget;
            case "funct":
                return selectedFunctiiTarget;
            case "departs":
                return selectedDepartamenteTarget;
            case "locatii":
                return selectedLocatiiTarget;
            default: return 0;
        }
    }

    return (
        <>
            {props.allowAll != true && (
                <button onClick={() => { setModalVisible(true); }} className="btn btn-secondary no-outline btn-block">{translate('generic.select_users')}</button>
            )}
            {props.allowAll == true && (
                <div className="btn-group d-flex">
                    <button type="button" className={allUsers ? "btn btn-primary no-outline" : "btn btn-secondary no-outline"} onClick={() => { setAllUsers(true); }}>
                        {translate('generic.all_members')}
                    </button>
                    <button type="button" className={allUsers == false ? "btn btn-primary no-outline" : "btn btn-secondary no-outline"} onClick={() => { setPrevAllUsers(allUsers); setAllUsers(false); setModalVisible(true); }}>
                        {translate('generic.some_members')}
                    </button>
                </div>
            )}

            <Modal isOpen={modalVisible} toggle={() => { setModalVisible(false); }} size={'lg'}>
                <ModalHeader toggle={() => { setModalVisible(false); }}>{ translate('generic.select')}</ModalHeader>
                <ModalBody>
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a className={currentModalTab == "users" ? "nav-link active" : "nav-link"} onClick={() => { setCurrentModalTab("users") }}>{translate('generic.users')} <span className="badge badge-secondary">{getSelectedUsers("users")}</span></a>
                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <a className={currentModalTab == "groups" ? "nav-link active" : "nav-link"} onClick={() => { setCurrentModalTab("groups") }}>Grupuri <span className="badge badge-secondary">{getSelectedUsers("groups")}</span></a>*/}
                        {/*</li>*/}
                        {!props.onlyUsers && (
                            <>
                                <li className="nav-item">
                                    <a className={currentModalTab == "funct" ? "nav-link active" : "nav-link"} onClick={() => { setCurrentModalTab("funct") }}>{translate('generic.functions')} <span className="badge badge-secondary">{getSelectedUsers("funct")}</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className={currentModalTab == "departs" ? "nav-link active" : "nav-link"} onClick={() => { setCurrentModalTab("departs") }}>{translate('generic.departs')} <span className="badge badge-secondary">{getSelectedUsers("departs")}</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className={currentModalTab == "locatii" ? "nav-link active" : "nav-link"} onClick={() => { setCurrentModalTab("locatii") }}>{translate('generic.locations')} <span className="badge badge-secondary">{getSelectedUsers("locatii")}</span></a>
                                </li>
                            </>
                        )}
                    </ul>
                    <RuleResourcePage
                        resource={currentModalTab}
                        selectedResourcesIds={currentModalTab == "users" ? selectedUsers :
                            currentModalTab == "groups" ? selectedGroupsRules :
                                currentModalTab == "funct" ? selectedFunctiiRules :
                                    currentModalTab == "departs" ? selectedDepartamenteRules :
                                        selectedLocatiiRules}
                        updateSelectedResourcesIds={currentModalTab == "users" ? setSelectedUsers :
                            currentModalTab == "groups" ? setSelectedGroupsRules :
                                currentModalTab == "funct" ? setSelectedFunctiiRules :
                                    currentModalTab == "departs" ? setSelectedDepartamenteRules :
                                        setSelectedLocatiiRules}
                    />
                    <br />
                    <button className="btn btn-warning float-left no-outline" onClick={loadDefaultValues}>{translate('generic.reset')}</button>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        props.saveCallback(selectedUsers, selectedGroupsRules, selectedFunctiiRules, selectedDepartamenteRules, selectedLocatiiRules, allUsers);
                        setModalVisible(false);
                    }}>{translate('generic.save')}</Button>
                    <Button color="warning" onClick={() => { loadDefaultValues(); setModalVisible(false); setAllUsers(prevAllUsers); }}>{translate('generic.cancel')}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

const RuleResourcePage = ({ resource, selectedResourcesIds, updateSelectedResourcesIds }) => {

    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [objects, setObjects] = useState([]);

    useEffect(() => {
        setLoading(true);
        get_ajax("Social/Utils/GetAvaliableResource?resourceType=" + resource + "&search=" + searchValue, (response) => {
            setObjects(response);
            setLoading(false);
        });
    }, [resource, searchValue]);

    return (        
            <>
                <br />
                <input value={searchValue} onChange={(e) => { setSearchValue(e.target.value); }} className="form-control no-outline" placeholder={translate('generic.search')} />
                <br/>
            {
                loading ? <LoadingCircleComponent /> :
                    <ul className="list-group">
                        {objects.map((o) =>
                            <li className="list-group-item" key={o.id}>
                                <input type="checkbox" className="float-left resourceDialogCheckBox"
                                    checked={selectedResourcesIds.includes(o.id)}
                                    onChange={(e) => {
                                        const cpy = [...selectedResourcesIds];
                                        if (e.target.checked)
                                            cpy.push(o.id);
                                        else
                                            cpy.splice(cpy.indexOf(o.id), 1);
                                        updateSelectedResourcesIds(cpy);
                                    }}
                                />
                                <div style={{ marginLeft: '20px' }}>
                                    <p style={{ marginBottom: 0 }}>{o.displayName}</p>
                                    {resource != "users" && (
                                        <small className="text-muted float-right" style={{ marginTop: '-20px' }}>{o.targetCount} {o.targetCount == 1 ? translate('generic.member') : translate('generic.members')}</small>
                                    )}
                                </div>
                            </li>
                        )}
                    </ul>
            }
            </>
    );
}