import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { get_ajax, post, post_dn } from '../../utils/DataProvider';
import authService from '../api-authorization/AuthorizeService';
import { LoadingCircleComponent } from '../buildingBlocks/LoadingCircleComponent';
import { SquareImageUploadField } from '../buildingBlocks/SquareImageUploadModal';
import { ButtonDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Collapse, Button, CardBody, Card, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import $ from 'jquery';
import { RiTranslate, RiLockUnlockLine, RiLockLine, RiDeleteBin5Line, RiCameraFill, RiEdit2Line, RiStarSFill, RiStarSLine, RiLinksFill, RiAttachmentLine, RiImageLine, RiLiveLine } from 'react-icons/ri';
import { ImFilesEmpty, ImFileZip, ImFilePdf, ImFileMusic, ImFileText2, ImFileExcel, ImFileWord } from 'react-icons/im';
import { BsArrowRepeat, BsArchive, BsArrowReturnRight } from "react-icons/bs";
import { AiOutlineFilePpt, AiOutlineComment, AiOutlineLike, AiOutlineFolderOpen } from 'react-icons/ai'
import { BiMessageRoundedDots } from 'react-icons/bi';
import { VscPinned } from 'react-icons/vsc';
import { GrAttachment, GrCircleQuestion } from "react-icons/gr";
import OverflowText from '../buildingBlocks/OverflowText';
import axios from 'axios';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { FacebookSelector } from '@charkour/react-reactions';
import { useInView } from 'react-intersection-observer';
import { UserBadge } from './Profile';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { translate } from '../../translations/translator_utils';

const downloadAs = (url, name) => {
    axios.get(url, {
        headers: {
            "Content-Type": "application/octet-stream",
            "Access-Control-Allow-Origin": "*"
        },
        responseType: "blob"
    })
        .then(response => {
            const a = document.createElement("a");
            const url = window.URL.createObjectURL(response.data);
            a.href = url;
            a.download = name;
            a.click();
        })
        .catch(err => {
            console.log("error", err);
        });
};
const getFileIcon = (fName) => {
    if (fName == null)
        fName = "";
    fName = fName.toLowerCase();
    if (fName.includes(".mp3"))
        return (<ImFileMusic />);
    if (fName.includes(".zip"))
        return (<ImFileZip />);
    if (fName.includes(".txt"))
        return (<ImFileText2 />);
    if (fName.includes(".pdf"))
        return (<ImFilePdf />);
    if (fName.includes(".doc") || fName.includes(".docx"))
        return (<ImFileWord />);
    if (fName.includes(".xls") || fName.includes(".xlsx"))
        return (<ImFileExcel />);
    if (fName.includes(".ppt") || fName.includes(".pptx"))
        return (<AiOutlineFilePpt />);
    return (<ImFilesEmpty />);
}

export const Home = (props) => {
    const [groupId, setGroupId] = useState(null);
    useEffect(() => {
        if (props.match.params.groupId != undefined) {
            setGroupId(props.match.params.groupId);            
        }
    }, []);

    if (window.innerWidth < 900)
        return (<MobileView/>);
    else
        return (
            <div className="row">
                <div className="col-md-3">
                    <SideMenu />
                </div>
                <div className="col-md-9">
                    <HomeFeed />
                </div>
            </div>
        );
}

export const MobileView = (props) => {
    return (
        <SwipeableViews enableMouseEvents index={1}>
            <SideMenu />
            <HomeFeed/>
        </SwipeableViews>
    );
}

export const SideMenu = () => {
    const [currentTab, setCurrentTab] = useState('public');
    const [loading, setLoading] = useState(true);
    const [featuredDirectories, setFeaturedDirectories] = useState([]);
    const [publicGroups, setPublicGroups] = useState([]);
    const [privateGroups, setPrivateGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const [featuredPromoURL, setFeaturedPromoURL] = useState('');

    useEffect(() => {
        loadGroups();
        loadFeaturedDirectories();
        get_ajax('Social/Utils/GetFeatuerdPromo', (src) => {
            setFeaturedPromoURL(src + "?" + new Date().getTime());
        });
    }, []);
    useEffect(() => {
        if (currentTab == "public")
            setSelectedGroups(publicGroups);
        else
            setSelectedGroups(privateGroups);
    }, [publicGroups, privateGroups]);
    useEffect(() => {        
        if (currentTab == "public")
            setSelectedGroups(publicGroups);
        else
            setSelectedGroups(privateGroups);
    }, [currentTab]);

    const loadGroups = async () => {
        await get_ajax('Social/Groups/GetMyGroups', (response) => {            
            setPublicGroups(response.filter(function (group) {
                return group.groupIsPrivate == false;
            }));
            setPrivateGroups(response.filter(function (group) {
                return group.groupIsPrivate == true;
            }));
            setLoading(false);
        });
    };

    const loadFeaturedDirectories = async () => {
        await get_ajax('Archive/Home/GetFeaturedDirectories', (response) => {
            setFeaturedDirectories(response);
        });
    }

    return (
        loading ? <LoadingCircleComponent/> :
            <div className="sideMenu">
                <UserBadge userId={sessionStorage.getItem("current_user_id")} />
            <div className="card card2 shadow" style={{ marginBottom: '10px' }}>
                <div className="card-body">
                    <h5 className="card-title link-ct">{translate('home.groups.title')}</h5>
                    <ul className="nav nav-tabs nav-fill">
                        <li className="nav-item initialA">
                            <a className={currentTab == "public" ? "nav-link active" : "nav-link"} onClick={() => { setCurrentTab("public") }}>{translate('home.groups.public')}</a>
                        </li>
                            <li className="nav-item initialA">
                                <a className={currentTab == "private" ? "nav-link active" : "nav-link"} onClick={() => { setCurrentTab("private") }}>{translate('home.groups.private')}</a>
                        </li>
                        </ul>                        
                        <ul className="list-group listScroll">
                            {selectedGroups.map((group, index) =>
                                <Link to={"/feed/" + group.id} key={index} className="initialA">
                                    <li className="list-group-item list-group-item-action initialA" style={{ cursor: 'pointer' }}>
                                        <img style={{ height: '25px', borderRadius: '50%', border: '2px solid #7f8c8d', marginRight: '10px' }} src={group.groupImage} className="float-left" />
                                        <label className="h6">{group.groupName}</label>                                    
                                    </li>
                                </Link>
                            )}                                                
                        </ul>
                    <br/>
                        {/*<h5 className="card-title link-ct">{translate('home.archive.title')}</h5>*/}
                        {/*<ul className="list-group listScroll">*/}
                        {/*    {featuredDirectories.map((directory, index) =>*/}
                        {/*        <Link to={"/archive/" + directory.id} key={index} className="initialA">*/}
                        {/*            <li className="list-group-item list-group-item-action initialA" style={{ cursor: 'pointer' }}>*/}
                        {/*                <AiOutlineFolderOpen className="react-icon"/>*/}
                        {/*                <label className="h6">{directory.name}</label>*/}
                        {/*            </li>*/}
                        {/*        </Link>*/}
                        {/*    )}                            */}
                        {/*</ul>*/}
                        {/*<Link*/}
                        {/*    className="btn btn-link nodeco"*/}
                        {/*    style={{ textAlign: 'left' }}*/}
                        {/*    to={"/archive/root"}*/}
                        {/*>*/}
                        {/*    <BsArchive style={{ marginRight: '10px' }} />*/}
                        {/*    {translate('home.archive.open_archive')}*/}
                        {/*</Link>*/}
                </div>
            </div>        
            <div className="card card3 text-center shadow">
                    <div className="card-body" style={{ margin: '0', padding: '20px' }}>
                        {(sessionStorage.getItem("current_user_role") == "PlatformAdmin" ||
                        sessionStorage.getItem("current_user_role") == "Administrator"
                        || sessionStorage.getItem("current_user_role") == "GodAdmin") && (
                            <SquareImageUploadField
                                defaultImagePreview={featuredPromoURL}
                                onSave={async (image) => {
                                    var data = new FormData();
                                    data.encoding = "multipart/form-data";
                                    data.append("image", image)

                                    var request = new XMLHttpRequest();
                                    request.open('POST', 'Social/Utils/UploadFeaturedPromo');
                                    const token = await authService.getAccessToken();
                                    request.setRequestHeader('Authorization', `Bearer ${token}`);
                                    request.send(data);
                                }} />
                        )}
                        {!(sessionStorage.getItem("current_user_role") == "PlatformAdmin" || 
                        sessionStorage.getItem("current_user_role") == "Administrator" || sessionStorage.getItem("current_user_role") == "GodAdmin") && (
                            <img src={featuredPromoURL} style={{ width: '100%', borderRadius: '30px' }} />
                        )}
                </div>                
            </div>
            <br/>
        </div>
    );
}

let autoRefreshVAR = true;
export const HomeFeed = (props) => {
    const [groupId, setGroupId] = useState(null);
    const [groupName, setGroupName] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingGroupPosts, setLoadingGroupPosts] = useState(true);

    const [posts, setPosts] = useState([]);

    const [signalRConnectionStatus, setSignalRConnectionStatus] = useState('');

    useEffect(() => {
        if (window.location.href.includes('/feed')) {
            const newGroup = window.location.href.split('/feed/')[1];
            setCurrentPage(0);
            setGroupId(newGroup);
        }
    }, [window.location.href]);

    const [connection, setConnection] = useState(null);
    useEffect(() => {
        loadGroup();
        if (groupId != null && connection == null)
            setup_connection();
    }, [groupId, props]);
    
    useEffect(() => {
        if (groupId != null)
            setup_connection();
    }, []);
    const setup_connection = () => {
        const newConnection = new HubConnectionBuilder()
            .withUrl('/hubs/feedhub')
            .withAutomaticReconnect()
            .build();
        setConnection(newConnection);
    }
    
    const [isAutoRefreshing, setIsAutoRefresing] = useState(true);
    const [autoRefreshOffNewData, setAutoRefreshOffNewData] = useState(false);

    useEffect(() => {
        autoRefreshVAR = isAutoRefreshing;
    }, [isAutoRefreshing]);

    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(async (result) => {
                    console.log('Connected!');

                    connection.on('FEED_UPDATE', data => {                        
                        if (!autoRefreshVAR) {
                            setAutoRefreshOffNewData(true);
                            return;
                        }                        
                        setCurrentPage(0);
                        loadGroupPosts(false, 0, true);
                    });
                    connection.on('FEED_UPDATE_X', data => {
                        if (!autoRefreshVAR) {
                            setAutoRefreshOffNewData(true);
                            return;
                        }
                        window.location.reload();
                    });
                    connection.on('UPDATE_NOTIFS', () => {
                        sessionStorage.setItem("NOTIFS_UPD", uuidv4());
                    });

                    connection.on('connectionSlow', () => {
                        setSignalRConnectionStatus("slow");
                    });
                    connection.on('reconnecting', () => {
                        setSignalRConnectionStatus("reconnecting");
                    });
                    connection.on('reconnected', () => {
                        setSignalRConnectionStatus("reconnected");
                        setTimeout(() => {
                            setSignalRConnectionStatus("");
                        });
                    });
                    connection.on('disconnected', () => {
                        if (signalRConnectionStatus != "reconnecting")
                            connection.start();
                    });

                    await connection.invoke('AssignToGroup', groupId, sessionStorage.getItem("current_user_id"))
                })
                .catch(e => console.log('Connection failed: ', e));

            connection.on('connectionSlow', () => {
                setSignalRConnectionStatus("slow");
            });
            connection.on('reconnecting',() => {
                setSignalRConnectionStatus("reconnecting");
            });
            connection.on('reconnected', () => {
                setSignalRConnectionStatus("reconnected");
                setTimeout(() => {
                    setSignalRConnectionStatus("");
                });
            });
            connection.on('disconnected', () => {
                if (signalRConnectionStatus != "reconnecting")
                    connection.start();
            });
        }
    }, [connection]);

    const loadGroup = () => {
        if (window.location.href.includes("/feed") && groupId == null) return;                
        get_ajax('Social/Groups/GetGroupDefaultOrById?id=' + groupId, (response) => {
            if(response === undefined) return;
            if (groupId == null) {
                setGroupId(response.id);
                sessionStorage.setItem("mainGroupId", response.id);                                
            }

            if (response.anyoneCanPost === true && response.isMember)
                sessionStorage.setItem("canPost", true);            
            else if (response.anyoneCanPost === false && response.isAdmin)
                sessionStorage.setItem("canPost", true);
            else
                sessionStorage.setItem("canPost", false);

            if (response.anyoneCanComment === true && response.isMember)
                sessionStorage.setItem("canComment", true);
            else if (response.anyoneCanComment === false && response.isAdmin)
                sessionStorage.setItem("canComment", true);
            else
                sessionStorage.setItem("canComment", false);


            sessionStorage.setItem("groupRole", response.isAdmin);

            setGroupName(response.groupName);                        
            loadGroupPosts();

            setLoading(false);
        });
    }

    const loadGroupPosts = (append, cp, allupto) => {        
        get_ajax('Social/Feed/GetPosts?groupId=' + groupId + "&currentPage=" + cp + "&filterByUser=" + props.filterByUser + "&allupto=" + allupto, (response) => {
            if (append == true) {
                const cpy = [...posts];                
                for (var i = 0; i < response.length; i++)
                    cpy.push(response[i]);                
                setPosts(cpy);
            }
            else {                
                setPosts(response);                
            }            
            setLoadingGroupPosts(false);
        });
    }

    const [currentPage, setCurrentPage] = useState(0);
    const [ref, inView] = useInView();
    useEffect(() => {
        if (!loadingGroupPosts && inView == true) {
            setIsAutoRefresing(false);
            loadGroupPosts(true, currentPage + 1);
            setCurrentPage(currentPage + 1);            
        }
    }, [inView]);

    return (
        loading ? <LoadingCircleComponent/> :
           <div>
                <div className="groupHeader shadow">
                    <h4>{groupName}</h4>
                    <Link to={'/groupDetails/' + groupId}>
                        <button className="btn btn-outline-light no-outline float-right">{ translate('home.groups.details')}</button>
                    </Link>
                </div>
                <br />

                {(!isAutoRefreshing && autoRefreshOffNewData) && (
                    <>
                        <div className="groupHeader shadow" style={{ backgroundColor: '#3D9294', textAlign: 'center', cursor: 'pointer' }} onClick={() => { window.location.reload(); }}>
                            <p>Actualizeaza feed-ul</p>
                        </div>
                        <br />
                    </>
                )}

                {signalRConnectionStatus == 'slow' && (
                    <div className="groupHeader shadow">
                        <p>Conexiunea este lenta</p>
                    </div>
                )}
                {signalRConnectionStatus == 'reconnecting' && (
                    <div className="groupHeader shadow">
                        <p>In curs de reconectare</p>
                    </div>
                )}
                {signalRConnectionStatus == 'reconnecting' && (
                    <div className="groupHeader shadow">
                        <p>Reconectat</p>
                    </div>
                )}

                {props.blockPosts != true && (
                    <>
                        {sessionStorage.getItem("canPost") == 'true' && (<NewPostBuilder currentGroupId={groupId} reloadPosts={() => { }} />)}
                        < br />
                    </>
                )}

                {loadingGroupPosts && (<LoadingCircleComponent />)}
                {posts.map((post, index) =>
                    <>
                    <PostBox
                      setPost={(newData) => {
                            const newP = [...posts];
                            for (var i = 0; i < newP.length; i++)
                                if(newP[i].id === post.id)
                                    newP[i] = newData;
                            setPosts(newP);
                            get_ajax('Social/Feed/GetPost?postId=' + post.id, (response) => {
                              const newP = [...posts];
                              for (var i = 0; i < newP.length; i++)
                                if (newP[i].id === post.id)
                                  newP[i] = response[0];
                              setPosts(newP);
                            });
                      }}
                      key={post.id}
                      post={post}
                      reloadPosts={() => { }}
                      setAutoRefreshOffNewData={setAutoRefreshOffNewData}
                      setIsAutoRefresing={setIsAutoRefresing}
                    />
                        {index === posts.length - 3 && (
                            <div ref={ref}></div>
                        )}
                    </>
                )}                
            </div>
    )
}

const NewPostBuilder = ({ currentGroupId, reloadPosts }) => {
    const [uploadFileProgress, setUploadFileProgress] = useState(0);
    const [uploadFileProgressTotal, setUploadFileProgressTotal] = useState(0);

    const [linkAttachmentValue, setLinkAttachmentValue] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [allowMultipleFilesUpload, setAllowMultipleFilesUpload] = useState(false);

    const [dropdownOpenSelectAttachType, setDropdownOpenSelectAttachType] = useState(false);    
    const [isBuildingSondaj, setIsBuildingSondaj] = useState(false);
    const [selectedAttachmentType, setSelectedAttachementType] = useState('');

    const [postContent, setPostContent] = useState('');


    const [sondajAllowMultipleAnswers, setSondajAllowMultipleAnswers] = useState(false);
    const [sondajAllowAnonymusAnswers, setSondajAllowAnonymusAnsers] = useState(false);
    const [sondajHideAnswers, setSondajHideAnswers] = useState(false);
    const [sondajAnswerOptions, setSondajAnswerOptions] = useState([]);

    const selectAttachType = (type) => {
        setIsBuildingSondaj(false);
        if (selectedAttachmentType == type && type=="link") setSelectedAttachementType('');
        else setSelectedAttachementType(type);

        if (selectedAttachmentType == 'imagine' && type == 'imagine') { }
        else { setSelectedFiles([]); }

        if (type == 'imagine') setAllowMultipleFilesUpload(true);
        else {
            setAllowMultipleFilesUpload(false);            
        }

        if (type == 'imagine' || type == 'video' || type == 'fisier') {
            $('#autoInputObj').trigger('click');
        }
    }
    const inputFileChanged = (e) => {
        if (allowMultipleFilesUpload) {
            const newFiles = [...selectedFiles];
            for (var x = 0; x < e.target.files.length; x++)
                newFiles.push(e.target.files[x]);
            setSelectedFiles(newFiles);
        }
        else {
            setSelectedFiles([e.target.files[0]]);
        }
    }

    const createPost = async () => {
        ///
        if (selectedAttachmentType === '' && postContent.replace(' ', '') === '') {
            alert(translate('generic.empty_post_err'));
            return;
        };
        post('Social/Feed/CreatePost', {
            'postContent': postContent,
            'linkValue': linkAttachmentValue,
            'attachType': selectedAttachmentType,
            'groupId': currentGroupId,
            'sondajData': JSON.stringify({
                'allowMultipleAnswers': sondajAllowMultipleAnswers,
                'allowAnonymusAnswers': sondajAllowAnonymusAnswers,
                'hideAnswers': sondajHideAnswers,
                'answerOptions': sondajAnswerOptions
            })            
        }, async (response) => {
            if (response === "forbidden") return;
            else {                
                let targetUrl = '';
                if (selectedAttachmentType === "imagine")
                    targetUrl = '/Social/Feed/UploadPostAttachmentIMGS?attachmentId=' + response
                else if (selectedAttachmentType === "video" || selectedAttachmentType === "fisier")
                    targetUrl = '/Social/Feed/UploadPostAttachment?attachmentId=' + response
                else {
                    setLinkAttachmentValue('');
                    setSelectedFiles([]);
                    setAllowMultipleFilesUpload(false);
                    setSelectedAttachementType('');
                    setPostContent('');
                    setIsBuildingSondaj(false);
                    return;
                }

                var data = new FormData();
                data.encoding = "multipart/form-data";
                for (var x = 0; x < selectedFiles.length; x++) {
                    data.append('files', selectedFiles[x]);
                }
                var request = new XMLHttpRequest();

                request.upload.addEventListener('progress', function (e) {
                    setUploadFileProgress(e.loaded);
                    setUploadFileProgressTotal(e.total);
                    if (e.total === e.loaded) {
                        setUploadFileProgress(0);
                        setUploadFileProgressTotal(0);                        
                    }
                }.bind(this), false);                

                request.open('POST', targetUrl);
                const token = await authService.getAccessToken();
                request.setRequestHeader('Authorization', `Bearer ${token}`);
                request.send(data);

                setLinkAttachmentValue('');
                setSelectedFiles([]);
                setAllowMultipleFilesUpload(false);
                setSelectedAttachementType('');
                setPostContent('');
                setIsBuildingSondaj(false);

                reloadPosts();
            }            
        });
    }

    let perc = (uploadFileProgress * 100) / uploadFileProgressTotal;
    return (
        <>
            <div className="card shadow">                
                <textarea
                    className="form-control no-outline"
                    rows={3}
                    placeholder={isBuildingSondaj ? translate('generic.add_question') : translate('generic.add_new_message')}
                    style={{ border: 'none' }}
                    value={postContent}
                    onChange={(e) => { setPostContent(e.target.value) }}
                ></textarea>

                {!isBuildingSondaj && (
                    <>
                        {selectedAttachmentType != "" && (<br />)}
                        {selectedAttachmentType == 'link' && (
                            <input value={linkAttachmentValue} onChange={(e) => { setLinkAttachmentValue(e.target.value); }} className="form-control no-outline" placeholder="http://" style={{ margin: 'auto', width: "calc(100% - 20px)" }} />
                        )}
                        {selectedAttachmentType == 'imagine' && (
                            <div className="row" style={{ margin: 'auto', width: "calc(100% - 20px)" }}>
                                {selectedFiles.map(((img, index) =>
                                    <div className="col-6 col-md-3" style={{ marginBottom: '5px', height: '100%' }} key={index}>
                                        <div style={{ height: '100%' }}>
                                            <span
                                                style={{ backgroundColor: '#34495e', padding: '5px', position: 'absolute', top: '5px', right: '0px' }}
                                                onClick={() => {
                                                    const cpy = [...selectedFiles];
                                                    cpy.splice(index, 1);
                                                    if (cpy.length === 0) {
                                                        setSelectedAttachementType('');
                                                    }
                                                    setSelectedFiles(cpy);
                                                }}
                                            >
                                                <RiDeleteBin5Line fill={'white'}/>
                                            </span>

                                            <span
                                                style={{ backgroundColor: '#34495e', padding: '5px', position: 'absolute', top: '50px', right: '0px' }}
                                                onClick={() => {
                                                    if (index == 0) return;
                                                    const newArray = [...selectedFiles];
                                                    const a = newArray[index];
                                                    const b = newArray[0];
                                                    newArray[0] = a;
                                                    newArray[index] = b;
                                                    setSelectedFiles(newArray);
                                                }}
                                            >
                                                {index == 0 && (
                                                    <VscPinned fill={'white'} />
                                                )}
                                                {index != 0 && (
                                                    <RiStarSLine fill={'white'} />
                                                )}
                                            </span>

                                            <div style={{ border: '5px solid #C58ED0', width: '100%', height: '100%' }}>
                                                <img style={{ width: '100%' }} src={URL.createObjectURL(img)} />
                                            </div>                                            
                                        </div>
                                    </div>
                                ))}
                                <div className="col-6 col-md-3" style={{ marginBottom: '5px', height: '100%' }}>
                                    <div style={{ height: '100%' }} className="text-center">
                                        <button className="btn btn-secondary" onClick={() => { selectAttachType('imagine') }}> + { translate('generic.add_image')}</button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {(selectedAttachmentType == 'video' || selectedAttachmentType == 'fisier') && (
                            <div style={{ margin: 'auto', width: "calc(100% - 20px)" }}>
                                {(selectedFiles[0] != null && (
                                    <ul className="list-group">
                                        <li className="list-group-item">
                                            {selectedFiles[0].name}
                                            <span
                                                className="float-right"
                                                style={{ padding: '5px' }}
                                                onClick={() => {
                                                    setSelectedAttachementType('');
                                                    setSelectedFiles([]);
                                                }}
                                            >
                                                <RiDeleteBin5Line />
                                            </span>
                                        </li>
                                    </ul>
                                ))}
                            </div>
                        )}
                        {uploadFileProgressTotal != 0 && (
                            <div style={{ margin: 'auto', width: "calc(100% - 20px)" }}>
                                <br />
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={perc}
                                        aria-valuemin="0" aria-valuemax="100" style={{ width: perc + "%" }}>
                                        {perc + "%"}
                                    </div>
                                </div>
                            </div>
                        )}

                        {selectedAttachmentType != "" && (<><br /><br /></>)}
                    </>
                )}
                {isBuildingSondaj && (
                    <div className="container">
                        <br/>
                        {sondajAnswerOptions.map((option, index) =>
                            <input
                                className="form-control no-outline"
                                style={{marginBottom: '10px'}}
                                value={option}
                                key={index}
                                autoFocus ={sondajAnswerOptions.length == index + 1 ? true : false}
                                onChange={(e) => {
                                    const cpy = [...sondajAnswerOptions];
                                    cpy[index] = e.target.value;
                                    if (cpy[index] === "")
                                        cpy.splice(index, 1);
                                    setSondajAnswerOptions(cpy);
                                }} />)}                        
                        <input className="form-control no-outline" placeholder={"+ " + translate('generic.answer_option')} onChange={(e) => {
                            const cpy = [...sondajAnswerOptions];
                            cpy.push(e.target.value);
                            setSondajAnswerOptions(cpy);
                            e.target.value = "";
                        }} />
                        <br />
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" value={sondajAllowMultipleAnswers} onChange={(e) => { setSondajAllowMultipleAnswers(e.target.checked); }} />
                            <label className="form-check-label">{translate('generic.multiple_answers')}</label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" value={sondajAllowAnonymusAnswers} onChange={(e) => { setSondajAllowAnonymusAnsers(e.target.checked); }} />
                            <label className="form-check-label">{translate('generic.anonymus_answers')}</label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" value={sondajHideAnswers} onChange={(e) => { setSondajHideAnswers(e.target.checked); }} />
                            <label className="form-check-label">{translate('generic.hide_answers')}</label>
                        </div>
                        <br />
                    </div>                    
                )}

                <div className="card-footer text-muted">
                    <div className="btnDRP" style={{ display: 'inline' }}>
                        <ButtonDropdown
                            className="no-outline"
                            isOpen={dropdownOpenSelectAttachType}
                            toggle={() => { setDropdownOpenSelectAttachType(!dropdownOpenSelectAttachType) }}
                            style={{ marginRight: '10px', borderRight: '1px solid #7f8c8d' }}
                        >
                            <DropdownToggle caret className="dropDownPostBIG no-outline text-secondary">
                                <GrAttachment/>
                                {translate('generic.attach')}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem className="dropDownPost" onClick={() => { selectAttachType('link') }}>
                                    <RiLinksFill />
                                    Link
                                </DropdownItem>
                                <DropdownItem className="dropDownPost" onClick={() => { selectAttachType('imagine') }}>
                                    <RiImageLine/>
                                    Image
                                </DropdownItem>
                                <DropdownItem className="dropDownPost" onClick={() => { selectAttachType('video') }}>
                                    <RiLiveLine/>
                                    Video
                                </DropdownItem>
                                <DropdownItem className="dropDownPost" onClick={() => { selectAttachType('fisier') }}>
                                    <ImFilesEmpty/>
                                    File
                                </DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
                        <button className="btn btn-secondary dropDownPostBIG no-outline text-secondary btnDRP" onClick={() => { setIsBuildingSondaj(!isBuildingSondaj) }}>
                            <GrCircleQuestion/>
                            {translate('generic.question')}
                        </button>
                    </div>
                    <button className="btn btn-primary float-right no-outline" style={{ color: 'white!important' }} onClick={() => { createPost(); }}>{translate('generic.post_action')}</button>
                </div>
            </div>
            <input id="autoInputObj" hidden type="file" style={{ display: 'none', position: 'fixed', top: '-5000em' }} onChange={inputFileChanged} multiple={allowMultipleFilesUpload} />
        </>
    );
}

export const PostBox = ({ post, reloadPosts, showToggle, setIsAutoRefresing, setAutoRefreshOffNewData, setPost }) => {    
    const [isImageGaleryOpen, setIsImageGaleryOpen] = useState(false);
    const [sondajSelectedItems, setSondajSelectedItems] = useState([]);
    const [isAnswered, setIsAnswered] = useState(post.sondajIsAnswered)
    const [sondajAnsCount, setSondajAnsCount] = useState(post.sondajVoturi);
    const [isSondajUsersModalOpen, setIsSondajUsersModalOpen] = useState(false);
    const [sondajAnswersUserIds, setSondajAnswersUserIds] = useState([]);

    useEffect(() => {
        const newSondajSelectedItems = [];
        for (var i = 0; i < post.sondajMyAnswered.length; i++)
            newSondajSelectedItems.push(post.sondajMyAnswered[i].questionIndex)
        setSondajSelectedItems(newSondajSelectedItems);
    }, [post]);

    const updateIsFeaturedStatus = () => {
        post_dn('Social/Feed/MarkAsFeatured', {
            postId: post.id,
            featured: !post.isFeatured
        }, (response) => {
            if (response == false)
                alert("Eroare.");
            else {
                reloadPosts();
            }
        });
    }

    const updateIsPrivate = () => {
        post_dn('Social/Feed/MarkAsPrivate', {
            postId: post.id,
            isPrivate: !post.isPrivate
        }, (response) => {
            if (response == false)
                alert("Eroare.");
            else {
                reloadPosts();
            }
        });
    }

    const [isEditMode, setIsEditMode] = useState(false);
    const [editModeContent, setEditModeContent] = useState(post.content);
    const [editModesondajAllowMultipleAnswers, editModeSetSondajAllowMultipleAnswers] = useState(post.sondaj != null ? post.sondaj.allowMultipleAnswers : false);
    const [editModesondajAllowAnonymusAnswers, editModeSetSondajAllowAnonymusAnsers] = useState(post.sondaj != null ? post.sondaj.anonymusAnswers : false);
    const [editModesondajHideAnswers, editModeSetSondajHideAnswers] = useState(post.sondaj != null ? post.sondaj.hideAnswers : false);
    const [editModesondajAnswerOptions, editModeSetSondajAnswerOptions] = useState(post.sondaj != null ? JSON.parse(post.sondaj.questionsJSON) : []);
    const [editModeLinkAttachmentValue, editModeSetLinkAttachmentValue] = useState(post.attachments.length > 0 ? post.attachments[0].blobName : '');
    const [editModeSelectedFiles, editModeSetSelectedFiles] = useState(post.attachments.length > 0 ? post.attachments : []);
    const [fileActuallyEdited, setFileActuallyEdited] = useState(false);

    const [uploadFileProgress, setUploadFileProgress] = useState(0);
    const [uploadFileProgressTotal, setUploadFileProgressTotal] = useState(0);

    const inputFileChanged = (e) => {
        if (post.attachments.length > 0 && post.attachments[0].type == 2) {
            const newFiles = [...editModeSelectedFiles];
            for (var x = 0; x < e.target.files.length; x++)
                newFiles.push(e.target.files[x]);
            editModeSetSelectedFiles(newFiles);
        }
        else {
            editModeSetSelectedFiles([e.target.files[0]]);
        }        
        setFileActuallyEdited(true);
    }

    const saveEdit = () => {
        post_dn('Social/Feed/UpdatePostData', {
            'postId': post.id,
            'postContent': editModeContent,
            'linkValue': editModeLinkAttachmentValue,
            'sondajData': JSON.stringify({
                'allowMultipleAnswers': editModesondajAllowMultipleAnswers,
                'allowAnonymusAnswers': editModesondajAllowAnonymusAnswers,
                'hideAnswers': editModesondajHideAnswers,
                'answerOptions': editModesondajAnswerOptions
            })
        }, async (response) => {
            if (response === false) alert("Eroare");
            else {
                console.log(post);
                console.log(fileActuallyEdited);

                if (!fileActuallyEdited) {
                    setIsEditMode(false);                    
                    reloadPosts();

                    setIsAutoRefresing(false);
                    setAutoRefreshOffNewData(true);

                    return;
                };
                if (post.attachments.length == 0) {
                    setIsEditMode(false);
                    reloadPosts();

                    setIsAutoRefresing(false);
                    setAutoRefreshOffNewData(true);

                    return;
                };
                let targetUrl = '';                
                
                if (post.attachments[0].type === 2)
                {
                    targetUrl = '/Social/Feed/UpdatePostAttachmentIMGS?postId=' + post.id
                    var data = new FormData();
                    data.encoding = "multipart/form-data";

                    for (var x = 0; x < editModeSelectedFiles.length; x++) {
                        if (editModeSelectedFiles[x].hasOwnProperty("blobName")) {
                            data.append('map', editModeSelectedFiles[x].blobName);
                        }
                        else {
                            data.append('map', 'fileData');
                            data.append('files', editModeSelectedFiles[x]);
                        }
                    }
                    var request = new XMLHttpRequest();

                    request.upload.addEventListener('progress', function (e) {
                        setUploadFileProgress(e.loaded);
                        setUploadFileProgressTotal(e.total);
                        if (e.total === e.loaded) {
                            setUploadFileProgress(0);
                            setUploadFileProgressTotal(0);

                            setIsEditMode(false);
                            reloadPosts();

                            setIsAutoRefresing(false);
                            setAutoRefreshOffNewData(true);

                            return;
                        }
                    }.bind(this), false);

                    request.open('POST', targetUrl);
                    const token = await authService.getAccessToken();
                    request.setRequestHeader('Authorization', `Bearer ${token}`);
                    request.send(data);                    
                }
                else if (post.attachments[0].type === 1 || post.attachments[0].type === 3)
                {
                    targetUrl = '/Social/Feed/UpdatePostAttachment?postId=' + post.id;
                    var data = new FormData();
                    data.encoding = "multipart/form-data";
                    for (var x = 0; x < editModeSelectedFiles.length; x++) {
                        data.append('files', editModeSelectedFiles[x]);
                    }
                    var request = new XMLHttpRequest();

                    request.upload.addEventListener('progress', function (e) {
                        setUploadFileProgress(e.loaded);
                        setUploadFileProgressTotal(e.total);
                        if (e.total === e.loaded) {
                            setUploadFileProgress(0);
                            setUploadFileProgressTotal(0);
                        }
                    }.bind(this), false);

                    request.open('POST', targetUrl);
                    const token = await authService.getAccessToken();
                    request.setRequestHeader('Authorization', `Bearer ${token}`);
                    request.send(data);

                    setIsEditMode(false);

                    setIsAutoRefresing(false);
                    setAutoRefreshOffNewData(true);
                    

                    reloadPosts();
                }
                else {
                    setIsEditMode(false);

                    setIsAutoRefresing(false);
                    setAutoRefreshOffNewData(true);

                    reloadPosts();
                    return;
                };               
            }
        });        
    }

    const [isLikeDropdownOpen, setIsLikeDropdownOpen] = useState(false);
    let perc = (uploadFileProgress * 100) / uploadFileProgressTotal;

    const [reactionUsersModalList, setReactionUsersModalList] = useState([]);
    const [reactionModalTitle, setReactionModalTitle] = useState('');
    const [reactionModalVisible, setReactionModalVisible] = useState(false);

    const [viewAllComments, setViewAllComments] = useState(false);
    const [forceShowAddComment, setForceShowAddComment] = useState(false);

    const getUserIdsPerReaction = (postId, reaction) => {
        post_dn("Social/Feed/GetUserIdsListPerPostAndReaction", { postId: postId, reaction: reaction },
            (response) => {
                setReactionUsersModalList(response);
                setReactionModalTitle(reaction == "haha" ? "funny" : reaction);
                setReactionModalVisible(true);
            });
    }

    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
    const [deleteSelectedPostId, setDeleteSelectedPostId] = useState(-1);

    useEffect(() => {
        if (deleteSelectedPostId != -1)
            setIsDeleteDialogVisible(true);
    }, [deleteSelectedPostId]);

    const [commentWasAdded, setCommentWasAdded] = useState(false);
    useEffect(() => {
        setCommentWasAdded(false);
        getPostTranslatedContent();
    }, [post]);
        
    const [translationTragetCode, setTranslationTargetCode] = useState(
        sessionStorage.getItem("lang") === post.contentOriginalLang ? 
        'original' : sessionStorage.getItem("lang_srv")
    );
    const [shouldRenderBody, setShouldRenderBody] = useState(true);

    useEffect(()=>{
        setShouldRenderBody(false);
        setTimeout(()=>{setShouldRenderBody(true)}, 5);
    }, [translationTragetCode]);

    const getPostTranslatedContent = () => {    
        try{
            if(translationTragetCode !== 'original')      
            {
                for(var i = 0; i < post.translations.length; i++)
                {
                    if(post.translations[i].to === translationTragetCode)
                    {                    
                        return post.translations[i].text;
                    }
                }                    
            } 
        }   
        catch(e){}
        if(post.content === null)
            return "";
        return post.content;
    }
    
    const getSondajTranslatedQuestions = (question_id) => {      
        const questionsInfo = JSON.parse(post.sondaj.questionsJSON);  
        try
        {
            if(translationTragetCode !== 'original')      
            {
                const translationInfo = JSON.parse(post.sondaj.translationsInfo);                        
                for(var i = 0; i < translationInfo[question_id].length; i++)
                {
                    if(translationInfo[question_id][i].To === translationTragetCode)
                    {                                        
                        return translationInfo[question_id][i].Text;
                    }
                }                    
            }  
        }catch(e){}
        return questionsInfo[question_id];
    }

    return (
        <div className="card post shadow">
            <DeleteConfirmationModal
                isOpen={isDeleteDialogVisible}
                toggle={() => { setIsDeleteDialogVisible(!isDeleteDialogVisible); }}
                onConfirm={() => {
                    post_dn('Social/Feed/DeletePost', {
                        postId: deleteSelectedPostId,
                    }, (response) => {
                        if (response == false)
                            alert("Eroare.");
                        else {
                            reloadPosts();
                        }
                    });
                }}
            />
            <div className="card-header">
                <Link to={"/profile/" + post.user.id} className="initialA">
                    <img src={post.user.imagePath} className="float-left" />
                    <div className="headerData">
                        <strong className="uName">{post.user.displayName}</strong>
                        <label className="cAt text-secondary">{post.createdAt}</label>
                    </div>
                </Link>
                <div className="float-right" style={{marginTop: '-50px'}}>
                    {post.translations !== null && post.translations.length !== 0 && (                        
                        <UncontrolledDropdown style={{display: 'inline-block'}}>
                            <DropdownToggle className='btn btn-light no-outline transparentBkg' style={{paddingRight: '10px', paddingRight: '10px'}}>
                                <RiTranslate className={translationTragetCode !== 'original' ? "primary-fill" : ""}/>
                            </DropdownToggle>
                            <DropdownMenu style={{ marginLeft: '-180px', width: '230px' }} className="shadow">
                                {translationTragetCode !== 'original' && (
                                    <DropdownItem className="dropDownPost" onClick={()=>{setTranslationTargetCode('original')}}>                                        
                                        Original
                                    </DropdownItem>
                                )}             
                                {post.translations.map((t)=>
                                    <DropdownItem 
                                        className={t.to == translationTragetCode ? "dropDownPost primary-color" : "dropDownPost"}
                                        onClick={()=>{setTranslationTargetCode(t.to)}}
                                    >
                                        {t.to == 'hu' ? "Hungarian" : t.to == 'ro' ? "Romanian" : t.to == 'cs' ? "Czech" : t.to == 'en' ? "English" : t.to == 'sk' ? "Slovakian" : t.to}
                                    </DropdownItem>
                                )}                   
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    )}                    
                    {showToggle != false && (
                        <UncontrolledDropdown style={{display: 'inline-block'}}>
                            <DropdownToggle caret className={post.isFeatured ? "btn btn-warning no-outline featured" : "btn btn-light no-outline transparentBkg"}>
                            </DropdownToggle>
                            <DropdownMenu style={{ marginLeft: '-180px', width: '230px' }} className="shadow">
                                {post.isAdmin && (
                                    <>
                                        <DropdownItem className="dropDownPost"
                                            onClick={() => {
                                                setDeleteSelectedPostId(post.id);                                                
                                            }}
                                        >
                                            <RiDeleteBin5Line />
                                            {translate('generic.delete')}
                                        </DropdownItem>
                                        <DropdownItem className="dropDownPost" onClick={() => { setIsEditMode(true); }}>
                                            <RiEdit2Line />
                                            {translate('generic.edit')}
                                        </DropdownItem>
                                        {sessionStorage.getItem("groupRole") == "true" && (
                                            <>
                                                {!post.isFeatured && (
                                                    <DropdownItem className="dropDownPost" onClick={() => { updateIsFeaturedStatus(); }}>
                                                        <RiStarSFill />
                                                        {translate('generic.mark_as_important')}
                                                    </DropdownItem>
                                                )}
                                                {post.isFeatured && (
                                                    <DropdownItem className="dropDownPost" onClick={() => { updateIsFeaturedStatus(); }}>
                                                        <RiStarSLine />
                                                        {translate('generic.unmark_as_important')}                                                        
                                                    </DropdownItem>
                                                )}

                                                {!post.isPrivate && (
                                                    <DropdownItem className="dropDownPost" onClick={() => { updateIsPrivate(); }}>
                                                        <RiLockLine />
                                                        {translate('generic.mark_as_private_post')}
                                                    </DropdownItem>
                                                )}
                                                {post.isPrivate && (
                                                    <DropdownItem className="dropDownPost" onClick={() => { updateIsPrivate(); }}>
                                                        <RiLockUnlockLine />
                                                        {translate('generic.mark_as_public_post')}
                                                    </DropdownItem>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                <DropdownItem className="dropDownPost" onClick={() => {
                                    window.location.href = "/postDetails/" + post.id;
                                }}>
                                    <RiLinksFill />
                                    {translate('generic.posts_page')}
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    )}                    
                </div>
            </div>
            {shouldRenderBody && (
                <>
                    <div className="card-body">                
                        <div className="text-container">
                            <p className="card-text">
                                {!isEditMode && (
                                    <OverflowText text={`${getPostTranslatedContent(false)}`} />
                                )}
                                {isEditMode && (
                                    <textarea
                                        className="form-control no-outline"
                                        rows={3}
                                        placeholder={translate('generic.change_text_content')}
                                        style={{ border: 'none' }}
                                        value={editModeContent}
                                        onChange={(e) => { setEditModeContent(e.target.value) }}
                                    ></textarea>
                                )}
                            </p>
                        </div>
                        {post.attachments.length > 0 && (
                            <>
                                {post.attachments[0].type == 0 && (
                                    <>
                                        {!isEditMode && (
                                            <>
                                                <br />
                                                <div className="btnDRP">
                                                    <a className="dropDownPost" style={{ cursor: 'pointer' }} href={post.attachments[0].blobName} target="_blank">
                                                        <RiLinksFill />
                                                        {post.attachments[0].blobName}
                                                    </a>
                                                </div>
                                                <br />
                                                <ManagedIFrameBox url={post.attachments[0].blobName} />
                                            </>
                                        )}
                                        {isEditMode && (
                                            <input value={editModeLinkAttachmentValue} onChange={(e) => { editModeSetLinkAttachmentValue(e.target.value); }} className="form-control no-outline" placeholder="http://" style={{ margin: 'auto', width: "calc(100% - 20px)" }} />
                                        )}
                                    </>
                                )}
                                {post.attachments[0].type == 1 && (
                                    <>
                                        {!isEditMode && (
                                            <div className="btnDRP">
                                                <a className="dropDownPost" style={{ cursor: 'pointer' }} onClick={() => { downloadAs("https://joyhubregular.azureedge.net/" + post.attachments[0].blobName, post.attachments[0].originalName) }}>
                                                    {getFileIcon(post.attachments[0].originalName)}
                                                    {post.attachments[0].originalName}
                                                </a>
                                            </div>
                                        )}
                                        {isEditMode && (
                                            <div style={{ margin: 'auto', width: "calc(100% - 20px)" }}>
                                                {(editModeSelectedFiles[0] != null && (
                                                    <ul className="list-group">
                                                        <li className="list-group-item">
                                                            {editModeSelectedFiles[0].hasOwnProperty('originalName') ? editModeSelectedFiles[0].originalName : editModeSelectedFiles[0].name}
                                                            <span
                                                                className="float-right"
                                                                style={{ padding: '5px' }}
                                                                onClick={() => {                                                            
                                                                    $('#autoInputObjEDIT').trigger('click');
                                                                }}
                                                            >
                                                                <BsArrowRepeat/>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                ))}
                                            </div>
                                        )}
                                    </>
                                )}
                                {post.attachments[0].type == 2 && (
                                    <>
                                        {!isEditMode && (
                                            <div className="container-fluid">
                                                <br />
                                                {post.attachments.length == 1 && (
                                                    <div className="row">
                                                        {post.attachments.map((atach) =>
                                                            <div className="col-12">
                                                                <img style={{ maxWidth: '100%', maxHeight: '500px', display: 'block', margin: 'auto' }} src={"https://joyhubregular.azureedge.net/" + atach.blobName} key={atach.blobName} onClick={() => { setIsImageGaleryOpen(true); }}/>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {post.attachments.length == 2 && (
                                                    <div className="row">
                                                        {post.attachments.map((atach, index) =>
                                                            <div className="col-6" key={index}>
                                                                <img style={{ width: '100%' }} src={"https://joyhubregular.azureedge.net/" + atach.blobName} key={atach.blobName} onClick={() => { setIsImageGaleryOpen(true); }}/>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {post.attachments.length >= 3 && (
                                                    <div className="row">
                                                        {post.attachments.slice(0, 3).map((atach, index) =>
                                                            <div className="col-4" key={index}>
                                                                <img style={{ width: '100%' }} src={"https://joyhubregular.azureedge.net/" + atach.blobName} key={atach.blobName} onClick={() => { setIsImageGaleryOpen(true); }}/>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {post.attachments.length >= 1 && (
                                                    <>
                                                        <div style={{ backgroundColor: "#f7f7f7", borderRadius: '5px', padding: '2px', paddingLeft: '5px', marginTop: '5px' }}>
                                                            <span className="dropDownPost" style={{ borderRight: '1px solid black', paddingRight: '10px' }}>
                                                                <RiCameraFill />
                                                                {post.attachments.length}
                                                            </span>
                                                            <a className="btnDRP" onClick={() => { setIsImageGaleryOpen(true); }} style={{ fontSize: '14px', marginLeft: '10px', cursor: 'pointer' }}>{translate('generic.view_gallery')} »</a>
                                                        </div>
                                                        <ImageGallery
                                                            isOpen={isImageGaleryOpen}
                                                            closeGallery={() => { setIsImageGaleryOpen(false); }}
                                                            attachments={post.attachments}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        {isEditMode && (
                                            <div className="row" style={{ margin: 'auto', width: "calc(100% - 20px)" }}>
                                                {editModeSelectedFiles.map(((img, index) =>
                                                    <div className="col-6 col-md-3" style={{ marginBottom: '5px', height: '100%' }} key={index}>
                                                        <div style={{ height: '100%' }}>
                                                            <span
                                                                style={{ backgroundColor: '#34495e', padding: '5px', position: 'absolute', top: '5px', right: '0px' }}
                                                                onClick={() => {
                                                                    const cpy = [...editModeSelectedFiles];
                                                                    cpy.splice(index, 1);
                                                                    setFileActuallyEdited(true);
                                                                    editModeSetSelectedFiles(cpy);
                                                                }}
                                                            >
                                                                <RiDeleteBin5Line fill={'white'} />
                                                            </span>

                                                            <span
                                                                style={{ backgroundColor: '#34495e', padding: '5px', position: 'absolute', top: '50px', right: '0px' }}
                                                                onClick={() => {
                                                                    if (index == 0) return;
                                                                    const newArray = [...editModeSelectedFiles];
                                                                    const a = newArray[index];
                                                                    const b = newArray[0];
                                                                    newArray[0] = a;
                                                                    newArray[index] = b;
                                                                    editModeSetSelectedFiles(newArray);
                                                                }}
                                                            >
                                                                {index == 0 && (
                                                                    <VscPinned fill={'white'} />
                                                                )}
                                                                {index != 0 && (
                                                                    <RiStarSLine fill={'white'} />
                                                                )}
                                                            </span>

                                                            <div style={{ border: '5px solid #e67e22', width: '100%', height: '100%' }}>
                                                                <img style={{ width: '100%' }} src={img.hasOwnProperty('blobName') ? "https://joyhubregular.azureedge.net/" + img.blobName : URL.createObjectURL(img)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="col-6 col-md-3" style={{ marginBottom: '5px', height: '100%' }}>
                                                    <div style={{ height: '100%' }} className="text-center">
                                                        <button className="btn btn-secondary" onClick={() => { $('#autoInputObjEDIT').click(); }}> + { translate('generic.add_image')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                                {post.attachments[0].type == 3 && (
                                    <>
                                        {!isEditMode && (
                                            <video width="100%" height="480" preload="metadata" controls>
                                                <source src={"https://joyhubregular.azureedge.net/" + post.attachments[0].blobName + "#t=0.1"} type='video/mp4' />                                            
                                                Your browser does not support the video tag.
                                            </video>
                                        )}
                                        {isEditMode && (
                                            <div style={{ margin: 'auto', width: "calc(100% - 20px)" }}>
                                                {(editModeSelectedFiles[0] != null && (
                                                    <ul className="list-group">
                                                        <li className="list-group-item">
                                                            {editModeSelectedFiles[0].hasOwnProperty('originalName') ? editModeSelectedFiles[0].originalName : editModeSelectedFiles[0].name}
                                                            <button
                                                                className="float-right btn btn-secondary"
                                                                style={{ padding: '5px' }}
                                                                disabled={false}
                                                                onClick={() => {                                                            
                                                                    $('#autoInputObjEDIT').trigger('click');
                                                                }}
                                                            >
                                                                <BsArrowRepeat />
                                                            </button>
                                                        </li>
                                                    </ul>
                                                ))}
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {post.sondaj && (
                            <>
                                {!isEditMode && (
                                    <div className="customSondaj">
                                        <br />
                                        {!isAnswered && (
                                            <>
                                                {JSON.parse(post.sondaj.questionsJSON).map((question, index) =>
                                                    <div key={index}>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" checked={sondajSelectedItems.includes(index)}
                                                                onChange={(e) => {
                                                                    const cpy = [...sondajSelectedItems];
                                                                    if (e.target.checked) {
                                                                        if (cpy.length >= 1 && post.sondaj.allowMultipleAnswers == false) return;
                                                                        cpy.push(index);
                                                                    }
                                                                    else
                                                                        cpy.splice(cpy.indexOf(index), 1);
                                                                    setSondajSelectedItems(cpy);
                                                                }}
                                                            />
                                                            <label className="form-check-label">
                                                                {String.fromCharCode((index + 97)) + ") " + getSondajTranslatedQuestions(index)}
                                                            </label>
                                                        </div>
                                                    </div>
                                                )}
                                                <br />
                                                <button className="btn btn-primary no-outline" onClick={() => {
                                                    post_dn('Social/Feed/VoteSondaj', {
                                                        postId: post.id,
                                                        answerIndex: sondajSelectedItems
                                                    }, (response) => {
                                                        if (response === true) { setIsAnswered(true); reloadPosts(); }
                                                        else alert("Eroare la salvare");
                                                    })
                                                }}>{translate('generic.vote')}</button>
                                                {/* TODO Move this to translations dictioanry as well */}
                                            </>
                                        )}
                                        {isAnswered && (
                                            <>
                                                {JSON.parse(post.sondaj.questionsJSON).map((question, index) =>
                                                    <div className="cs_row" key={index}>
                                                        <div className="btn-group" style={{ width: '100%' }}>
                                                            <button type="button" className="btn btn-link no-outline" style={{color: 'black!important'}}>{String.fromCharCode((index + 97)) + ")"}</button>
                                                            <button type="button" className={sondajSelectedItems.includes(index) ? "btn btn-secondary no-outline" : "btn btn-link no-outline"} style={{ width: '100%', textAlign: 'left', color: 'black!important' }}>{question}</button>
                                                            {(post.isAdmin || post.sondaj.hideAnswers == false) && (
                                                                <button type="button" className="btn btn-primary no-outline"
                                                                    onClick={() => {
                                                                        if (post.isAdmin || post.sondaj.anonymusAnswers == false) {
                                                                            const ids = [];
                                                                            if (JSON.parse(post.sondaj.answersJSON) != null) {
                                                                                const userAns = JSON.parse(post.sondaj.answersJSON).filter((el) => el.questionIndex == index);
                                                                                for (var i = 0; i < userAns.length; i++)
                                                                                    ids.push(userAns[i].userId)
                                                                            }
                                                                            setSondajAnswersUserIds(ids);
                                                                            setIsSondajUsersModalOpen(true);
                                                                        }
                                                                    }}
                                                                >
                                                                    {((JSON.parse(post.sondaj.answersJSON) != null) ? JSON.parse(post.sondaj.answersJSON).filter((el) => el.questionIndex == index).length : 0)}
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}

                                                <br/>
                                                <div style={{ backgroundColor: "rgb(247, 247, 247)", borderRadius: '5px', padding: '2px', paddingLeft: '5px' }}>
                                                    <a style={{ fontSize: '14px' }} className="initialA">{sondajAnsCount} {translate('generic.votes')}</a>
                                                    <span> • </span>
                                                    <div className="btnDRP" style={{ display: 'inline' }}>
                                                        <a style={{ fontSize: '14px', cursor: 'pointer' }} className="initialA" onClick={() => {
                                                            setIsAnswered(false);
                                                        }}>{translate('generic.change_vote')}</a>
                                                    </div>
                                                </div>

                                            </>
                                        )}

                                        <UsersViewerModal
                                            isOpen={isSondajUsersModalOpen}
                                            toggle={() => { setIsSondajUsersModalOpen(!isSondajUsersModalOpen); }}
                                            title={'Raspunsuri'}
                                            selectedUsersIds={sondajAnswersUserIds}
                                        />
                                    </div>
                                )}
                                {isEditMode && (
                                    <div className="container">
                                        <br />
                                        {editModesondajAnswerOptions.map((option, index) =>
                                            <input
                                                className="form-control no-outline"
                                                style={{ marginBottom: '10px' }}
                                                value={option}
                                                key={index}
                                                autoFocus={editModesondajAnswerOptions.length == index + 1 ? true : false}
                                                onChange={(e) => {
                                                    const cpy = [...editModesondajAnswerOptions];
                                                    cpy[index] = e.target.value;
                                                    if (cpy[index] === "")
                                                        cpy.splice(index, 1);
                                                    editModeSetSondajAnswerOptions(cpy);
                                                }} />)}
                                        <input className="form-control no-outline" placeholder={"+ " + translate('generic.answer_option')} onChange={(e) => {
                                            const cpy = [...editModesondajAnswerOptions];
                                            cpy.push(e.target.value);
                                            editModeSetSondajAnswerOptions(cpy);
                                            e.target.value = "";
                                        }} />
                                        <br />
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" checked={editModesondajAllowMultipleAnswers} onChange={(e) => { editModeSetSondajAllowMultipleAnswers(e.target.checked); }} />
                                            <label className="form-check-label">{translate('generic.multiple_answers')}</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" checked={editModesondajAllowAnonymusAnswers} onChange={(e) => { editModeSetSondajAllowAnonymusAnsers(e.target.checked); }} />
                                            <label className="form-check-label">{translate('generic.anonymus_answers')}</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" checked={editModesondajHideAnswers} onChange={(e) => { editModeSetSondajHideAnswers(e.target.checked); }} />
                                            <label className="form-check-label">{translate('generic.hide_answers')}</label>
                                        </div>
                                        <br />
                                    </div>
                                )}
                            </>
                        )}

                        {isEditMode && (
                            <>
                                <br />
                                {uploadFileProgressTotal != 0 && (
                                    <div style={{ margin: 'auto', width: "calc(100% - 20px)" }}>
                                        <br />
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" aria-valuenow={perc}
                                                aria-valuemin="0" aria-valuemax="100" style={{ width: perc + "%" }}>
                                                {perc + "%"}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <br />
                                <button type="submit" className="btn btn-primary no-outline" onClick={saveEdit}>{translate('generic.save')}</button>
                                <button type="submit" className="btn btn-link no-outline" onClick={() => { setIsEditMode(false) }}>{translate('generic.cancel')}</button>

                                <input id="autoInputObjEDIT" hidden type="file" style={{ display: 'none', position: 'fixed', top: '-5000em' }} onChange={inputFileChanged} multiple={post.attachments.length > 0 && post.attachments[0].type == 2} />
                            </>
                        )}

                        <br />                

                        <div>
                            <a className="initialA" onClick={() => { getUserIdsPerReaction(post.id, 'like'); }} style={{ fontSize: '14px', cursor: 'pointer' }}>{post.likes} <img style={{ height: '20px' }} src="/images/like_icon.png" /></a>
                            <span> • </span>
                            <a className="initialA" onClick={() => { getUserIdsPerReaction(post.id, 'love'); }} style={{ fontSize: '14px', cursor: 'pointer' }}>{post.loves} <img style={{ height: '20px' }} src="/images/heart_icon.png" /></a>
                            <span> • </span>
                            <a className="initialA" onClick={() => { getUserIdsPerReaction(post.id, 'haha'); }} style={{ fontSize: '14px', cursor: 'pointer' }}>{post.hahas} <img style={{ height: '20px' }} src="/images/haha_icon.png" /></a>
                            <span> • </span>
                            <a className="initialA" style={{ fontSize: '14px', cursor: 'pointer' }}>{post.comments.length} <AiOutlineComment/></a>
                        </div>
                        <div className="float-right" style={{marginTop: '-25px'}}>
                            <div className="">
                                <a style={{ fontSize: '14px', cursor: 'pointer' }} className="btnDRP initialA"
                                    onClick={() => {
                                        if (post.myLike) {
                                            post_dn("Social/Feed/ToggleLike", { postId: post.id }, () => {
                                                const obj = JSON.parse(JSON.stringify(post));
                                                obj.myLike = !post.myLike;
                                                setPost(obj);
                                                reloadPosts();
                                            })
                                        }
                                        else {
                                            setIsLikeDropdownOpen(!isLikeDropdownOpen);
                                        }
                                    }}>{post.myLike ? "Unlike" : "Like"}</a>

                                {/*<span> • </span>*/}
                                {/*<a style={{ fontSize: '14px', cursor: 'pointer' }} className="btnDRP">Share</a>*/}

                                <span> • </span>
                                <a style={{ fontSize: '14px', cursor: 'pointer' }} className="btnDRP initialA" onClick={() => { setForceShowAddComment(!forceShowAddComment) }}>Comment</a>
                            </div>
                        </div>
                        <Collapse isOpen={isLikeDropdownOpen}>
                            <div className="text-center emojiSelector">
                                <FacebookSelector
                                    reactions={['like', 'love', 'haha']}
                                    onSelect={(e) => {                                
                                        post_dn("Social/Feed/ToggleLike", { postId: post.id, reaction: e }, () => {
                                            const obj = JSON.parse(JSON.stringify(post));
                                            obj.myLike = !post.myLike;
                                            setPost(obj);
                                            setIsLikeDropdownOpen(false); reloadPosts();
                                        })
                                    }}
                                />
                            </div>
                        </Collapse>
                        <UsersViewerModal
                            isOpen={reactionModalVisible}
                            toggle={() => { setReactionModalVisible(!reactionModalVisible) }}
                            title={reactionModalTitle}
                            selectedUsersIds={reactionUsersModalList}
                        />
                    </div>
                    {(post.comments.length != 0 || forceShowAddComment) && (
                        <div className="card-footer text-muted" style={{ paddingTop: '5px' }}>
                            {post.comments.length >= 2 && (
                                <>
                                    <a className="btnDRP" style={{ fontSize: '14px', cursor: 'pointer', marginBottom: '10px' }} onClick={() => { setViewAllComments(!viewAllComments) }}>
                                        <BiMessageRoundedDots style={{ marginRight: '5px' }} />
                                        {viewAllComments == false ? translate('generic.view_all_comments') : translate('generic.hide_comments')}
                                    </a>
                                    <br />
                                </>
                            )}
                            {!viewAllComments && (
                                <>
                                    {post.comments.length > 0 && (
                                        <Comment targetTranslation={translationTragetCode} comment={post.comments[post.comments.length - 1]} reload={reloadPosts} updateMyLikeToggle={() => {
                                            const obj = JSON.parse(JSON.stringify(post));
                                            for (var x = 0; x < obj.comments.length; x++)
                                                if (obj.comments[x].id == post.comments[post.comments.length - 1].id)
                                                    obj.comments[x].myLike = !post.comments[x].myLike;
                                            setPost(obj);
                                        }}/>
                                    )}
                                </>
                            )}
                            {viewAllComments && (
                                <>
                                    {post.comments.map((c, index) =>
                                        <Comment targetTranslation={translationTragetCode} comment={c} key={index} reload={reloadPosts} updateMyLikeToggle={() => {
                                            const obj = JSON.parse(JSON.stringify(post));
                                            for (var x = 0; x < obj.comments.length; x++)
                                                if (obj.comments[x].id == c.id)
                                                    obj.comments[x].myLike = !post.comments[x].myLike;
                                            setPost(obj);                                                                        
                                        }} />
                                    )}
                                </>
                            )}
                            {commentWasAdded && (
                                <label style={{ textAlign: 'center' }}>{translate('generic.comment_added')}</label>
                            )}
                            <NewCommentDialog postId={post.id} reloadPost={reloadPosts} showNewComm={() => {
                                setCommentWasAdded(true);
                            }}/>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

const Comment = ({ comment, reload, updateMyLikeToggle, targetTranslation }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

    const getPostTranslatedContent = () => {                            
        try
        {
            for(var i = 0; i < comment.translationsInfo.translations.length; i++)
            {            
                if(comment.translationsInfo.translations[i].to === targetTranslation ||
                    targetTranslation === 'original' && (comment.translationsInfo.translations[i].to === sessionStorage.getItem('lang_srv')))
                {                    
                    // alert(comment.translationsInfo.translations[i].text);
                    return comment.translationsInfo.translations[i].text;
                }
            }   
        }       
        catch(E){}               
        // alert(comment.content);
        return comment.content;
    }

    return (
        <div className="comment">
            <Link to={'/profile/' + comment.user.id} className="initialA">
                <img src={comment.user.imagePath} className="float-left" />
            </Link>
            <div className="data">
                <p style={{ marginBottom: '0' }}>
                    <Link to={'/profile/' + comment.user.id} className="initialA"><strong>{comment.user.displayName}</strong></Link>
                    <div
                        dangerouslySetInnerHTML={{ __html: getPostTranslatedContent() }}
                        style={{ whiteSpace: "pre-line"}}>
                    </div>
                </p>                
                {comment.isAdmin && (
                    <RiDeleteBin5Line className="float-right" style={{ cursor: 'pointer' }} onClick={() => { setDeleteDialogVisible(true); }} />
                )}

                {( (comment.contentOriginalLang !== null && comment.contentOriginalLang !== undefined) && comment.contentOriginalLang.toLowerCase() !== targetTranslation ) && (
                    <>         
                        {(targetTranslation === 'original' && comment.contentOriginalLang !== sessionStorage.getItem("lang")) && (
                            <>
                                <RiTranslate 
                                    className="float-right primary-fill"
                                    id={`UncontrolledTooltipExample1_${comment.id}`}
                                />
                                <UncontrolledTooltip placement="left" target={`UncontrolledTooltipExample1_${comment.id}`}>
                                    <div dangerouslySetInnerHTML={{ __html: comment.content }}/>
                                </UncontrolledTooltip>
                            </>
                        )}                                       
                        {targetTranslation !== 'original' && (
                            <>
                                <RiTranslate 
                                    className="float-right primary-fill" 
                                    id={`UncontrolledTooltipExample2_${comment.id}`}                    
                                />
                                <UncontrolledTooltip placement="left" target={`UncontrolledTooltipExample2_${comment.id}`}>
                                    <div dangerouslySetInnerHTML={{ __html: comment.content }}/>
                                </UncontrolledTooltip>
                            </>
                        )}
                    </>
                )}                                                            

                {/* {
                    <>
                    <span>{`col ${comment.contentOriginalLang.toLowerCase()} - target ${targetTranslation} `}</span>
                    <span>{`local ${sessionStorage.getItem('lang')} `}</span>
                    <span>{`${comment.contentOriginalLang !== sessionStorage.getItem("lang")}`}</span>
                    </>
                } */}

                {comment.attachment != null && (
                    <>
                        {(!comment.attachment.toLowerCase().includes(".png") && !comment.attachment.toLowerCase().includes(".gif") && !comment.attachment.toLowerCase().includes(".jpg") && !comment.attachment.toLowerCase().includes(".jpeg")) ? (
                            <div className="btnDRP">
                                <a className="dropDownPost" style={{ cursor: 'pointer' }} onClick={() => { downloadAs("https://joyhubregular.azureedge.net/" + comment.attachment, comment.attachmentOriginalName) }}>
                                    {getFileIcon(comment.attachmentOriginalName)}
                                    {comment.attachmentOriginalName}
                                </a>
                            </div>
                        ) : (<img src={"https://joyhubregular.azureedge.net/" + comment.attachment} style={{ width: "50%", height: 'auto' }} />)}
                    </>
                )}
                <div>
                    <a style={{ fontSize: '12px', cursor: 'pointer' }} className="btnDRP initialA" onClick={() => {
                        post_dn("Social/Feed/ToggleLikeToComment", { commentId: comment.id }, () => {
                            updateMyLikeToggle();
                            reload();
                        })
                    }}>
                        {comment.myLike ? "Unlike" : "Like" }
                    </a>
                    <span> • </span>
                    <a style={{ fontSize: '12px', cursor: 'pointer' }} className="btnDRP initialA"
                        onClick={() => {
                            post_dn("social/feed/GetUserIdsListPerComment", { commentId: comment.id }, (response) => {
                                setSelectedUsers(response);
                                setIsDialogOpen(true);
                            })
                        }}
                    >{comment.likes} <AiOutlineLike /></a>
                    <span> • </span>
                    <a style={{ fontSize: '12px', cursor: 'pointer' }} className="initialA">{comment.createdAt}</a>
                </div>                
            </div>
            <UsersViewerModal
                isOpen={isDialogOpen}
                toggle={() => { setIsDialogOpen(!isDialogOpen) }}
                title={translate('generic.users')}
                selectedUsersIds={selectedUsers}
            />
            <DeleteConfirmationModal
                isOpen={deleteDialogVisible}
                toggle={() => { setDeleteDialogVisible(!deleteDialogVisible) }}
                onConfirm={() => {
                    post_dn("Social/Feed/DeleteComment", { commentId: comment.id }, () => { reload()})
                }}
            />
        </div>
    );
}

const NewCommentDialog = ({ postId, reloadPost, showNewComm }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [commentText, setCommentText] = useState('');

    const [uploadFileProgress, setUploadFileProgress] = useState(0);
    const [uploadFileProgressTotal, setUploadFileProgressTotal] = useState(0);

    const inputFileChanged = (e) => {
        setSelectedFile(e.target.files[0]);        
    }

    const addComment = (e) => {
        post_dn("Social/Feed/AddNewComment", { postId: postId, content: commentText, requireAttach: (selectedFile != null) },
            async (response) => {
                if (response === "forbidden" || response == "") return;
                else {
                    let targetUrl = '/Social/Feed/UploadCommentAttachment?attachmentId=' + response                    

                    var data = new FormData();
                    data.encoding = "multipart/form-data";
                    data.append('files', selectedFile);
                    var request = new XMLHttpRequest();

                    request.upload.addEventListener('progress', function (e) {
                        setUploadFileProgress(e.loaded);
                        setUploadFileProgressTotal(e.total);
                        if (e.total === e.loaded) {
                            setUploadFileProgress(0);
                            setUploadFileProgressTotal(0);
                        }
                    }.bind(this), false);

                    request.open('POST', targetUrl);
                    const token = await authService.getAccessToken();
                    request.setRequestHeader('Authorization', `Bearer ${token}`);
                    request.send(data);

                    setSelectedFile(null);
                    setCommentText('');

                    showNewComm(commentText);

                    reloadPost();
                }
            });
    }

    let perc = (uploadFileProgress * 100) / uploadFileProgressTotal;
    if (sessionStorage.getItem("canComment") == "true")
        return (
            <div className="comment" style={{ paddingTop: '5px' }}>
                <img src={sessionStorage.getItem("avatar")} className="float-left" style={{ borderRadius:'50%' }} />
                <div className="data">
                    <textarea value={commentText} onChange={(e) => { setCommentText(e.target.value); }} className="form-control no-outline" placeholder={translate('generic.your_comment')} rows={2}></textarea>
                    {selectedFile != null && (
                        <ul className="list-group" style={{ marginTop: '5px', marginBottom: '5px' }}>
                            <li className="list-group-item">
                                {selectedFile.name}
                                <span
                                    className="float-right"
                                    style={{ padding: '5px' }}
                                    onClick={() => {
                                        setSelectedFile(null);
                                    }}
                                >
                                    <RiDeleteBin5Line />
                                </span>
                            </li>
                        </ul>
                    )}
                    {uploadFileProgressTotal != 0 && (
                        <div style={{ margin: 'auto', width: "calc(100% - 20px)" }}>
                            <br />
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow={perc}
                                    aria-valuemin="0" aria-valuemax="100" style={{ width: perc + "%" }}>
                                    {perc + "%"}
                                </div>
                            </div>
                        </div>
                    )}
                    <div style={{ marginTop: '10px' }}>
                        <button className="btn btn-secondary no-outline atchBTN"
                            onClick={() => {
                                $("#cmd_aa_" + postId).trigger('click');
                            }}
                        >
                            <GrAttachment stroke="white!important" /> {translate('chat.add_file')}
                        </button>
                        <button className="btn btn-primary float-right no-outline" onClick={addComment}>{translate('generic.comment_action')}</button>
                    </div>
                </div>
                <input hidden style={{ display: 'none', position: 'fixed', top: '-5000em' }} id={"cmd_aa_" + postId} type="file" onChange={inputFileChanged} />
            </div>
        );
    else return (<></>);
}

export const UsersViewerModal = ({ isOpen, toggle, title, selectedUsersIds }) => {
    const [usersData, setUsersData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        post("Social/Feed/GetFilteredUsersTableData", { userIds: selectedUsersIds }, (response) => {
            setUsersData(response);
            setIsLoading(false);
        })
    }, [selectedUsersIds]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} size='lg'>
            <ModalHeader toggle={toggle}>{title} ({selectedUsersIds.length} {selectedUsersIds.length == 1 ? translate('generic.user') : translate('generic.users')})</ModalHeader>
            <ModalBody>
                {isLoading && (<LoadingCircleComponent />)}
                {!isLoading && (
                    <div className="row">
                        {usersData.map((data, index) =>
                            <div className="col-md-6" key={index}>
                                <Link to={'/profile/' + data.id} onClick={toggle}>
                                    <div className="groupListIcon" style={{ height: '62px' }}>
                                        <img style={{ height: '40px', borderRadius: '50%', border: '2px solid #7f8c8d', marginRight: '10px' }} src={data.imagePath} className="float-left" />
                                        <div>
                                            <p style={{ marginBottom: '0' }} className="h6">{data.displayName}</p>
                                            <small className="text-muted">{data.departament}</small>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )}
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" className="no-outline" onClick={toggle}>{translate('generic.close')}</Button>
            </ModalFooter>
        </Modal>
    );
}

export const DeleteConfirmationModal = ({ isOpen, toggle, onConfirm }) => {

    return (
        <Modal isOpen={isOpen} toggle={toggle} size='sm'>
            <ModalHeader toggle={toggle}>{translate('generic.confirm_q')}</ModalHeader>
            <ModalBody>
                <p>{translate('generic.confirm_question')}</p>
            </ModalBody>
            <ModalFooter>
                <Button color="warning" className="no-outline" onClick={toggle}>{translate('generic.cancel')}</Button>
                <Button color="primary" className="no-outline" onClick={() => { onConfirm(); toggle(); }}>{translate('generic.confirm')}</Button>
            </ModalFooter>
        </Modal>
    );
}

const ImageGallery = ({isOpen, closeGallery, attachments }) => {
    const [images, setImages] = useState([]);
    const [photoIndex, setPhotoIndex] = useState(0);

    useEffect(() => {
        const newImgs = [];
        for (var x = 0; x < attachments.length; x++)
            newImgs.push("https://joyhubregular.azureedge.net/" + attachments[x].blobName);
        setImages(newImgs);
    }, []);

    return (
        <>
            {(isOpen && images.length != 0) && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => closeGallery()}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                    }
                />
            )}
        </>
    );
}

const ManagedIFrameBox = ({ url }) => {
    const [visible, setVisible] = useState(true);
    return (
        <>
            {visible && (<iframe onLoad={(e) => {}} src={url} style={{ width: '100%' }} height={480} />)}
        </>
    );
}
