import React, { useEffect, useState } from 'react';
import { get_ajax, post } from '../../utils/DataProvider';
import { LoadingCircleComponent } from '../buildingBlocks/LoadingCircleComponent';
import { Link, Redirect } from 'react-router-dom'
import { RoundImageUploadField } from '../buildingBlocks/RoundImageUploadModal';
import { MembersPicker } from '../buildingBlocks/MembersPicker';
import authService from '../api-authorization/AuthorizeService';
import { translate } from '../../translations/translator_utils';

export const GroupDetails = (props) => {
    const [groupData, setGroupData] = useState(null);

    const [isInEditView, setIsInEditView] = useState(false);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        loadGroup();
    }, []);    

    const loadGroup = async () => {
        await get_ajax('Social/Groups/GetGroupDefaultOrById?id=' + props.match.params.groupId, (response) => {
            setGroupData(response);
            setLoading(false);            
        });
    }

    return (
        loading ? <LoadingCircleComponent/> :
        <div className="card">
                <div className="card-body">
                    {!isInEditView && (
                        <GroupDetailsView groupData={groupData} editClickCallback={() => { setIsInEditView(true); }} />
                    )}
                    {isInEditView && (
                        <GroupEditView groupData={groupData} editClickCallback={() => { setIsInEditView(false); }} loadCallback={loadGroup}  />
                    )}

                    <hr />
                    <br/>
                    <GroupUserZone users={groupData.adminUsers} label={ translate('groups.administrators')} groupId={groupData.id} />
                    <br/>
                    <GroupUserZone users={groupData.regularUsers} label={translate('generic.g_members')} groupId={groupData.id} />
            </div>
        </div>
    );
}

const GroupUserZone = ({ users, label, groupId }) => {
    return (
        <>
            <h5 className="card-title">{label}</h5>            
            <div className="row">
                {users.map((user) =>
                    <div className="col-6 col-md-3" key={user.id}>
                        <Link to={'/profile/' + user.id} className="initialA">
                            <div className="groupListIcon" style={{ height: '62px' }}>
                                <img style={{ height: '40px', borderRadius: '50%', border: '2px solid #3D9294', marginRight: '10px' }} src={user.avatar} className="float-left" />
                                <div>
                                    <p style={{ marginBottom: '0' }} className="h6">{user.display_name}</p>
                                    <small className="text-muted">{user.functie}</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                )}
            </div>
        </>
    );
}

const GroupDetailsView = ({ groupData, editClickCallback }) => {
    const [registerSuccessfull, setRegisterSuccessfull] = useState(false);
    const registerToGroup = () => {
        post('Social/Groups/RegisterIntoGroup', {
            'groupId': groupData.id
        }, (response) => {
            if (response === false) {
                alert("Eroare la crearea grupului.");
            }
            else {
                setRegisterSuccessfull(true);
            }
        });
    }

    return (
        <>
            <div>
                <img src={groupData.groupImage} className="float-left" style={{ marginRight: "15px", height: '110px', width: '110px', borderRadius: '50%', border: '5px solid #3D9294' }} />
                <div>
                    <h5 className="card-title">{groupData.groupName}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                        {groupData.private && (
                            <>
                                <svg fill="#6c757d" style={{ marginRight: '5px' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10 0v-4c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-8z" /></svg>
                                {translate('generic.private')}
                            </>
                        )}
                        {!groupData.private && (
                            <>
                                <svg fill="#6c757d" style={{ marginRight: '5px' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M12 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v3h2v-3c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-4v14h18v-14h-12z" /></svg>
                                {translate('generic.public')}
                            </>
                        )}
                    </h6>
                    <small className="card-subtitle mb-2 text-muted">
                        {`${groupData.adminUsers.length + groupData.regularUsers.length} ${translate('generic.members')} | ${translate('groups.created_on')} ${groupData.createdAt}`}
                    </small>
                </div>
            </div>
            <br />
            <br />
            <p className="card-text">{groupData.groupDesc}</p>
            <button type="button" className="btn btn-primary no-outline" style={{ marginRight: '5px' }} onClick={() => { setRegisterSuccessfull(true) }}>{translate('groups.access_group')}</button>
            {!groupData.isMember && (
                <button type="button" className="btn btn-primary no-outline" style={{ marginRight: '5px' }} onClick={registerToGroup}>{translate('groups.register_group')}</button>
            )}
            {(groupData.isAdmin || sessionStorage.getItem("current_user_role") == "PlatformAdmin" || sessionStorage.getItem("current_user_role") == "GodAdmin") && (
                <button type="button" className="btn btn-warning no-outline" onClick={editClickCallback}>{translate('generic.edit')}</button>
            )}

            {registerSuccessfull && (
                <Redirect to={'/feed/' + groupData.id} />
            )}
        </>
    );
}

const GroupEditView = ({ groupData, editClickCallback, loadCallback }) => {
    const [image, setImage] = useState(groupData.groupImage);
    const [imageData, setImageData] = useState(null);
    const [groupName, setGroupName] = useState(groupData.groupName);
    const [groupDesc, setGroupDesc] = useState(groupData.groupDesc);

    const [administratorsUsers, setAdministratorsUsers] = useState([sessionStorage.getItem("current_user_id")]);
    const [administratorsGroups, setAdministratorsGroups] = useState([]);
    const [administratorsFunctii, setAdministratorsFunctii] = useState([]);
    const [administratorsDepartamente, setAdministratorsDepartamente] = useState([]);
    const [administratorsLocatii, setAdministratorsLocatii] = useState([]);

    const [membersUsers, setmembersUsers] = useState([]);
    const [membersGroups, setmembersGroups] = useState([]);
    const [membersFunctii, setmembersFunctii] = useState([]);
    const [membersDepartamente, setmembersDepartamente] = useState([]);
    const [membersLocatii, setmembersLocatii] = useState([]);

    const [updateAdminPrivilages, setUpdateAdminPrivilages] = useState("FALSE");
    const [updateMemberPrivilages, setUpdateMemberPrivilages] = useState("FALSE");

    const saveEdits = async () => {
        var data = new FormData();
        data.encoding = "multipart/form-data";
        data.append("base64GroupImg", image)
        data.append('groupId', groupData.id);
        data.append('groupName', groupName);
        data.append('groupDesc', groupDesc);
        data.append('administrators_json', JSON.stringify({
            "Users": administratorsUsers,
            "Groups": administratorsGroups,
            "Functii": administratorsFunctii,
            "Departamente": administratorsDepartamente,
            "Locatii": administratorsLocatii,
            "AllowAll": false
        }));
        data.append('update_administrators', updateAdminPrivilages);
        data.append('members_json', JSON.stringify({
            "Users": membersUsers,
            "Groups": membersGroups,
            "Functii": membersFunctii,
            "Departamente": membersDepartamente,
            "Locatii": membersLocatii,
            "AllowAll": false
        }));
        data.append('update_members', updateMemberPrivilages);

        var request = new XMLHttpRequest();
        request.upload.addEventListener('progress', function (e) {
            if (e.total === e.loaded) {
                loadCallback();
                editClickCallback();
                return;
            }
        }.bind(this), false);
        request.open('POST', 'Social/Groups/EditGroupDetails');
        const token = await authService.getAccessToken();
        request.setRequestHeader('Authorization', `Bearer ${token}`);
        request.send(data);
    }

    const [groupAccessRules, setGroupAccessRules] = useState(undefined);

    useEffect(() => {        
        get_ajax('Social/Groups/GetGroupAccessRules?id=' + groupData.id, (response) => {
            setGroupAccessRules(response);
            console.log(response);
            setAdministratorsUsers(response.admin_users);
            setAdministratorsFunctii(response.admin_functii);
            setAdministratorsDepartamente(response.admin_departamente);
            setAdministratorsLocatii(response.admin_locatii);
        });
    }, [groupData]);

    return (
        <>
            <RoundImageUploadField
                defaultImagePreview={image}
                onSave={(blob) => {
                    setImage(blob);
                }} />
            <br />
            <div className="row">
                <div className="col-md-8">
                    <div className="form-group">
                        <label>{translate('generic.name')}</label>
                        <input value={groupName} onChange={(e) => { setGroupName(e.target.value); }} className="form-control no-outline" placeholder={translate('generic.name')}></input>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="form-group">
                    <label>{translate('generic.description')}</label>
                    <textarea value={groupDesc} onChange={(e) => { setGroupDesc(e.target.value); }} className="form-control no-outline" placeholder={translate('generic.description')} rows={2}></textarea>
                </div>
            </div>
            <hr />
            {groupAccessRules !== undefined && (
                <>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>{translate('groups.only_admins')}</label>
                                <MembersPicker
                                    defaultSelectedUsers={groupAccessRules.admin_users}
                                    defaultSelectedGroups={administratorsGroups}
                                    defaultSelectedFunctii={groupAccessRules.admin_functii}
                                    defaultSelectedDepartamente={groupAccessRules.admin_departamente}
                                    defaultSelectedLocatii={groupAccessRules.admin_locatii}
                                    saveCallback={(selectedUsers, selectedGroupsRules, selectedFunctiiRules, selectedDepartamenteRules, selectedLocatiiRules, allUsers) => {
                                        setAdministratorsUsers(selectedUsers);
                                        setAdministratorsGroups(selectedGroupsRules)
                                        setAdministratorsFunctii(selectedFunctiiRules);
                                        setAdministratorsDepartamente(selectedDepartamenteRules);
                                        setAdministratorsLocatii(selectedLocatiiRules);
                                        setUpdateAdminPrivilages("TRUE");                                        
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">                                
                                {!groupAccessRules.member_allow_all && (
                                    <>
                                        <label>{translate('generic.g_members')}</label>
                                        <MembersPicker
                                            defaultSelectedUsers={groupAccessRules.member_users}
                                            defaultSelectedGroups={membersGroups}
                                            defaultSelectedFunctii={groupAccessRules.member_functii}
                                            defaultSelectedDepartamente={groupAccessRules.member_departamente}
                                            defaultSelectedLocatii={groupAccessRules.member_locatii}
                                            saveCallback={(selectedUsers, selectedGroupsRules, selectedFunctiiRules, selectedDepartamenteRules, selectedLocatiiRules, allUsers) => {
                                                                                        setmembersUsers(selectedUsers);
                                                                                        setmembersGroups(selectedGroupsRules)
                                                                                        setmembersFunctii(selectedFunctiiRules);
                                                                                        setmembersDepartamente(selectedDepartamenteRules);
                                                                                        setmembersLocatii(selectedLocatiiRules);
                                                                                        setUpdateMemberPrivilages("TRUE");
                                                                                    }}
                                        />
                                    </>
                                )}
                                {groupAccessRules.member_allow_all && (
                                    <label><br />{translate('generic.configured_for_all')}</label>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}            
            <button type="submit" className="btn btn-primary no-outline" onClick={saveEdits}>{translate('generic.save')}</button>
            <button type="submit" className="btn btn-link no-outline" onClick={editClickCallback}>{translate('generic.cancel')}</button>
        </>
    );
}